import React, { useState, useEffect } from "react";
import { loginSchema } from "../../config/validations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import loginImage from "../../assest/login.jpg";
import Content from "./Content";
import { login } from "../../apis/Auth"
import { toast } from 'react-toastify';
import logo from "../../assest/logo1.png";
import { Loader2 } from "lucide-react"; // Import a loading icon
import { Button } from "../../components/ui/button";
import { setAuthToken } from "../../apis/index";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check if user is already logged in
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // If user exists, redirect to dashboard
      navigate('/dashboard');
    }
  }, [navigate]);

  const contentData = {
    title: "Potenza, precisione, produttività, il tuo successo è il nostro cantiere",
    description: "Aziende all'avanguardia con il sistema Restruct. Unisci competenze, ottimizza i risultati e costruisci in modo intelligente, noi saremo i mattoni digitali del tuo successo.",
    image: loginImage
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await login(data);
      if (response.status === 200) {
        const { user } = response.data;

        // Set token in both cookie and localStorage
        setAuthToken(user.token);

        // Store user data
        localStorage.setItem('user', JSON.stringify(user));

        toast.success(response.data.message);

        if (user.subscription) {
          window.location.href = "/dashboard";
        } else {
          window.location.href = `/billing/${user.username}`;
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      // toast.error(error.response?.data?.message || "An error occurred during login");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <Link to='/'>
        <div className="md:hidden w-full bg-gradient-to-r from-blue-950 to-blue-800 p-4 flex justify-center">
          <img src={logo} className="w-[180px]" alt="Logo" />
        </div>
      </Link>
      <div className="hidden md:block md:w-[45%]">
        <Content contentData={contentData} />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center p-8 bg-gradient-to-r from-blue-950 to-blue-800">
        <h1 className="mb-8 text-white font-nunito text-2xl md:text-3xl font-bold text-center">
          Benvenuto su <span className="text-lime-400">Restruct</span>
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 w-full max-w-[350px] md:max-w-[400px]"
        >
          <div>
            <input
              className={`h-12 p-4 rounded-full text-normal text-black w-full ${errors.email ? "border-2 border-red-500" : ""}`}
              type="text"
              placeholder="Email"
              {...register("email")}
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
          </div>
          <div>
            <input
              className={`h-12 p-4 rounded-full text-normal text-black w-full ${errors.password ? "border-2 border-red-500" : ""}`}
              type="password"
              placeholder="Password"
              {...register("password")}
            />
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
          </div>
          <div>
            <button
              className="h-12 p-2 w-full rounded-full text-normal bg-green-500 hover:bg-green-600 text-white flex items-center justify-center disabled:opacity-50"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Caricamento...
                </>
              ) : (
                "Accedi"
              )}
            </button>
            <p onClick={() => navigate("/forget-password")} className="mt-2 text-white text-[14px] pl-2 cursor-pointer">Password dimenticata?</p>
          </div>
          <p className="text-center text-white mx-2">
            Non hai un account?{" "}
            <a
              className="hover:opacity-75 font-semibold cursor-pointer"
              onClick={() => navigate("/register")}
            >
              Registrati
            </a>
          </p>
          <Link to="/" className="absolute top-4 left-4">
            <Button variant="link" className="flex items-center gap-2 text-white border-white hover:bg-white/10">
              Torna alla Home
            </Button>
          </Link>

        </form>
      </div>
    </div>
  );
};

export default Login;