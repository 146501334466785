import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { CircleCheck, HelpCircle, Minus } from 'lucide-react';
import { fetchPlans, initiateStripeCheckout, changePlan } from '../apis/SubscriptionApi';
import { Button } from '../components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../components/landing/Header';
import Footer from '../components/landing/Footer';
import { motion } from 'framer-motion';

const FeatureNameCell = ({ name, tooltip }) => {
  if (tooltip) {
    return (
      <div className="flex items-center gap-2">
        <div className="font-medium text-gray-800">{name}</div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="h-4 w-4 text-gray-700" />
            </TooltipTrigger>
            <TooltipContent className="max-w-xs">
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }
  return <div className="font-medium text-gray-800">{name}</div>;
};

const planDescriptions = {
  "Piano Restruct Basic": "Perfetto per piccole imprese edili",
  "Piano Restruct Standard": "Ideale per imprese in crescita",
  "Piano Restruct Professional": "Ottimo per aziende di medie dimensioni",
  "Piano Restruct Enterprise": "Completo per grandi imprese edili"
};

const featureGroups = [
  {
    name: "Risorse",
    features: [
      { name: "Spazio di archiviazione in cloud", tiers: ["1 gb", "3 gb", "10 gb", "Unlimited"] },
      { name: "Utenti", tiers: [1, 5, 50, 100] },
      { name: "Dipendenti", tiers: [5, 20, 50, "Unlimited"] },
    ]
  },
  {
    name: "Funzionalità base",
    features: [
      { name: "Chat", tiers: [false, true, true, true] },
      { name: "Patente a punti", tiers: [false, true, true, true] },
      { name: "Calendario & Agenda", tiers: [true, true, true, true] },
      { name: "Movimenti", tiers: ["100/mese", "300/mese", "Unlimited", "Unlimited"] },
    ]
  },
  {
    name: "Gestione aziendale",
    features: [
      { name: "Gestione clienti", tiers: [50, 200, 500, "Unlimited"] },
      { name: "Gestione fornitori", tiers: [25, 100, 300, "Unlimited"] },
      { name: "Gestione flotta", tiers: [false, "10 Veicoli", "50 Veicoli", "Unlimited"] },
      { name: "Magazzino", tiers: ["1 magazzino\n100 prodotti", "3 magazzini\n500 prodotti", "5 magazzini\n1000 prodotti", "Unlimited"] },
      { name: "DDT", tiers: ["20/mese", "50/mese", "200/mese", "Unlimited"] },
    ]
  },
  {
    name: "Gestione cantieri",
    features: [
      {
        name: "Cantieri",
        tiers: [3, 10, 70, "Unlimited"],
        tooltip: "Limite di cantieri aperti e gestiti contemporaneamente nell'app. Non sono conteggiati i cantieri terminati e archiviati."
      },
      { name: "Contabilità di cantiere", tiers: [true, true, true, true] },
      { name: "Documentazione cantieri", tiers: [false, true, true, true] },
      { name: "Cantieri -> Rapportini", tiers: [false, true, true, true] },
      { name: "Cantieri -> Giornale dei lavori", tiers: [false, false, true, true] },
      { name: "Cantieri -> Media", tiers: [false, "Foto", "Foto", "Foto & Video"] },
      { name: "Computi metrici", tiers: [false, false, true, true] },
    ]
  },
  {
    name: "Supporto e funzionalità avanzate",
    features: [
      { name: "Supporto", tiers: ["Standard", "Standard", "Dedicato", "Dedicato 24/7"] },
      { name: "Accesso esclusivo a nuove funzionalità", tiers: [false, false, false, true] },
    ]
  }
];

const PricingTier = ({ plan, billingCycle, onSubscribe, isCurrentPlan, userLoggedIn, userPlanType }) => {
  const price = plan.prices[billingCycle];
  const priceAmount = price ? (price.unit_amount / 100).toFixed(2) : 'N/A';
  const isPopular = plan.name === "Piano Restruct Professional";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="h-full"
    >
      <div className={`relative bg-white rounded-2xl p-4 h-full flex flex-col ${isPopular
        ? 'border-2 border-primary shadow-lg shadow-blue-100 ring-1 ring-blue-500/20'
        : 'border'
        } ${isCurrentPlan ? 'bg-blue-50' : ''}`}
      >
        {isPopular && (
          <div className="absolute -top-4 left-0 right-0 mx-auto w-max bg-primary text-white px-4 py-1 text-sm rounded-full shadow-sm">
            Più popolare
          </div>
        )}
        <div className={`${isPopular ? 'pt-2' : ''}`}>
          <h3 className="font-semibold text-xl mb-2">{plan.name}</h3>
          <p className="text-gray-600 text-sm">{planDescriptions[plan.name]}</p>

          <div className="my-6 flex items-baseline flex-wrap">
            <span className="text-4xl lg:text-5xl font-bold">€{priceAmount}</span>
            <span className="text-gray-600 ml-2">
              /{billingCycle === 'quarter' ? 'trimestre' : billingCycle === 'year' ? 'anno' : 'mese'}
            </span>
          </div>
        </div>

        <div className="mt-auto pt-1">
          {isCurrentPlan ? (
            <p className="text-green-600 font-semibold w-full text-center">Piano Attuale</p>
          ) : (
            <div className="flex flex-col gap-2 w-full">
              {userLoggedIn ? (
                <Button
                  onClick={() => onSubscribe(price?.id)}
                  className={`w-full ${isPopular ? 'bg-primary' : ''}`}
                  disabled={!price}
                  variant={isPopular ? "default" : "outline"}
                >
                  {userPlanType === 'free' ? 'Sottoscrivi' : 'Upgrade'}
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => onSubscribe(price?.id)}
                    className={`w-full ${isPopular ? 'bg-primary' : ''}`}
                    disabled={!price}
                    variant={isPopular ? "default" : "outline"}
                  >
                    Acquista ora
                  </Button>
                  <button
                    onClick={() => onSubscribe(price?.id)}
                    className="mt-2 w-full text-slate-900"
                    disabled={!price}
                  >
                    Inizia prova gratuita
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const PricingPage = () => {
  const [plans, setPlans] = useState([]);
  const [billingCycle, setBillingCycle] = useState('month');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const fetchedPlans = await fetchPlans();
        const planOrder = ['basic', 'standard', 'professional', 'enterprise'];
        const sortedPlans = fetchedPlans.sort((a, b) => {
          const indexA = planOrder.findIndex(p => a.name.toLowerCase().includes(p));
          const indexB = planOrder.findIndex(p => b.name.toLowerCase().includes(p));
          return indexA - indexB;
        });
        setPlans(sortedPlans);
      } catch (err) {
        console.error('Error fetching plans:', err);
        setError('Errore nel caricamento dei piani. Riprova più tardi.');
      }
    };

    loadPlans();
  }, []);

  const handleSubscribe = async (priceId) => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      if (user.subscription && user.subscription.planType !== 'free') {
        const response = await changePlan(priceId);
        setSuccessMessage('Piano aggiornato con successo. Le modifiche saranno effettive dal prossimo ciclo di fatturazione.');
        navigate('/subscription', { state: { success: true } });
      } else {
        const { checkoutUrl } = await initiateStripeCheckout(priceId);
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          throw new Error('URL di checkout non ricevuto');
        }
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      setError('Errore nell\'elaborazione della richiesta. Riprova.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Scegli il piano perfetto per la tua impresa</h1>
          <p className="text-xl text-gray-600">Piani flessibili che crescono con il tuo business</p>
        </motion.div>

        {error && (
          <Alert variant="destructive" className="mb-8">
            <AlertTitle>Errore</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {successMessage && (
          <Alert className="mb-8">
            <AlertTitle>Successo</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}

        <div className="flex justify-center mb-12">
          {['month', 'quarter', 'year'].map((cycle) => (
            <button
              key={cycle}
              className={`px-6 py-2 text-sm font-medium transition-colors
                ${billingCycle === cycle 
                  ? 'bg-slate-900 text-white' 
                  : 'bg-gray-200 hover:bg-gray-300'}`}
              onClick={() => setBillingCycle(cycle)}
            >
              {cycle === 'month' ? 'Mensile' : cycle === 'quarter' ? 'Trimestrale' : 'Annuale'}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {plans.map((plan) => (
            <PricingTier
              key={plan.id}
              plan={plan}
              billingCycle={billingCycle}
              onSubscribe={handleSubscribe}
              isCurrentPlan={user?.subscription?.planType === plan.name.toLowerCase()}
              userLoggedIn={!!user}
              userPlanType={user?.subscription?.planType}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="overflow-x-auto"
        >
          <div className="text-center text-gray-600 mb-4">
            Tutti i prezzi sono IVA inclusa
          </div>
          
          <div className="rounded-xl border shadow-lg bg-white">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left py-6 px-6 border-b bg-white rounded-tl-xl w-1/3">
                    <span className="text-xl font-semibold text-gray-900">Caratteristiche</span>
                  </th>
                  {['Basic', 'Standard', 'Professional', 'Enterprise'].map((tier, i) => (
                    <th key={i} className={`py-6 px-6 border-b bg-white ${i === 3 ? 'rounded-tr-xl' : ''}`}>
                      <div className="space-y-1">
                        <span className="text-xl font-semibold text-gray-900 block">{tier}</span>
                        {tier === 'Professional' && (
                          <span className="inline-block px-3 py-1 text-xs font-medium text-blue-700 bg-blue-50 rounded-full">
                            Più popolare
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {featureGroups.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    <tr className="bg-gradient-to-r from-gray-50 to-white">
                      <td colSpan={5} className="py-4 px-6 font-bold text-gray-900 border-t border-b text-lg">
                        {group.name}
                      </td>
                    </tr>
                    {group.features.map((feature, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="py-4 px-6 border-b">
                          <FeatureNameCell
                            name={feature.name}
                            tooltip={feature.tooltip}
                          />
                        </td>
                        {feature.tiers.map((value, i) => (
                          <td key={i} className="px-6 py-4 text-center border-b">
                            {typeof value === 'boolean' ? (
                              value ? (
                                <div className="flex justify-center">
                                  <div className="p-1 rounded-full bg-green-50">
                                    <CircleCheck className="h-5 w-5 text-green-500" />
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-center">
                                  <div className="p-1 rounded-full bg-gray-50">
                                    <Minus className="h-5 w-5 text-gray-400" />
                                  </div>
                                </div>
                              )
                            ) : (
                              <span className={`text-gray-900 font-medium ${value === 'Unlimited' ? 'text-blue-600' : ''}`}>
                                {value}
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mt-16"
        >
          <h2 className="text-2xl font-bold mb-4">Hai bisogno di aiuto per scegliere il piano giusto?</h2>
          <p className="text-gray-600 mb-8">Il nostro team è qui per aiutarti a trovare la soluzione perfetta per la tua impresa</p>
          <Link to="https://glds.it" target="_blank">
            <Button size="lg" variant="outline">
              Contattaci
            </Button>
          </Link>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default PricingPage;