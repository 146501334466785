import React, { useState, useEffect } from 'react';
import { getTaxes, createTax, updateTax, deleteTax } from '../../../apis/Tax';
import { ChevronLeft, ChevronRight, Filter, Plus, Trash2, Search, Edit } from 'lucide-react';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { format } from 'date-fns';
import { exportToExcel, exportToPDF } from '../../../config/helper';

const TAX_TYPES = [
  'INPS',
  'INAIL',
  'Cassa Edile',
  'IVA',
  'IRPEF',
  'Altro'
];

const MONTHS = [
  'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
  'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
];

const Tasse = () => {
  const [taxes, setTaxes] = useState([]);
  const [filteredTaxes, setFilteredTaxes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taxToDelete, setTaxToDelete] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [editingTax, setEditingTax] = useState(null);

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    status: '',
    type: ''
  });

  const emptyTax = {
    type: '',
    referenceYear: currentYear,
    referencePeriod: MONTHS[new Date().getMonth()],
    dueDate: new Date().toISOString().split('T')[0],
    amount: '',
    status: 'Non Pagata'
  };

  const [newTax, setNewTax] = useState(emptyTax);

  useEffect(() => {
    fetchTaxes();
  }, [searchTerm, currentYear]);

  useEffect(() => {
    applyFilters();
  }, [taxes, filters, searchTerm]);

  const fetchTaxes = async () => {
    try {
      const data = await getTaxes({
        year: currentYear
      });
      
      setTaxes(data.taxes);
      setFilteredTaxes(data.taxes);
      setTotalAmount(data.totalAmount);
      setTotalPaid(data.totalPaid);
    } catch (error) {
      toast.error('Errore durante il caricamento delle tasse');
    }
  };

  const handleEditClick = (tax) => {
    setEditingTax(tax);
    setNewTax({
      type: tax.type,
      referenceYear: tax.referenceYear,
      referencePeriod: tax.referencePeriod,
      dueDate: new Date(tax.dueDate).toISOString().split('T')[0],
      amount: tax.amount,
      status: tax.status
    });
    setIsModalOpen(true);
  };

  const applyFilters = () => {
    let filtered = [...taxes];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(tax => 
        tax.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tax.referencePeriod.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply type filter
    if (filters.type) {
      filtered = filtered.filter(tax => tax.type === filters.type);
    }

    // Apply status filter
    if (filters.status) {
      filtered = filtered.filter(tax => tax.status === filters.status);
    }

    // Apply date range filter
    if (filters.startDate) {
      filtered = filtered.filter(tax => 
        new Date(tax.dueDate) >= new Date(filters.startDate)
      );
    }
    if (filters.endDate) {
      filtered = filtered.filter(tax => 
        new Date(tax.dueDate) <= new Date(filters.endDate)
      );
    }

    setFilteredTaxes(filtered);
  };

  const handleExport = async (value) => {
    try {
      const formattedData = filteredTaxes.map((tax, index) => ({
        id: index + 1,
        tipo: tax.type,
        periodo: `${tax.referencePeriod} ${tax.referenceYear}`,
        scadenza: format(new Date(tax.dueDate), 'dd/MM/yyyy'),
        importo: `€ ${tax.amount.toFixed(2)}`,
        stato: tax.status
      }));

      const columns = [
        { header: "ID", key: "id", width: 0.5 },
        { header: "Tipo", key: "tipo", width: 1 },
        { header: "Periodo", key: "periodo", width: 1.5 },
        { header: "Scadenza", key: "scadenza", width: 1 },
        { header: "Importo", key: "importo", width: 1 },
        { header: "Stato", key: "stato", width: 1 }
      ];

      const currentDate = new Date().toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');

      const stats = {
        left: [
          { label: "Totale Tasse", value: filteredTaxes.length.toString() },
          { label: "Data", value: currentDate }
        ],
        right: [
          { label: "Totale Importo", value: `€ ${totalAmount.toFixed(2)}` },
          { label: "Tasse Pagate", value: totalPaid.toString() }
        ]
      };

      if (value === "excel") {
        exportToExcel(formattedData, "Tasse");
        toast.success("Esportazione Excel completata con successo");
      } else if (value === "pdf") {
        await exportToPDF(
          formattedData,
          columns,
          "Tasse",
          stats
        );
        toast.success("Esportazione PDF completata con successo");
      }
    } catch (error) {
      console.error('Errore durante l\'esportazione:', error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!newTax.type || !newTax.amount || !newTax.dueDate) {
        toast.error('Compila tutti i campi obbligatori');
        return;
      }

      if (editingTax) {
        await updateTax(editingTax._id, newTax);
        toast.success('Tassa aggiornata con successo');
      } else {
        await createTax(newTax);
        toast.success('Tassa creata con successo');
      }

      setIsModalOpen(false);
      setEditingTax(null);
      setNewTax(emptyTax);
      fetchTaxes();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Errore durante il salvataggio della tassa');
    }
  };

  const handleDeleteClick = (tax) => {
    setTaxToDelete(tax);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTax(taxToDelete._id);
      fetchTaxes();
      toast.success('Tassa eliminata con successo');
      setDeleteDialogOpen(false);
      setTaxToDelete(null);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Errore durante l\'eliminazione della tassa');
    }
  };

  const resetFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      status: '',
      type: ''
    });
    setIsFilterDialogOpen(false);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <ChevronLeft 
            className="cursor-pointer"
            onClick={() => setCurrentYear(prev => prev - 1)}
          />
          <span className="text-xl font-semibold">{currentYear}</span>
          <ChevronRight 
            className="cursor-pointer"
            onClick={() => setCurrentYear(prev => prev + 1)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="p-6 border rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Importo totale pagato</h3>
          <p className="text-3xl font-bold">€{totalAmount.toLocaleString('it-IT', { minimumFractionDigits: 2 })}</p>
        </div>
        <div className="p-6 border rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Numero di tasse pagate</h3>
          <p className="text-3xl font-bold">{totalPaid}</p>
        </div>
      </div>

      {/* Actions Row */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => setIsModalOpen(true)}
            className="bg-primary hover:bg-primary/90"
          >
            Aggiungi nuova tassa
          </Button>
          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <Input
              className="pl-10"
              placeholder="Cerca..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Button
            variant="outline"
            onClick={() => setIsFilterDialogOpen(true)}
          >
            <Filter className="h-4 w-4 mr-2" />
            Filtri
          </Button>
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nome tassa</TableHead>
              <TableHead>Periodo di riferimento</TableHead>
              <TableHead>Scadenza</TableHead>
              <TableHead>Importo</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead className="text-right">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredTaxes.map((tax) => (
              <TableRow key={tax._id}>
                <TableCell>{tax.type}</TableCell>
                <TableCell>{tax.referencePeriod} {tax.referenceYear}</TableCell>
                <TableCell>{format(new Date(tax.dueDate), 'dd/MM/yyyy')}</TableCell>
                <TableCell>€ {tax.amount.toFixed(2)}</TableCell>
                <TableCell>
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    tax.status === 'Pagata'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {tax.status}
                  </span>
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end gap-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleEditClick(tax)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDeleteClick(tax)}
                    >
                      <Trash2 className="h-4 w-4 text-red-500" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Tax Modal (Create/Edit) */}
      <Dialog open={isModalOpen} onOpenChange={(open) => {
        if (!open) {
          setEditingTax(null);
          setNewTax(emptyTax);
        }
        setIsModalOpen(open);
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{editingTax ? 'Modifica Tassa' : 'Aggiungi Nuova Tassa'}</DialogTitle>
            <DialogDescription>
              {editingTax ? 'Modifica i dettagli della tassa.' : 'Inserisci i dettagli della nuova tassa da aggiungere al sistema.'}
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium">Tipo</label>
                <Select
                  value={newTax.type}
                  onValueChange={(value) => setNewTax({ ...newTax, type: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona tipo" />
                  </SelectTrigger>
                  <SelectContent>
                    {TAX_TYPES.map(type => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="text-sm font-medium">Periodo</label>
                <Select
                  value={newTax.referencePeriod}
                  onValueChange={(value) => setNewTax({ ...newTax, referencePeriod: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona periodo" />
                  </SelectTrigger>
                  <SelectContent>
                    {MONTHS.map(month => (
                      <SelectItem key={month} value={month}>
                        {month}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div>
              <label className="text-sm font-medium">Data Scadenza</label>
              <Input
                type="date"
                value={newTax.dueDate}
                onChange={(e) => setNewTax({ ...newTax, dueDate: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium">Importo</label>
              <Input
                type="number"
                step="0.01"
                value={newTax.amount}
                onChange={(e) => setNewTax({ ...newTax, amount: e.target.value })}
                required
              />
            </div>

            {editingTax && (
              <div>
                <label className="text-sm font-medium">Stato</label>
                <Select
                  value={newTax.status}
                  onValueChange={(value) => setNewTax({ ...newTax, status: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona stato" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Pagata">Pagata</SelectItem>
                    <SelectItem value="Non Pagata">Non Pagata</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            )}

            <DialogFooter>
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingTax(null);
                  setNewTax(emptyTax);
                }}
              >
                Annulla
              </Button>
              <Button type="submit">
                {editingTax ? 'Aggiorna' : 'Salva'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Conferma eliminazione</AlertDialogTitle>
            <AlertDialogDescription>
              Sei sicuro di voler eliminare questa tassa?
              Questa azione non può essere annullata.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setDeleteDialogOpen(false);
              setTaxToDelete(null);
            }}>
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-600"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Filters Dialog */}
      <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Filtri</DialogTitle>
            <DialogDescription>
              Imposta i filtri per la ricerca delle tasse.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium">Data inizio</label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                />
              </div>
              <div>
                <label className="text-sm font-medium">Data fine</label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                />
              </div>
            </div>

            <div>
              <label className="text-sm font-medium">Tipo</label>
              <select
                className="w-full mt-1 rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background"
                value={filters.type}
                onChange={(e) => setFilters({ ...filters, type: e.target.value })}
              >
                <option value="">Tutti</option>
                {TAX_TYPES.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="text-sm font-medium">Stato</label>
              <select
                className="w-full mt-1 rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background"
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              >
                <option value="">Tutti</option>
                <option value="Pagata">Pagata</option>
                <option value="Non Pagata">Non Pagata</option>
              </select>
            </div>
          </div>
          <DialogFooter className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={resetFilters}
            >
              Reset
            </Button>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsFilterDialogOpen(false)}
              >
                Annulla
              </Button>
              <Button
                type="button"
                onClick={() => setIsFilterDialogOpen(false)}
              >
                Applica
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Tasse;