import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Eye, Trash2, Plus, Search } from "lucide-react";
import { fetchDdt, deleteDdt } from '../../../apis/Ddt';
import { fetchCantiere } from '../../../apis/DdtEdit';
import { toast } from "react-toastify";
import Pagination from '../sharedComponent/Pgination';

import { Button } from "../../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import SearchBar from '../sharedComponent/SearchBar';
import HelpSheet from '../sharedComponent/HelpSheet';
import { exportToExcel, exportToPDF } from "../../../config/helper";

const TableComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cantiereMap, setCantiereMap] = useState({});

  const getCantiereData = async () => {
    try {
      const response = await fetchCantiere(companyId);
      const sites = response?.data?.sites || [];
      const siteMap = {};
      sites.forEach(site => {
        siteMap[site._id] = site.name;
      });
      setCantiereMap(siteMap);
    } catch (error) {
      console.error('Error fetching cantiere data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      const response = await fetchDdt(companyId, currentPage, limit);
      if (response.data.success) {
        const limitedItems = response.data.ddts.slice(0, limit);
        setData(limitedItems);
        setFilteredData(limitedItems);
        setDataIsLoaded(true);
        setTotalPage(Math.ceil(response.data.totalDdt / limit));
        setCurrentPage(response.data.currentPage);
        setTotalRecords(response.data.totalDdt);
      }
    } catch (error) {
      console.error('Error fetching DDTs:', error);
      toast.error("Errore nel caricamento dei DDT");
    }
  };

  useEffect(() => {
    getItems();
    getCantiereData();
  }, [currentPage, limit]);

  const handleSearch = (value) => {
    const searchTerm = value.toLowerCase();
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.numeroddt?.toLowerCase().includes(searchTerm) ||
        item.data?.toLowerCase().includes(searchTerm) ||
        item.valuta?.toLowerCase().includes(searchTerm) ||
        cantiereMap[item.cantiere]?.toLowerCase().includes(searchTerm) ||
        item.tipologia?.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questo DDT?')) {
      try {
        const response = await deleteDdt(id);
        if (response.data.success) {
          toast.success("DDT eliminato con successo");
          getItems();
        }
      } catch (error) {
        console.error('Error deleting DDT:', error);
        toast.error("Errore nell'eliminazione del DDT");
      }
    }
  };

  const handleExport = (value) => {
    const formattedData = filteredData.map((item, index) => ({
      nu: (index + 1).toString(),
      numero: item.numeroddt || '',
      data: item.data || '',
      valuta: item.valuta || '',
      cantiere: cantiereMap[item.cantiere] || '-',
      tipologia: item.tipologia || '-',
      prodotti: item.prodottival ? `€${item.prodottival}` : '€0',
      iva: item.ivaval ? `€${item.ivaval}` : '€0',
      totale: item.totaleval ? `€${item.totaleval}` : '€0'
    }));

    const columns = [
      { header: "N°", key: "nu" },
      { header: "Numero DDT", key: "numero" },
      { header: "Data", key: "data" },
      { header: "Valuta", key: "valuta" },
      { header: "Cantiere", key: "cantiere" },
      { header: "Tipologia", key: "tipologia" },
      { header: "Prodotti", key: "prodotti" },
      { header: "IVA", key: "iva" },
      { header: "Totale", key: "totale" }
    ];

    const stats = [
      { label: "Totale DDT", value: totalRecords },
      { label: "Data Export", value: new Date().toLocaleDateString() }
    ];

    if (value === "excel") {
      exportToExcel(formattedData, "DDT_Export");
    } else if (value === "pdf") {
      exportToPDF(formattedData, columns, "Elenco DDT", stats);
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button>
                      Nuovo DDT
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-[170px]">
                    <DropdownMenuItem asChild>
                      <Link to="/ddt_add?type=Emessi" className="cursor-pointer">
                        Nuovo DDT emesso
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/ddt_add?type=Ricevuti" className="cursor-pointer">
                        Nuovo DDT ricevuto
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TooltipTrigger>
              <TooltipContent>
                <p>Crea nuovo DDT</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Select onValueChange={handleExport}>
                  <SelectTrigger className="w-full sm:w-[180px]">
                    <SelectValue placeholder="Esporta" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excel">Esporta in Excel</SelectItem>
                    <SelectItem value="pdf">Esporta in PDF</SelectItem>
                  </SelectContent>
                </Select>
              </TooltipTrigger>
              <TooltipContent>
                <p>Esporta dati</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-center space-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/ddt" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Numero DDT</TableHead>
              <TableHead>Tipologia</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Cantiere</TableHead>
              <TableHead className="text-right">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map(item => (
                <TableRow key={item._id}>
                  <TableCell className="font-medium">{item.numeroddt || '-'}</TableCell>
                  <TableCell>{item.tipologia || '-'}</TableCell>
                  <TableCell>{item.data || '-'}</TableCell>
                  <TableCell>{cantiereMap[item.cantiere] || '-'}</TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link to={`/ddt_edit/${item._id}`}>
                              <Button variant="ghost" size="sm">
                                <Eye className="h-4 w-4" />
                              </Button>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Visualizza/Modifica</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDelete(item._id)}
                            >
                              <Trash2 className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Elimina DDT</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-4">
                  {dataIsLoaded ? 'Nessun DDT trovato' : 'Caricamento...'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {filteredData.length > 0 && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalRecords}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default TableComponent;