import React, { useState, useEffect, useRef } from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, startOfWeek, endOfWeek, addMonths, subMonths } from "date-fns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AttendanceDocument from "./AttendanceDocumentPdf";
import { formatWorkingHours } from "../../../../../config/helper";
import {
  getCompnayEmployeeAttendence,
  markAttendance,
  updateAttendance,
} from "../../../../../apis/Attendence";
import { getCompanyPlans } from "../../../../../apis/Planning";
import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../ui/dialog";
import { Label } from "../../../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../ui/card";
import EmployeeSelectionDialog from './SelectEmployeeModal';
import { getEmployeeGroups } from "../../../../../apis/EmployeeGroups";
import { Link } from "react-router-dom";
import { AddLeave } from "../../../../../apis/leave";

const getAbsenceCode = (absenceReason) => {
  switch (absenceReason) {
    case 'Ingiustificata':
      return 'A';
    case 'Malattia':
      return 'M';
    case 'Permesso':
      return 'P';
    case 'Ferie':
      return 'F';
    default:
      return '';
  }
};

const AttendanceSheet = ({employeeData}) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSelectEmployeeDialogOpen, setSelectEmployeeDialogOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [companyPlans, setCompanyPlans] = useState([]);
  const [groups, setGroups] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      fetchAttendanceData();
    }
    fetchCompanyPlans();
  }, [selectedMonth, employeeData]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getEmployeeGroups(user.companyId);
        setGroups(response.data.groups);
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast.error('Unable to fetch employee groups');
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    if (attendanceData.length > 0 && selectedEmployees.length === 0) {
      if (!hasInitialized.current) {
        setSelectedEmployees(attendanceData.map(emp => emp._id));
        hasInitialized.current = true;
      }
    }
    filterAttendanceData();
  }, [attendanceData, selectedEmployees]);

  const fetchAttendanceData = async () => {
    try {
      const response = await getCompnayEmployeeAttendence(user.companyId);
      if (response.data?.employees) {
        // Create a map of employee IDs to their attendance data
        const attendanceMap = response.data.employees.reduce((map, emp) => {
          map[emp._id] = emp.attendance || [];
          return map;
        }, {});

        // Merge the attendance data with the employee data
        const mergedEmployees = employeeData.map(emp => ({
          ...emp,
          attendance: attendanceMap[emp._id] || []
        }));

        setAttendanceData(mergedEmployees);
      }
    } catch (error) {
      console.error("Failed to fetch attendance data", error);
      toast.error("Unable to fetch attendance data");
    }
  };

  const fetchCompanyPlans = async () => {
    try {
      const response = await getCompanyPlans();
      setCompanyPlans(response.data.plans);
    } catch (error) {
      console.error("Unable to fetch company plans", error);
      toast.error("Unable to fetch company plans");
    }
  };

  const filterAttendanceData = () => {
    let filtered = [];
    if (selectedEmployees && selectedEmployees.length > 0) {
      filtered = attendanceData.filter(emp => selectedEmployees.includes(emp._id));
    }
    setFilteredAttendanceData(filtered);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value));
  };

  const incrementMonth = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const decrementMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const getCalendarDays = () => {
    const start = startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  const openModal = (employee, date) => {
    setSelectedEmployee(employee);
    setSelectedDate(date);
    setModalOpen(true);
  };

  const handleModalSubmit = async (data) => {
    try {
      const response = data._id ? await updateAttendance(data) : await markAttendance(data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Attendance updated successfully");
        fetchAttendanceData();
      }
    } catch (error) {
      console.error("Failed to update attendance", error);
      toast.error("Unable to update attendance");
    }
    setModalOpen(false);
  };

  const handleEmployeeSelect = (newSelectedEmployees) => {
    setSelectedEmployees(newSelectedEmployees || []);
  };

  const days = getCalendarDays();
  const weeks = [];
  let currentWeek = [];

  days.forEach((day) => {
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
    currentWeek.push(day);
  });
  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
  }

  return (
    <Card className="mx-auto p-0 border-0">
      <CardHeader className='py-2 px-0'>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center gap-2">
            <Button onClick={decrementMonth} variant="outline" className="p-1">
              <IoCaretBack className="w-4 h-4 text-gray-600" />
            </Button>
            <span className="text-gray-900 text-sm">
              {format(selectedMonth, 'd MMM yyyy')} {'>'} {format(endOfMonth(selectedMonth), 'd MMM yyyy')}
            </span>
            <Button onClick={incrementMonth} variant="outline" className="p-1">
              <IoCaretForward className="w-4 h-4 text-gray-600" />
            </Button>
          </div>
          <div className="flex items-center gap-2 md:mt-0">
            <Button variant="outline" onClick={() => setSelectEmployeeDialogOpen(true)}>
              Seleziona Dipendenti
            </Button>
            <PDFDownloadLink
              document={
                <AttendanceDocument
                  attendanceData={filteredAttendanceData}
                  selectedMonth={selectedMonth}
                />
              }
              fileName={`Foglio_Presenze_${format(selectedMonth, 'yyyy-MM')}.pdf`}
            >
              <Button>Esporta</Button>
            </PDFDownloadLink>
          </div>
        </div>
      </CardHeader>

      <CardContent className='p-0 mt-3'>
        <div className="overflow-x-auto">
          <div className="min-w-max">
            {/* Calendar Headers - Days and Dates */}
            <div className="ml-10 flex gap-4">
              {weeks.map((week, weekIndex) => (
                <div key={weekIndex} className="flex">
                  <div className="flex">
                    {week.map((day, dayIndex) => (
                      <div key={dayIndex} className="w-8">
                        <div className="text-xs text-gray-500 text-center">
                          {format(day, 'EEE').toUpperCase()}
                        </div>
                        <div className="text-xs text-blue-500 text-center">
                          {format(day, 'd')}
                        </div>
                      </div>
                    ))}
                  </div>
                  {weekIndex < weeks.length - 1 && (
                    <div className="w-4 flex items-center justify-center">
                      <div className="h-full w-px bg-gray-200"></div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Employees Section */}
            <div className="mt-4 space-y-6">
              {filteredAttendanceData.map((employee) => (
                <div key={employee._id} className="flex flex-col">
                  {/* Employee Info */}
                  <div className="flex items-center gap-2 mb-1">
                    <div className="w-9 h-9 bg-gray-100 rounded-full flex items-center justify-center">
                      <span className="text-md text-gray-600 font-medium">
                        {`${employee.firstName[0]}${employee.sureName?.[0] || ''}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-md text-gray-900">
                        {employee.firstName} <span className="font-medium">{employee.sureName}</span>
                      </span>
                      <span className="text-xs text-gray-500">
                        Matricola: {employee.freshman}
                      </span>
                    </div>
                  </div>

                  <div className="text-xs text-gray-600 ml-11 mb-2">nessun orario attivo per questa settimana</div>

                  {/* Calendar Boxes */}
                  <div className="ml-10 gap-4 flex">
                    {weeks.map((week, weekIndex) => (
                      <div key={weekIndex} className="flex">
                        <div className="flex">
                          {week.map((day) => {
                            const dateString = format(day, "dd-MM-yyyy");
                            const attendanceForDate = employee.attendance.find(
                              (a) => a.date === dateString
                            );
                            const planDetails = companyPlans.find(
                              (p) => p._id === attendanceForDate?.planId
                            );

                            return (
                              <div key={`${employee._id}-${day.getTime()}`} className="w-8">
                                <div
                                  onClick={() => openModal(employee, day)}
                                  className={`w-8 h-8 border rounded hover:bg-gray-50 cursor-pointer mx-auto flex items-center justify-center ${!isSameMonth(day, selectedMonth) ? 'bg-white' : ''
                                    } ${attendanceForDate?.attendanceState === 'present'
                                      ? 'border-green-500 border'
                                      : attendanceForDate?.attendanceState === 'absent'
                                        ? 'border-red-500 border'
                                        : ''
                                    }`}
                                  style={{
                                    backgroundColor: isSameMonth(day, selectedMonth)
                                      ? planDetails?.color || 'white'
                                      : '#f3f4f6'
                                  }}
                                >
                                  {attendanceForDate && isSameMonth(day, selectedMonth) && (
                                    <div className="flex flex-col items-center text-[10px]">
                                      <span className={`font-semibold ${attendanceForDate.attendanceState === 'present'
                                        ? planDetails
                                          ? 'text-white'
                                          : 'text-green-500 font-bold'
                                        : 'text-red-500 font-bold'
                                        }`}>
                                        {attendanceForDate.attendanceState === 'present'
                                          ? (planDetails ? planDetails.acronym : 'P')
                                          : getAbsenceCode(attendanceForDate.absenceReason)
                                        }
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {weekIndex < weeks.length - 1 && (
                          <div className="w-4 flex items-center justify-center">
                            <div className="h-full w-px bg-gray-200"></div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Utente selezionato: {selectedEmployee?.firstName} {selectedEmployee?.sureName}
              <span className="text-sm font-normal text-gray-500 ml-2">
                (Matricola: {selectedEmployee?.freshman})
              </span>
              {selectedDate && ` - ${format(selectedDate, 'dd/MM/yyyy')}`}
            </DialogTitle>
          </DialogHeader>
          <ModalContent
            employee={selectedEmployee}
            date={selectedDate}
            onSubmit={handleModalSubmit}
            companyPlans={companyPlans}
            initialAttendanceData={
              selectedEmployee?.attendance.find(
                (a) => a.date === format(selectedDate || new Date(), "dd-MM-yyyy")
              )
            }
          />
        </DialogContent>
      </Dialog>

      <EmployeeSelectionDialog
        isOpen={isSelectEmployeeDialogOpen}
        onClose={() => setSelectEmployeeDialogOpen(false)}
        employees={attendanceData}
        groups={groups}
        selectedEmployees={selectedEmployees}
        onSelectionChange={handleEmployeeSelect}
        onConfirm={() => {
          setSelectEmployeeDialogOpen(false);
          filterAttendanceData();
        }}
      />

      <ToastContainer />
    </Card>
  );
};

const ModalContent = ({
  employee,
  date,
  onSubmit,
  companyPlans,
  initialAttendanceData,
}) => {
  const [attendanceState, setAttendanceState] = useState(
    initialAttendanceData?.attendanceState || "present"
  );
  const [selectedPlan, setSelectedPlan] = useState(initialAttendanceData?.planId || "");
  const [startTime, setStartTime] = useState(initialAttendanceData?.startTime || "");
  const [exitTime, setExitTime] = useState(initialAttendanceData?.exitTime || "");
  const [absenceReason, setAbsenceReason] = useState(
    initialAttendanceData?.absenceReason || ""
  );

  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);
    
    const attendancePayload = {
      _id: initialAttendanceData?._id,
      employeeId: employee._id,
      date: format(date, "dd-MM-yyyy"),
      attendanceState,
      startTime: attendanceState === "present" ? (startTime || selectedPlanDetails?.startTime) : "",
      exitTime: attendanceState === "present" ? (exitTime || selectedPlanDetails?.endTime) : "",
      planId: attendanceState === "present" ? selectedPlan : "",
      absenceReason: attendanceState === "absent" ? absenceReason : "",
      companyId: user.companyId,
    };

    try {
      // First update attendance
      await onSubmit(attendancePayload);

      // If marking as absent, create a leave record
      if (attendanceState === "absent") {
        const leavePayload = {
          employeeId: employee._id,
          companyId: user.companyId,
          absenceType: absenceReason,
          startDate: format(date, "yyyy-MM-dd"),
          endDate: format(date, "yyyy-MM-dd"),
          absenceHours: 8 // Standard work day
        };

        await AddLeave(leavePayload);
      }
    } catch (error) {
      console.error("Failed to update records:", error);
      toast.error("Failed to update records");
    }
  };

  const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="attendanceState">Stato Presenza</Label>
        <Select value={attendanceState} onValueChange={setAttendanceState}>
          <SelectTrigger id="attendanceState">
            <SelectValue placeholder="Seleziona lo stato" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="present">Presente</SelectItem>
            <SelectItem value="absent">Assente</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {attendanceState === "present" ? (
        <div className="space-y-2">
          <Label htmlFor="planSelect">Seleziona turno</Label>
          <Select value={selectedPlan} onValueChange={setSelectedPlan}>
            <SelectTrigger id="planSelect">
              <SelectValue placeholder="Seleziona un turno" />
            </SelectTrigger>
            <SelectContent>
              {companyPlans.map((plan) => (
                <SelectItem key={plan._id} value={plan._id}>
                  {`${plan.name} (${plan.acronym}) - ${plan.workSite}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {selectedPlanDetails && (
            <div className="space-y-2">
              <h3 className="font-bold">Dettagli Turno:</h3>
              <div className="grid gap-2 p-4 border rounded-lg">
                <p>Nome: {selectedPlanDetails.name}</p>
                <p>Acronimo: {selectedPlanDetails.acronym}</p>
                <p>Sede: {selectedPlanDetails.workSite}</p>
                <p>Orario: {selectedPlanDetails.startTime} - {selectedPlanDetails.endTime}</p>
                {selectedPlanDetails.isContinuous ? (
                  <p>Turno Continuato</p>
                ) : (
                  <p>Pausa: {selectedPlanDetails.breakStartTime} - {selectedPlanDetails.breakEndTime}</p>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-2">
          <Label htmlFor="absenceReason">Motivo Assenza</Label>
          <Select value={absenceReason} onValueChange={setAbsenceReason}>
            <SelectTrigger id="absenceReason">
              <SelectValue placeholder="Seleziona il motivo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Ingiustificata">Ingiustificata</SelectItem>
              <SelectItem value="Malattia">Malattia</SelectItem>
              <SelectItem value="Ferie">Ferie</SelectItem>
              <SelectItem value="Permesso">Permesso</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}

      <DialogFooter>
        <div className="flex justify-between w-full">
          {attendanceState === "present" && (
            <Link to="/settings">
              <Button variant="link">
                Aggiungi nuovo turno
              </Button>
            </Link>
          )}
          <Button
            onClick={handleSubmit}
            disabled={
              (attendanceState === "present" && !selectedPlan) ||
              (attendanceState === "absent" && !absenceReason)
            }
          >
            {initialAttendanceData ? 'Aggiornamento' : 'Salva'}
          </Button>
        </div>
      </DialogFooter>
    </div>
  );
};

export default AttendanceSheet;