import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchDdt, fetchCurrencies, fetchSupplier, fetchClients, fetchDdtInvoiceById, updateDdt, updateDdtInvoice, fetchCantiere, fetchUm } from '../../../apis/DdtEdit';
import { toast } from "react-toastify";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Textarea } from "../../ui/textarea";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../ui/card";
import Spinner from '../../Spinner';
import { ChevronUp, ChevronDown } from "lucide-react";

function DetailComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const navigate = useNavigate();
  const { id } = useParams();
  const originalData = useRef(null);
  const [item, setItem] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [cantiere, setCantiere] = useState([]);
  const [isClient, setIsClient] = useState(true);
  const [itemDetails, setItemDetails] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [um, setUm] = useState([]);
  const [clients, setClients] = useState([]);
  const [openSection, setOpenSection] = useState(0);

  useEffect(() => {
    if (id && companyId) {
      const getData = async () => {
        try {
          const itemData = await fetchDdt(id);
          if (!itemData?.data?.ddt) {
            throw new Error('Failed to fetch DDT data');
          }

          console.log('DDT Data:', itemData.data.ddt);
          const ddt = itemData.data.ddt;
          // Store original data
          originalData.current = {
            clienti_id: ddt.clienti_id,
            clienti: ddt.clienti,
            fornitore_id: ddt.fornitore_id
          };
          setItem(ddt);
          setIsClient(Boolean(ddt.clienti_id) || Boolean(ddt.clienti));

          const token = ddt.token;

          const [currencyData, suppliersData, umData, clientsData, itemDetailData, cantiereData] = await Promise.all([
            fetchCurrencies(),
            fetchSupplier(companyId),
            fetchUm(),
            fetchClients(companyId),
            fetchDdtInvoiceById(token),
            fetchCantiere(companyId)
          ]);

          setCurrencies(currencyData?.valuta || []);
          setSuppliers(suppliersData?.suppliers || []);
          setUm(umData?.um || []);
          setClients(clientsData?.data?.clients || []);
          setItemDetails(itemDetailData?.data?.ddtInvoice || []);
          setCantiere(cantiereData?.data?.sites || []);

        } catch (error) {
          console.error('Error in getData:', error);
          toast.error('Error fetching data: ' + (error.message || 'Unknown error'));
        }
      };

      getData();
    }
  }, [id, companyId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const ddtResponse = await updateDdt(id, item);

      const ddtInvoiceData = {
        token: item.token,
        ddtInvoice: itemDetails.map(detail => ({
          ...detail,
          token: item.token,
          importo_totale: detail.importo
        }))
      };

      await updateDdtInvoice(item.token, ddtInvoiceData);

      if (ddtResponse.status === 200) {
        toast.success("Data saved successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error('Error updating DDT:', error);
      toast.error("Error saving data");
    }
  };

  const handleDuplicate = (index) => {
    setItemDetails(prev => {
      const newDetails = [...prev];
      const duplicatedItem = {
        token: newDetails[index].token,
        codice: newDetails[index].codice || '',
        nome: newDetails[index].nome || '',
        descrizione: newDetails[index].descrizione || '',
        um: newDetails[index].um || '',
        qita: newDetails[index].qita || 0,
        iva: newDetails[index].iva || 0,
        sconto: newDetails[index].sconto || 0,
        importonetto: newDetails[index].importonetto || 0,
        importo: newDetails[index].importo || 0
      };

      newDetails.splice(index + 1, 0, duplicatedItem);

      const totals = newDetails.reduce((acc, curr) => {
        const baseAmount = parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
        const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
        const amountAfterDiscount = baseAmount - discount;
        const ivaAmount = (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

        return {
          prodottival: acc.prodottival + amountAfterDiscount,
          ivaval: acc.ivaval + ivaAmount
        };
      }, { prodottival: 0, ivaval: 0 });

      setItem(prevItem => ({
        ...prevItem,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      }));

      setOpenSection(index + 1);

      return newDetails;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleDelete = (indexToDelete) => {
    if (itemDetails.length <= 1) return;
    setItemDetails(prev => prev.filter((_, index) => index !== indexToDelete));
  };

  const handleDetailChange = (index, field, value) => {
    setItemDetails(prev => {
      const newDetails = [...prev];
      const detail = { ...newDetails[index], [field]: value };

      const quantity = parseFloat(detail.qita) || 0;
      const unitPrice = parseFloat(detail.importonetto) || 0;
      const discount = parseFloat(detail.sconto) || 0;
      const iva = parseFloat(detail.iva) || 0;

      const baseAmount = quantity * unitPrice;
      const discountAmount = (baseAmount * discount) / 100;
      const amountAfterDiscount = baseAmount - discountAmount;

      const ivaAmount = (amountAfterDiscount * iva) / 100;
      const totalAmount = amountAfterDiscount + ivaAmount;

      detail.importo = totalAmount.toFixed(2);
      newDetails[index] = detail;

      const totals = newDetails.reduce((acc, curr) => {
        const baseAmount = parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
        const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
        const amountAfterDiscount = baseAmount - discount;
        const ivaAmount = (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

        return {
          prodottival: acc.prodottival + amountAfterDiscount,
          ivaval: acc.ivaval + ivaAmount
        };
      }, { prodottival: 0, ivaval: 0 });

      setItem(prevItem => ({
        ...prevItem,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      }));

      return newDetails;
    });
  };

  if (!item) return <Spinner />;

  return (
    <form className="space-y-8 p-6" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Dati Cliente / Fornitore</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <RadioGroup
              value={isClient ? "client" : "supplier"}
              onValueChange={(value) => {
                const newIsClient = value === "client";
                console.log('Radio value changed:', { newIsClient, value });
                setIsClient(newIsClient);

                if (newIsClient) {
                  setItem(prev => ({
                    ...prev,
                    clienti_id: originalData.current?.clienti_id || null,
                    clienti: originalData.current?.clienti || null,
                    fornitore_id: null
                  }));
                } else {
                  setItem(prev => ({
                    ...prev,
                    fornitore_id: originalData.current?.fornitore_id || null,
                    // Only clear client if there was no original fornitore
                    ...(originalData.current?.fornitore_id ? {
                      clienti_id: null,
                      clienti: null
                    } : {})
                  }));
                }
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="client" id="clientRadio" />
                <Label htmlFor="clientRadio">Cliente</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="supplier" id="fornitoreRadio" />
                <Label htmlFor="fornitoreRadio">Fornitore</Label>
              </div>
            </RadioGroup>

            {isClient ? (
              <Select
                name="clienti_id"
                value={(item.clienti_id || item.clienti || '').toString()}
                onValueChange={(value) => {
                  console.log('Selecting client:', value);
                  if (value !== (item.clienti_id || item.clienti)) {
                    setItem(prev => ({
                      ...prev,
                      clienti_id: value,
                      clienti: value,
                      fornitore_id: null
                    }));
                    originalData.current = {
                      ...originalData.current,
                      clienti_id: value,
                      clienti: value,
                      fornitore_id: null
                    };
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Scegli Cliente" />
                </SelectTrigger>
                <SelectContent>
                  {Array.isArray(clients) && clients.map(client => (
                    <SelectItem key={client._id} value={client._id}>
                      {client.typology === 'Private' ? client.fullName : client.companyName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <Select
                name="fornitore_id"
                value={item.fornitore_id || ''}
                onValueChange={(value) => {
                  console.log('Selecting supplier:', value);
                  if (value !== item.fornitore_id) {
                    setItem(prev => ({
                      ...prev,
                      fornitore_id: value,
                      clienti_id: null,
                      clienti: null
                    }));
                    originalData.current = {
                      ...originalData.current,
                      fornitore_id: value,
                      clienti_id: null,
                      clienti: null
                    };
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Scegli Fornitore" />
                </SelectTrigger>
                <SelectContent>
                  {suppliers?.map(supplier => (
                    <SelectItem key={supplier._id} value={supplier._id}>
                      {supplier.businessName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}

            <Input
              type="number"
              id="numeroddt"
              name="numeroddt"
              value={item.numeroddt || ''}
              readOnly
              disabled
              className="bg-gray-100"
            />
          </CardContent>
        </Card>

        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Dati del documento</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="data">Data</Label>
                <Input
                  type="date"
                  id="data"
                  name="data"
                  value={item.data || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="valuta">Valuta</Label>
                <Select
                  name="valuta"
                  value={item.valuta || ''}
                  onValueChange={(value) => setItem({ ...item, valuta: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona Valuta" />
                  </SelectTrigger>
                  <SelectContent>
                    {currencies.map(currency => (
                      <SelectItem key={currency.valuta} value={currency.valuta}>
                        {currency.valuta}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="cantiere">Cantiere</Label>
                <Select
                  name="cantiere"
                  value={item.cantiere || ''}
                  onValueChange={(value) => setItem({ ...item, cantiere: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona Cantiere" />
                  </SelectTrigger>
                  <SelectContent>
                    {cantiere.map(site => (
                      <SelectItem key={site._id} value={site._id}>
                        {site.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="tipologia">Tipologia</Label>
                <Select
                  name="tipologia"
                  value={item.tipologia || ''}
                  onValueChange={(value) => setItem({ ...item, tipologia: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona Tipologia" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Emessi">Emessi</SelectItem>
                    <SelectItem value="Ricevuti">Ricevuti</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        <Card className="md:col-span-2 bg-white">
          <CardHeader>
            <CardTitle>Lista degli articoli</CardTitle>
          </CardHeader>
          <CardContent className='pb-0 mb-0'>
            {itemDetails.map((detail, index) => (
              <div key={index} className="mb-4 pb-4 border-b">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setOpenSection(openSection === index ? null : index)}
                >
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">
                      {detail.nome || detail.codice || `Articolo ${index + 1}`}
                    </span>
                    <span className="text-sm text-gray-500">
                      {detail.um ? `${detail.qita} ${detail.um}` : ''}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">€ {detail.importo || '0.00'}</span>
                    {openSection === index ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </div>
                </div>

                {openSection === index && (
                  <div className="mt-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`codice-${index}`}>Codice</Label>
                            <Input
                              id={`codice-${index}`}
                              name="codice"
                              value={detail.codice || ''}
                              onChange={(e) => handleDetailChange(index, 'codice', e.target.value)}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`nome-${index}`}>Nome</Label>
                            <Input
                              id={`nome-${index}`}
                              name="nome"
                              value={detail.nome || ''}
                              onChange={(e) => handleDetailChange(index, 'nome', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor={`descrizione-${index}`}>Descrizione</Label>
                          <Textarea
                            id={`descrizione-${index}`}
                            name="descrizione"
                            value={detail.descrizione || ''}
                            onChange={(e) => handleDetailChange(index, 'descrizione', e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`um-${index}`}>U.M.</Label>
                            <Select
                              name="um"
                              value={detail.um || ''}
                              onValueChange={(value) => handleDetailChange(index, 'um', value)}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Seleziona U.M." />
                              </SelectTrigger>
                              <SelectContent>
                                {um.map(unit => (
                                  <SelectItem key={unit.unit} value={unit.unit}>{unit.unit}</SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`qita-${index}`}>Q.ità</Label>
                            <Input
                              id={`qita-${index}`}
                              name="qita"
                              value={detail.qita || ''}
                              onChange={(e) => handleDetailChange(index, 'qita', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`iva-${index}`}>IVA</Label>
                            <Select
                              value={detail.iva || ''}
                              onValueChange={(value) => handleDetailChange(index, 'iva', value)}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Seleziona IVA" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="22">22%</SelectItem>
                                <SelectItem value="21">21%</SelectItem>
                                <SelectItem value="20">20%</SelectItem>
                                <SelectItem value="10">10%</SelectItem>
                                <SelectItem value="4">4%</SelectItem>
                                <SelectItem value="0">0%</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`sconto-${index}`}>Sconto</Label>
                            <Input
                              id={`sconto-${index}`}
                              name="sconto"
                              value={detail.sconto || ''}
                              onChange={(e) => handleDetailChange(index, 'sconto', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`importonetto-${index}`}>Importo netto</Label>
                            <Input
                              id={`importonetto-${index}`}
                              name="importonetto"
                              value={detail.importonetto || ''}
                              onChange={(e) => handleDetailChange(index, 'importonetto', e.target.value)}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`importo-${index}`}>Importo</Label>
                            <Input
                              id={`importo-${index}`}
                              name="importo"
                              value={detail.importo || ''}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end space-x-2">
                      {itemDetails.length > 1 && (
                        <Button
                          type="button"
                          onClick={() => handleDelete(index)}
                          variant="destructive"
                          size="sm"
                        >
                          Elimina
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button
              type="button"
              onClick={() => handleDuplicate(itemDetails.length - 1)}
              size="sm"
            >
              Aggiungi
            </Button>
          </CardFooter>
        </Card>

        <div className="flex flex-col space-y-2">
          <Card className="bg-white">
            <CardHeader>
              <CardTitle>Riepilogo</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="flex justify-between">
                <span>Prodotti e/o Servizi</span>
                <span className="text-xl">€ {item.prodottival || ''}</span>
              </div>
              <div className="flex justify-between">
                <span>IVA</span>
                <span className="text-xl">€ {item.ivaval || ''}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-bold">Totale</span>
                <span className="text-xl font-bold">€ {item.totaleval || ''}</span>
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-end w-full">
            <Button type="submit">Salva</Button>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <input type="hidden" id="ProdottiSpaninput" name="prodottival" value={item.prodottival || ''} />
        <input type="hidden" id="ivaSpaninput" name="ivaval" value={item.ivaval || ''} />
        <input type="hidden" id="totaleSpaninput" name="totaleval" value={item.totaleval || ''} />
      </div>
    </form>
  );
}

export default DetailComponent;