import React, { useState, useEffect } from 'react';
import { FaDesktop, FaWallet, FaGlobe, FaFile, FaShoppingCart, FaCircle, FaUser, FaAngleLeft, FaAngleRight, FaChartBar, FaMoneyBillWave, FaHardHat, FaList, FaComments } from "react-icons/fa";
import { fetchClients } from '../../../apis/client';
import { fetchSuppliers } from '../../../apis/supplier';
import { fetchMonthlyMovimenti, sumEntra, sumUscita } from '../../../apis/Movimenti';
import { getConstructionSites, getContabilita } from '../../../apis/ConstructionSite';
import { fetchPreventivi } from '../../../apis/Preventivi';
import { getRateizzi } from '../../../apis/RateizziService';
import { ScrollArea } from "../../ui/scroll-area";

import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";
import { Area, AreaChart, Pie, PieChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, LineChart, Line } from "recharts";
import { Separator } from '../../ui/separator';
import Spinner from '../../Spinner';
import { Link, useNavigate } from 'react-router-dom';

const formatNumber = (num) => {
    return typeof num === 'number'
        ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
        : '0,00';
};

const DashBoard = () => {
    const navigate = useNavigate();
    const initialData = {
        cashFlow: 0,
        totalClients: 0,
        totalSuppliers: 0,
        totalConstructionSites: 0,
        sentQuotes: 0,
        stats: {
            week: { quotes: 0, inProgress: 0, sent: 0 },
            month: { quotes: 0, inProgress: 0, sent: 0 },
            day: { quotes: 0, inProgress: 0, sent: 0 }
        },
        siteTypes: [],
        projectStatus: [],
        movimentiData: [],
        preventiviData: []
    };

    const [data, setData] = useState(initialData);
    const [suppliers, setSuppliers] = useState([]);
    const [rateizziStats, setRateizziStats] = useState({
        inCorso: 0,
        totalAmount: 0,
        upcomingPayments: 0
    });
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const userString = localStorage.getItem('user');
                if (!userString) {
                    throw new Error('User not found in local storage');
                }
                const userData = JSON.parse(userString);
                setUser(userData);
                const companyId = userData.companyId;

                if (!companyId) {
                    throw new Error('Company ID not found in user data');
                }

                const [clientsResponse, suppliersResponse, constructionSitesResponse, movimentiData, preventiviResponse, rateizziData] = await Promise.all([
                    fetchClients(companyId, 1, 10, '', ''),
                    fetchSuppliers(companyId, 1, 1000),
                    getConstructionSites(companyId),
                    fetchMonthlyMovimenti(companyId).catch(err => {
                        console.warn('Error fetching movimenti data:', err);
                        return [];
                    }),
                    fetchPreventivi(companyId, 1, 1000),
                    getRateizzi()
                ]);

                // Set suppliers
                setSuppliers(suppliersResponse?.data?.suppliers || []);

                const preventiviData = processPreventiviData(preventiviResponse?.data?.preventivis || []);
                const suppliers = suppliersResponse?.data?.suppliers || [];
                const sites = constructionSitesResponse?.data?.sites || [];

                // Process Rateizzi data
                const now = new Date();
                const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

                const rateizziStats = {
                    inCorso: Array.isArray(rateizziData) ? rateizziData.filter(r => r.status === 'In corso').length : 0,
                    totalAmount: Array.isArray(rateizziData) ? rateizziData.reduce((sum, r) => sum + r.totalAmount, 0) : 0,
                    upcomingPayments: Array.isArray(rateizziData) ? rateizziData.reduce((count, ratezzo) => {
                        const upcomingInstallments = ratezzo.installments.filter(inst =>
                            inst.status !== 'Pagata' &&
                            new Date(inst.dueDate) <= nextWeek &&
                            new Date(inst.dueDate) >= now
                        );
                        return count + upcomingInstallments.length;
                    }, 0) : 0
                };

                setRateizziStats(rateizziStats);

                // Fetch contabilità data for each site
                const sitesWithContabilita = await Promise.all(
                    sites.map(async (site) => {
                        try {
                            const contabilitaData = await getContabilita(site._id);
                            return {
                                name: site.name || 'Unnamed Project',
                                description: site.description || '',
                                progress: contabilitaData.percentagePaid || 0,
                                totalAmount: contabilitaData.totalAmount || 0,
                                amountPaid: contabilitaData.amountPaid || 0
                            };
                        } catch (error) {
                            console.error(`Error fetching contabilità for site ${site._id}:`, error);
                            return {
                                name: site.name || 'Unnamed Project',
                                description: site.description || '',
                                progress: 0,
                                totalAmount: 0,
                                amountPaid: 0
                            };
                        }
                    })
                );

                // Calculate cash flow from movimenti data
                const cashFlow = calculateCashFlowFromMovimenti(movimentiData);

                setData(prevData => ({
                    ...prevData,
                    cashFlow,
                    totalClients: clientsResponse?.data?.totalClients || 0,
                    totalSuppliers: suppliers.length,
                    totalConstructionSites: sites.length,
                    projectStatus: sitesWithContabilita,
                    siteTypes: processWorkTypes(sites),
                    movimentiData: Array.isArray(movimentiData) ? processMovimentiData(movimentiData) : [],
                    preventiviData
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(`An error occurred while loading the dashboard: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const calculateCashFlowFromMovimenti = (movimentiData) => {
        if (!Array.isArray(movimentiData)) return 0;

        return movimentiData.reduce((total, movimento) => {
            const Entra = movimento.Entra?.total || 0;
            const uscita = movimento.Uscita?.total || 0;
            return total + (Entra - uscita);
        }, 0);
    };

    const processMovimentiData = (movimenti) => {
        if (!Array.isArray(movimenti)) return [];

        return movimenti.map(movimento => ({
            month: movimento.date || '',
            Entra: movimento.Entra?.total || 0,
            Uscita: movimento.Uscita?.total || 0
        })).sort((a, b) => a.month.localeCompare(b.month));
    };

    const processWorkTypes = (sites) => {
        const typeCount = sites.reduce((acc, site) => {
            const workType = site.workType || 'Other';
            acc[workType] = (acc[workType] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(typeCount).map(([name, value]) => ({ name, value }));
    };

    const processPreventiviData = (preventivi) => {
        if (!Array.isArray(preventivi)) return [];

        const monthlyData = {};
        preventivi.forEach(preventivo => {
            if (!preventivo?.dataa) return;

            const date = new Date(preventivo.dataa);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            if (!monthlyData[monthYear]) {
                monthlyData[monthYear] = { month: monthYear, count: 0 };
            }
            monthlyData[monthYear].count++;
        });
        return Object.values(monthlyData).sort((a, b) => a.month.localeCompare(b.month));
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-2 border border-gray-300 rounded shadow">
                    <p className="font-semibold">{`${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {`${entry.name}: ${entry.value}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const EmptyState = ({ message }) => (
        <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">{message}</p>
        </div>
    );

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <div className="p-6 text-red-500">{error}</div>;
    }

    const COLORS = ['#4A5568', '#6B46C1', '#4299E1', '#48BB78', '#ED8936', '#ECC94B'];

    return (
        <div id="admin_content" className="px-4">
            {/* Greeting Section */}
            <div className="mb-4 rounded-lg py-4">
                <h1 className="text-xl font-bold mb-2">Ciao, {user?.username || 'Utente'}! felice di rivederti.</h1>
                <p className="text-lg">Rivedi le tue attività, aggiorna le informazioni e mantieni tutto organizzato al meglio.</p>
            </div>

            {/* Statistics Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-10 max-sm:gap-y-2 mb-6">
                <Card className="transition-all duration-300">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Flusso di cassa</CardTitle>
                            <FaMoneyBillWave className="" size={24} />
                        </div>
                        <div className="text-xl font-bold">€{data.cashFlow.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Clienti</CardTitle>
                            <FaUser className="text-green-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalClients}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Fornitori</CardTitle>
                            <FaShoppingCart className="text-purple-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalSuppliers}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Cantieri</CardTitle>
                            <FaHardHat className="text-yellow-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalConstructionSites}</div>
                    </CardContent>
                </Card>
            </div>

            {/* Le tue finanze section */}
            <div>
                <h2 className="text-xl font-bold mb-6">Le tue finanze</h2>
                <Separator className="mb-6" />

                {/* Main Finance Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Movimenti Card */}
                    <Card className="border-0">
                        <CardHeader className="p-0 mb-6">
                            <div className="flex justify-between items-center">
                                <p className="text-sm text-gray-600">
                                    Entrate ed uscite dei tuoi conti bancari
                                </p>
                                <Link
                                    to="/movimenti"
                                    className=" hover:underline text-sm"
                                >
                                    Visualizza Movimenti
                                </Link>
                            </div>

                        </CardHeader>
                        <CardContent className="p-0">
                            {data.movimentiData.length > 0 ? (
                                <ResponsiveContainer width="100%" height={200}>
                                    <AreaChart data={data.movimentiData}>
                                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                                        <YAxis tick={{ fontSize: 10 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Area
                                            type="monotone"
                                            dataKey="Entra"
                                            stroke="#4A5568"
                                            fill="#EDF2F7"
                                            stackId="1"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="Uscita"
                                            stroke="#6B46C1"
                                            fill="#E9D8FD"
                                            stackId="1"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per i movimenti del conto" />
                            )}
                        </CardContent>
                    </Card>

                    {/* Right Column with Ratezzi and Suppliers */}
                    <div className="flex flex-row h-full">
                        {/* Ratezzi Card */}
                        <Card className="border-0 flex-1 px-2">
                            <CardHeader className="p-0 mb-6">
                                <div className="flex flex-col md:flex-row md:items-center justify-between space-y-1 md:space-y-0 md:space-x-4">
                                    <h3 className="font-medium">Situazione ratezzi</h3>
                                    <Link
                                        to="/installment"
                                        className="text-sm  hover:underline"
                                    >
                                        Tutti i ratezzi
                                    </Link>
                                </div>

                            </CardHeader>
                            <CardContent className="px-2 ">
                                <div className="grid grid-cols-1 gap-4">
                                    <div>
                                        <div className="text-xl font-bold">
                                            {rateizziStats.inCorso || "0"}
                                        </div>
                                        <div className="text-sm text-gray-500">Rateizzi in corso</div>
                                    </div>
                                    <div>
                                        <div className="text-xl font-bold">
                                            {rateizziStats.totalAmount ?
                                                `€${new Intl.NumberFormat('it-IT', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(rateizziStats.totalAmount)}` :
                                                "€0,00"
                                            }
                                        </div>
                                        <div className="text-sm text-gray-500">Importo totale dilazionato</div>
                                    </div>
                                    <div>
                                        <div className="text-xl font-bold">
                                            {rateizziStats.upcomingPayments || "0"}
                                        </div>
                                        <div className="text-sm text-gray-500">Rate da pagare nei prossimi giorni</div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        {/* Supplier Balances Card */}
                        <Card className="border-0 flex-1 px-2">
                            <CardHeader className="p-0 mb-6">
                                <div className="flex justify-start items-center">
                                    <CardTitle className="text-lg px-4">Debiti con i fornitori</CardTitle>
                                    {/* <Button variant="link" size="sm" asChild>
                                        <Link to="/suppliers">
                                            Vedi tutti
                                        </Link>
                                    </Button> */}
                                </div>
                            </CardHeader>
                            <CardContent className="px-2">
                                <ScrollArea className="h-[180px]">
                                    <div className="space-y-2">
                                        {suppliers.length > 0 ? (
                                            suppliers.map((supplier, index) => (
                                                <React.Fragment key={supplier._id}>
                                                    <div
                                                        className="flex justify-between items-center py-2 hover:bg-gray-50 px-2 rounded-lg cursor-pointer"
                                                        onClick={() => navigate(`/suppliers/details/${supplier._id}`)}
                                                    >
                                                        <span>{supplier.businessName}</span>
                                                        <span className="text-red-500">€ -0,00</span>
                                                    </div>
                                                    {index < suppliers.length - 1 && <Separator />}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="text-center text-gray-500 py-4">
                                                Nessun fornitore disponibile
                                            </div>
                                        )}
                                    </div>
                                </ScrollArea>
                            </CardContent>
                        </Card>
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-6">Preventivi</h2>
                    <Separator className="mb-6" />

                    {/* Preventivi Section Below */}
                    <Card className="border-0 mb-8">
                        <CardHeader className="p-0 mb-6">
                            <p className="text-sm text-gray-600">
                                Monitora l'andamento mensile dei preventivi emessi
                            </p>
                        </CardHeader>
                        <CardContent className="p-0">
                            {data.preventiviData.length > 0 ? (
                                <ResponsiveContainer width="100%" height={200}>
                                    <LineChart data={data.preventiviData}>
                                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                                        <YAxis tick={{ fontSize: 10 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Line type="monotone" dataKey="count" stroke="#8884d8" name="Preventivi" />
                                    </LineChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per i preventivi mensili" />
                            )}
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* Construction Sites Section */}
            <div>
                <h2 className="text-xl font-bold mb-6">I tuoi cantieri</h2>
                <Separator className="mb-6" />
                <p className="text-sm text-gray-600 mb-2">Visualizza lo stato di avanzamento dei cantieri in corso</p>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
                    <Card className="lg:col-span-2 border-0">
                        <CardHeader className="bg-gray-50 border-gray-200 p-0">
                        </CardHeader>
                        <CardContent className="p-2">
                            <ScrollArea className="h-[400px] pr-4">
                                <div className="space-y-4">
                                    {data.projectStatus.map((project, index) => (
                                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                                            <div className="flex justify-between items-center mb-2">
                                                <h5 className="text-md font-semibold">{project.name}</h5>
                                                <span className="text-sm font-medium text-gray-600">
                                                    {formatNumber(project.progress)}%
                                                </span>
                                            </div>
                                            <p className="text-gray-600 mb-2">{project.description}</p>
                                            <Progress value={project.progress} className="w-full h-2" />
                                            <div className="text-sm text-gray-500 mt-2">
                                                €{formatNumber(project.amountPaid)} / €{formatNumber(project.totalAmount)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </ScrollArea>
                        </CardContent>
                    </Card>

                    <Card className="border-0 w-full">
                        <CardContent className="p-2" style={{ height: '400px' }}>
                            {data.siteTypes.length > 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={data.siteTypes}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#E9D8FD"
                                            paddingAngle={5}
                                            dataKey="value"
                                        >
                                            {data.siteTypes.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per la tipologia dei cantieri" />
                            )}
                        </CardContent>
                    </Card>
                </div>

                {/* Chat section */}
                <Card className="border-0 mb-6">
                    <CardHeader className="bg-gray-50 border-gray-200">
                    </CardHeader>
                    <CardContent className="p-6">
                        <EmptyState message="Funzionalità chat non ancora implementata" />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default DashBoard;