import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Card } from "../../ui/card";

// These would typically be imported from your assets
import clientExampleFile from "../../../assest/clientFormatFile.xlsx";
import supplierExampleFile from "../../../assest/importSupplierFileFormat.xlsx";
import employeeExampleFile from "../../../assest/importEmployeeFileFomat.xlsx";

const ImportFile = ({ open, onOpenChange, importFunction, vendorType }) => {
  const [fileData, setFileData] = useState(null);
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);

  const getExampleFile = () => {
    switch (vendorType) {
      case "Clienti":
        return clientExampleFile;
      case "Suppliers":
        return supplierExampleFile;
      case "Employees":
        return employeeExampleFile;
      default:
        return "#";
    }
  };

  const transformKeys = (data) => {
    return data.map((item) => {
      const transformedItem = {};
      for (const key in item) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        transformedItem[newKey] = item[key];
      }
      return transformedItem;
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const transformedJson = transformKeys(json);
      setFileData(transformedJson);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onSubmit = async () => {
    if (fileData) {
      importFunction(fileData);
      onOpenChange(false);
    } else {
      toast.error("Please upload a file first.");
    }
  };

  const handleClose = () => {
    setFileData(null);
    setFileName("");
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Carica un file excel per importare</DialogTitle>
          <DialogDescription>
            {vendorType && <div className="mt-2">{vendorType}</div>}
            <div className="mt-2">
              Non hai un file di esempio?{" "}
              <a
                download
                href={getExampleFile()}
                className="text-blue-600 underline cursor-pointer hover:text-blue-800"
              >
                Scaricalo qui
              </a>
            </div>
          </DialogDescription>
        </DialogHeader>

        <Card
          className="flex justify-center items-center border-2 border-dashed rounded-lg p-6 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors"
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            ref={hiddenFileInput}
            className="hidden"
          />
          <p className="text-center text-gray-700 font-medium">
            {fileName
              ? `File caricato: ${fileName}`
              : "Trascina il file qui o clicca per caricare"}
          </p>
        </Card>

        <DialogFooter className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleClose}>
            Chiudi
          </Button>
          <Button onClick={onSubmit}>
            Importa
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ImportFile;