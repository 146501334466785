import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getContabilita, addProgressStatus, updateProgressStatus, deleteProgressStatus, getConstructionSite } from '../../../apis/ConstructionSite';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';
import { exportToExcel, exportToPDF } from "../../../config/helper";
import { Archive, Loader2 } from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { toast } from 'react-toastify';

function Sal() {
  const { id } = useParams();
  const [contabilita, setContabilita] = useState({
    totalAmount: 0,
    amountPaid: 0,
    amountRemaining: 0,
    percentagePaid: 0,
    progressStatuses: []
  });
  const [newStatus, setNewStatus] = useState({ date: '', amount: '' });
  const [editingStatus, setEditingStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, statusId: null });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [site, setSite] = useState(null);

  useEffect(() => {
    fetchSiteStatus();
    fetchContabilita();
  }, [id]);

  const fetchSiteStatus = async () => {
    try {
      const response = await getConstructionSite(id);
      setIsArchived(response.data.status === 'Archiviato');
      setSite(response.data); // Store site data
    } catch (error) {
      console.error('Error fetching site status:', error);
    }
  };

  const fetchContabilita = async () => {
    setIsLoading(true);
    try {
      const data = await getContabilita(id);
      setContabilita(data);
    } catch (error) {
      console.error('Error fetching contabilita:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    if (isArchived) return;
    setNewStatus({ ...newStatus, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!site) {
      toast.error('Dati del cantiere non disponibili');
      return;
    }
  
    try {
      const progressStatus = {
        date: newStatus.date,
        amount: parseFloat(newStatus.amount),
        createPrimaNota: true,
        clientId: site.client,
        siteName: site.name
      };
  
      await addProgressStatus(id, progressStatus);
      fetchContabilita();
      setNewStatus({ date: '', amount: '' });
      setIsAddModalOpen(false);
      toast.success('SAL e Prima Nota creati con successo');
    } catch (error) {
      console.error('Error adding progress status:', error);
      toast.error(error.message || 'Errore durante la creazione del SAL');
    }
  };

  const handleEdit = (status) => {
    if (isArchived) return;
    setEditingStatus({ ...status });
    setIsEditModalOpen(true);
  };

  const handleEditSave = async () => {
    if (isArchived) return;
    try {
      await updateProgressStatus(id, editingStatus._id, editingStatus);
      fetchContabilita();
      setEditingStatus(null);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating progress status:', error);
    }
  };

  const handleDeleteClick = (statusId) => {
    if (isArchived) return;
    setDeleteModal({ isOpen: true, statusId });
  };

  const handleDeleteConfirm = async () => {
    if (isArchived) return;
    try {
      await deleteProgressStatus(id, deleteModal.statusId);
      fetchContabilita();
      setDeleteModal({ isOpen: false, statusId: null });
    } catch (error) {
      console.error('Error deleting progress status:', error);
    }
  };

  const handleExport = (value) => {
    const formattedData = contabilita.progressStatuses.map((status, index) => ({
      nu: (index + 1).toString(),
      data: new Date(status.date).toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }),
      amount: `€${formatNumber(status.amount)}`
    }));

    const columns = [
      { header: "N°", key: "nu" },
      { header: "Data", key: "data" },
      { header: "Importo", key: "amount" }
    ];

    const stats = [
      { label: "Importo Totale", value: `€${formatNumber(contabilita.totalAmount)}` },
      { label: "Importo Pagato", value: `€${formatNumber(contabilita.amountPaid)}` },
      { label: "Importo Rimanente", value: `€${formatNumber(contabilita.amountRemaining)}` },
      { label: "Percentuale Pagata", value: `${formatNumber(contabilita.percentagePaid)}%` }
    ];

    if (value === "excel") {
      exportToExcel(formattedData, "Contabilita");
    } else if (value === "pdf") {
      exportToPDF(formattedData, columns, "Elenco Contabilita", stats);
    }
  };

  const formatNumber = (num) => {
    return typeof num === 'number'
      ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
      : '0,00';
  };

  return (
    <div>
      <ConstructionSiteNavbar />

      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare la contabilità.
              </p>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-6 px-2">
            <Card className="bg-white">
              <CardHeader>
                <CardTitle>Importo Totale</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">€{formatNumber(contabilita.totalAmount)}</p>
              </CardContent>
            </Card>
            <Card className="bg-white">
              <CardHeader>
                <CardTitle>Importo Pagato</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-green-600">€{formatNumber(contabilita.amountPaid)}</p>
              </CardContent>
            </Card>
            <Card className="bg-white">
              <CardHeader>
                <CardTitle>Importo Rimanente</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-red-600">€{formatNumber(contabilita.amountRemaining)}</p>
              </CardContent>
            </Card>
            <Card className="bg-white">
              <CardHeader>
                <CardTitle>Percentuale Pagata</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">{formatNumber(contabilita.percentagePaid)}%</p>
              </CardContent>
            </Card>
          </div>

          <CardContent>
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                {!isArchived && (
                  <Button onClick={() => setIsAddModalOpen(true)}>
                    Aggiungi
                  </Button>
                )}
                <Select onValueChange={handleExport}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Esporta" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excel">Esporta in Excel</SelectItem>
                    <SelectItem value="pdf">Esporta in PDF</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Numero</TableHead>
                  <TableHead>Data</TableHead>
                  <TableHead className="text-right">Importo</TableHead>
                  {!isArchived && <TableHead className="text-center">Azioni</TableHead>}
                </TableRow>
              </TableHeader>
              <TableBody>
                {contabilita.progressStatuses.map((status, index) => (
                  <TableRow key={status._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{new Date(status.date).toLocaleDateString()}</TableCell>
                    <TableCell className="text-right">{`€${formatNumber(status.amount)}`}</TableCell>
                    {!isArchived && (
                      <TableCell className="text-center">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleEdit(status)}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteClick(status._id)}
                        >
                          <FaTrash className='text-red-600' />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </>
      )}

      {!isArchived && (
        <>
          <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Aggiungi Stato di Avanzamento</DialogTitle>
                <DialogDescription>
                  Inserisci i dettagli del nuovo stato di avanzamento.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 py-4">
                  <div className="space-y-2">
                    <label htmlFor="date">Data</label>
                    <Input
                      id="date"
                      type="date"
                      name="date"
                      value={newStatus.date}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="amount">Importo</label>
                    <Input
                      id="amount"
                      type="number"
                      name="amount"
                      step="0.01"
                      value={newStatus.amount}
                      onChange={handleInputChange}
                      placeholder="Importo"
                      required
                    />
                  </div>
                </div>
                <DialogFooter>
                  <Button type="button" variant="outline" onClick={() => setIsAddModalOpen(false)}>
                    Annulla
                  </Button>
                  <Button type="submit">Salva</Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Modifica Stato di Avanzamento</DialogTitle>
                <DialogDescription>
                  Modifica i dettagli dello stato di avanzamento.
                </DialogDescription>
              </DialogHeader>
              {editingStatus && (
                <div className="grid gap-4 py-4">
                  <div className="space-y-2">
                    <label htmlFor="edit-date">Data</label>
                    <Input
                      id="edit-date"
                      type="date"
                      value={editingStatus.date.split('T')[0]}
                      onChange={(e) => setEditingStatus({ ...editingStatus, date: e.target.value })}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="edit-amount">Importo</label>
                    <Input
                      id="edit-amount"
                      type="number"
                      step="0.01"
                      value={editingStatus.amount}
                      onChange={(e) => setEditingStatus({ ...editingStatus, amount: e.target.value })}
                      required
                    />
                  </div>
                </div>
              )}
              <DialogFooter>
                <Button type="button" variant="outline" onClick={() => {
                  setIsEditModalOpen(false);
                  setEditingStatus(null);
                }}>
                  Annulla
                </Button>
                <Button onClick={handleEditSave}>
                  Aggiorna
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={deleteModal.isOpen} onOpenChange={(isOpen) => setDeleteModal({ ...deleteModal, isOpen })}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Conferma eliminazione</DialogTitle>
                <DialogDescription>
                  Sei sicuro di voler eliminare questo stato di avanzamento?
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button variant="outline" onClick={() => setDeleteModal({ isOpen: false, statusId: null })}>
                  Annulla
                </Button>
                <Button variant="destructive" onClick={handleDeleteConfirm}>
                  Elimina
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Sal;