// apis/notificationApi.js
import { useState, useEffect, useCallback } from 'react';
import { createJsonTypeInstance } from './index';

const axios = createJsonTypeInstance();

// Get all notifications with optional filtering and pagination
export const getNotifications = async (params = {}) => {
  try {
    const { status, type, page = 1, limit = 10 } = params;
    const queryParams = new URLSearchParams();
    
    if (status) queryParams.append('status', status);
    if (type) queryParams.append('type', type);
    queryParams.append('page', page);
    queryParams.append('limit', limit);

    const response = await axios.get(`/notifications?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Get notification preferences
export const getNotificationPreferences = async () => {
  try {
    const response = await axios.get('/notifications/preferences');
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Update notification preferences
export const updateNotificationPreferences = async (preferences) => {
  try {
    const response = await axios.put('/notifications/preferences', preferences);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Get unread notifications count
export const getUnreadCount = async () => {
  try {
    const response = await axios.get('/notifications', {
      params: {
        status: 'unread',
        limit: 1
      }
    });
    return response.data.pagination.total;
  } catch (error) {
    console.error('Error fetching unread count:', error);
    return 0;
  }
};

// Mark a single notification as read
export const markAsRead = async (notificationId) => {
  try {
    const response = await axios.patch(`/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Mark all notifications as read
export const markAllAsRead = async () => {
  try {
    const response = await axios.patch('/notifications/read-all');
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Delete a notification
export const deleteNotification = async (notificationId) => {
  try {
    const response = await axios.delete(`/notifications/${notificationId}`);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Manually trigger check for expiring construction sites
export const checkExpiringSites = async () => {
  try {
    const response = await axios.post('/notifications/check-expiring');
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw error.response.data;
    }
    throw { message: 'Network error occurred' };
  }
};

// Hook for managing notification preferences
export const useNotificationPreferences = () => {
  const [preferences, setPreferences] = useState({
    inApp: true,
    email: true,
    push: false,
    constructionSiteExpiration: true,
    constructionSiteUpdates: true,
    documentExpiration: true,
    documentUpdates: true,
    systemUpdates: true,
    securityAlerts: true
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPreferences = async () => {
    try {
      setLoading(true);
      const response = await getNotificationPreferences();
      setPreferences(response.preferences);
      setError(null);
    } catch (err) {
      setError(err.message || 'Error fetching notification preferences');
      console.error('Error fetching preferences:', err);
    } finally {
      setLoading(false);
    }
  };

  const updatePreferences = async (newPreferences) => {
    try {
      setLoading(true);
      await updateNotificationPreferences(newPreferences);
      setPreferences(newPreferences);
      setError(null);
      return true;
    } catch (err) {
      setError(err.message || 'Error updating notification preferences');
      console.error('Error updating preferences:', err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  return {
    preferences,
    loading,
    error,
    updatePreferences,
    refreshPreferences: fetchPreferences
  };
};

// WebSocket integration for real-time notifications
export class NotificationSocket {
  constructor(token) {
    this.socket = null;
    this.token = token;
    this.callbacks = {
      onNotification: () => {},
      onConnect: () => {},
      onDisconnect: () => {},
      onError: () => {}
    };
  }

  connect() {
    if (!this.socket) {
      const wsUrl = process.env.REACT_APP_WS_URL || 'ws://localhost:8000';
      this.socket = new WebSocket(`${wsUrl}/notifications?token=${this.token}`);

      this.socket.onopen = () => {
        console.log('NotificationSocket connected');
        this.callbacks.onConnect();
      };

      this.socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          this.callbacks.onNotification(data);
        } catch (error) {
          console.error('Error parsing notification:', error);
        }
      };

      this.socket.onclose = () => {
        console.log('NotificationSocket disconnected');
        this.callbacks.onDisconnect();
        // Attempt to reconnect after 5 seconds
        setTimeout(() => this.connect(), 5000);
      };

      this.socket.onerror = (error) => {
        console.error('NotificationSocket error:', error);
        this.callbacks.onError(error);
      };
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  onNotification(callback) {
    this.callbacks.onNotification = callback;
  }

  onConnect(callback) {
    this.callbacks.onConnect = callback;
  }

  onDisconnect(callback) {
    this.callbacks.onDisconnect = callback;
  }

  onError(callback) {
    this.callbacks.onError = callback;
  }
}

// Notification hooks for React components
export const useNotifications = (initialPage = 1, initialLimit = 10) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: initialPage,
    limit: initialLimit,
    total: 0,
    pages: 0
  });

  const fetchNotifications = useCallback(async (page = initialPage) => {
    try {
      setLoading(true);
      const response = await getNotifications({
        page,
        limit: pagination.limit
      });
      setNotifications(response.notifications);
      setPagination(response.pagination);
      setError(null);
    } catch (err) {
      setError(err.message || 'Error fetching notifications');
    } finally {
      setLoading(false);
    }
  }, [pagination.limit]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const markRead = async (id) => {
    try {
      await markAsRead(id);
      setNotifications(prev =>
        prev.map(notification =>
          notification._id === id
            ? { ...notification, status: 'read' }
            : notification
        )
      );
    } catch (err) {
      setError(err.message || 'Error marking notification as read');
    }
  };

  const deleteNotif = async (id) => {
    try {
      await deleteNotification(id);
      setNotifications(prev =>
        prev.filter(notification => notification._id !== id)
      );
    } catch (err) {
      setError(err.message || 'Error deleting notification');
    }
  };

  return {
    notifications,
    loading,
    error,
    pagination,
    fetchNotifications,
    markRead,
    deleteNotif
  };
};

// Also provide a default export for backwards compatibility
export default {
  getNotifications,
  getUnreadCount,
  markAsRead,
  markAllAsRead,
  deleteNotification,
  checkExpiringSites,
  NotificationSocket,
  useNotifications,
  useNotificationPreferences,
  getNotificationPreferences,
  updateNotificationPreferences
};