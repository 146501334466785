import React, { useState, useEffect } from 'react';
import { Card } from '../../../../ui/card';
import { Button } from '../../../../ui/button';
import { Input } from '../../../../ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../../../ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import { Eye, Pencil, Trash } from 'lucide-react';
import { toast } from 'react-toastify';
import * as MedicalVisitsAPI from '../../../../../apis/MedicalVisits';
import HelpSheet from "../../../sharedComponent/HelpSheet";

const MedicalVisits = ({ employeeData = [] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visits, setVisits] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [visitDate, setVisitDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [notes, setNotes] = useState('');
  const [editingVisit, setEditingVisit] = useState(null);
  const [visitToDelete, setVisitToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const getExpiryStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const diffTime = expiry - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return { status: "Scaduta", color: "text-red-500" };
    } else if (diffDays <= 30) {
      return { status: "In Scadenza", color: "text-orange-500" };
    } else {
      return { status: "Valida", color: "text-green-500" };
    }
  };

  const fetchVisits = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user?.companyId) {
        toast.error('Company ID not found');
        return;
      }

      const response = await MedicalVisitsAPI.getMedicalVisits(user.companyId, currentPage, 10);

      if (response.status === 200) {
        setVisits(response.data.visits || []);
        setTotalPages(response.data.totalPages || 1);
      }
    } catch (error) {
      toast.error('Failed to fetch medical visits');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (employeeData?.length > 0) {
      fetchVisits();
    }
  }, [currentPage, employeeData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!selectedEmployee) {
      toast.error('Seleziona un dipendente');
      return;
    }

    if (!visitDate) {
      toast.error('Inserisci la data della visita');
      return;
    }

    if (!expiryDate) {
      toast.error('Inserisci la data di scadenza');
      return;
    }

    try {
      setIsLoading(true);
      const formData = {
        employeeId: selectedEmployee,
        visitDate,
        expiryDate,
        notes,
        file: selectedFile
      };

      if (editingVisit) {
        await MedicalVisitsAPI.updateMedicalVisit(editingVisit._id, formData);
        toast.success('Visit updated successfully');
      } else {
        await MedicalVisitsAPI.addMedicalVisit(formData);
        toast.success('Visit added successfully');
      }

      fetchVisits();
      resetForm();
    } catch (error) {
      toast.error(error.message || 'Failed to save medical visit');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (visit) => {
    setVisitToDelete(visit);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!visitToDelete) return;

    try {
      setIsLoading(true);
      await MedicalVisitsAPI.deleteMedicalVisit(visitToDelete._id);
      toast.success('Medical visit deleted successfully');
      setShowDeleteModal(false);
      setVisitToDelete(null);
      fetchVisits();
    } catch (error) {
      toast.error('Failed to delete medical visit');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedEmployee(null);
    setVisitDate('');
    setExpiryDate('');
    setNotes('');
    setSelectedFile(null);
    setEditingVisit(null);
    setShowModal(false);
  };

  const handleEdit = (visit) => {
    setEditingVisit(visit);
    setSelectedEmployee(visit.employeeId._id);
    setVisitDate(new Date(visit.visitDate).toISOString().split('T')[0]);
    setExpiryDate(new Date(visit.expiryDate).toISOString().split('T')[0]);
    setNotes(visit.notes);
    setShowModal(true);
  };

  const getDocumentUrl = (documentUrl) => {
    if (!documentUrl) return '';
    if (documentUrl.startsWith('http')) return documentUrl;
    return `${backendUrl}${documentUrl}`;
  };

  if (!employeeData?.length) {
    return (
      <Card className="p-4">
        <div className="text-center py-4">Loading employee data...</div>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Button
          onClick={() => setShowModal(true)}
          className="flex items-center gap-2"
        >
          Aggiungi Visita Medica
        </Button>
        <HelpSheet />
      </div>

      <Card className="p-4">
        <div className="relative w-full overflow-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Matricola</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>Data Visita</TableHead>
                <TableHead>Data Scadenza</TableHead>
                <TableHead className="text-right">Azioni</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {visits.map((visit) => {
                const expiryStatus = getExpiryStatus(visit.expiryDate);
                return (
                  <TableRow key={visit._id}>
                    <TableCell>{visit.employeeId.freshman}</TableCell>
                    <TableCell>
                      {`${visit.employeeId.firstName} ${visit.employeeId.sureName}`}
                    </TableCell>
                    <TableCell>
                      {new Date(visit.visitDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(visit.expiryDate).toLocaleDateString()}
                      <span className={`ml-2 ${expiryStatus.color}`}>
                        ({expiryStatus.status})
                      </span>
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex justify-end gap-2">
                        {visit.documentUrl && (
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => window.open(getDocumentUrl(visit.documentUrl), '_blank')}
                          >
                            <Eye className="w-4 h-4" />
                          </Button>
                        )}
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEdit(visit)}
                        >
                          <Pencil className="w-4 h-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="text-red-600 hover:text-red-700"
                          onClick={() => handleDeleteClick(visit)}
                        >
                          <Trash className="w-4 h-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {visits.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-4 text-muted-foreground">
                    Nessuna visita medica trovata
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center gap-2 mt-4">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                variant={page === currentPage ? "default" : "outline"}
                size="sm"
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            ))}
          </div>
        )}
      </Card>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editingVisit ? 'Modifica Visita Medica' : 'Aggiungi Visita Medica'}
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Dipendente
                <span className="text-red-500">*</span>
              </label>
              <select
                value={selectedEmployee || ''}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                className="w-full p-2 border rounded"
                disabled={isLoading}
                required
              >
                <option value="">Seleziona Dipendente</option>
                {employeeData?.map((emp) => (
                  <option key={emp.employeeId} value={emp.employeeId}>
                    {`${emp.name} ${emp.sureName} (${emp.freshman})`}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Data Visita
                <span className="text-red-500">*</span>
              </label>
              <Input
                type="date"
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            <div className="space-y-2">
              <label className="flex items-center gap-1">
                Data Scadenza
                <span className="text-red-500">*</span>
              </label>
              <Input
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>

            <div className="space-y-2">
              <label>Note</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full p-2 border rounded"
                disabled={isLoading}
              />
            </div>

            <div className="space-y-2">
              <label>Documento</label>
              <Input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                disabled={isLoading}
              />
            </div>

            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={resetForm}>
                Annulla
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Salvando...' : 'Salva'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            Sei sicuro di voler eliminare questa visita medica?
            {visitToDelete && (
              <div className="mt-2 text-sm text-gray-500">
                Per il dipendente: {visitToDelete.employeeId.firstName} {visitToDelete.employeeId.sureName}
                <br />
                Data visita: {new Date(visitToDelete.visitDate).toLocaleDateString()}
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowDeleteModal(false);
                setVisitToDelete(null);
              }}
              disabled={isLoading}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? 'Eliminando...' : 'Elimina'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MedicalVisits;