import React, { useEffect, useState } from "react";
import { Edit, Trash2, Eye } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { it } from "date-fns/locale";

import {
  fetchTrainings,
  deleteTrainingCertificate,
  fetchSingleTraining,
} from "../../../../../apis/training";

import { Button } from "../../../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import AddCertificate from "./AddCertificate";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import Pagination from "../../../sharedComponent/Pgination";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Training = ({ employeeData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [singleTraining, setSingleTraining] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const companyId = user.companyId;

  const getExpiryStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const diffTime = expiry - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return { status: "Scaduta", color: "text-red-500" };
    } else if (diffDays <= 30) {
      return { status: "In Scadenza", color: "text-orange-500" };
    } else {
      return { status: "Valida", color: "text-green-500" };
    }
  };

  const getTrainings = async () => {
    try {
      const response = await fetchTrainings(
        companyId,
        currentPage,
        limit,
        searchQuery
      );
      if (response.status === 200) {
        setTrainingData(response.data.trainingCertificates);
        setTotalPage(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati di formazione", error);
      toast.error("Impossibile recuperare i dati di formazione");
    }
  };

  useEffect(() => {
    getTrainings();
  }, [isUpdated, currentPage, limit, searchQuery]);

  const editDocument = async (id) => {
    try {
      setSelectedId(id);
      const response = await fetchSingleTraining(id);
      setSingleTraining(response.data?.trainingCertificate);
      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati del certificato", error);
      toast.error("Impossibile recuperare i dati del certificato");
    }
  };

  const handleDeleteDocument = async () => {
    try {
      const response = await deleteTrainingCertificate(selectedId);
      if (response.status === 200) {
        toast.success("Certificato di formazione eliminato con successo!");
        setIsUpdated(!isUpdated);
        setIsDeleteModalOpen(false);
      } else {
        toast.error("Impossibile eliminare il certificato di formazione.");
      }
    } catch (error) {
      console.error("Errore durante l'eliminazione del certificato", error);
      toast.error("Si è verificato un errore durante l'eliminazione del certificato di formazione.");
    }
  };

  const getDocumentUrl = (documentUrl) => {
    if (!documentUrl) return '';
    if (documentUrl.startsWith('http')) return documentUrl;

    // Remove any leading slash from documentUrl to avoid double slashes
    const cleanDocumentUrl = documentUrl.startsWith('/') ? documentUrl.slice(1) : documentUrl;

    // Ensure backendUrl ends with a slash
    const cleanBackendUrl = backendUrl.endsWith('/') ? backendUrl : `${backendUrl}/`;

    return `${cleanBackendUrl}${cleanDocumentUrl}`;
  };

  const handleViewDocument = (imagePath) => {
    if (!imagePath) {
      toast.error("Nessun documento disponibile per la visualizzazione");
      return;
    }

    const fullUrl = getDocumentUrl(imagePath);
    window.open(fullUrl, '_blank');
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        <Button onClick={() => setIsModalOpen(true)}>
          Aggiungi Certificato
        </Button>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Matricola</TableHead>
              <TableHead className="text-center">Nome</TableHead>
              <TableHead className="text-center">Cognome</TableHead>
              <TableHead className="text-center">Qualifica</TableHead>
              <TableHead className="text-center">Attestato</TableHead>
              <TableHead className="text-center">Scadenza</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {trainingData?.length > 0 ? (
              trainingData?.map((data, index) => {
                const expiryStatus = getExpiryStatus(data.expiration);
                return (
                  <TableRow key={index}>
                    <TableCell className="text-center">{data?.employee?.freshman || '-'}</TableCell>
                    <TableCell className="text-center">{data.employee?.firstName}</TableCell>
                    <TableCell className="text-center">{data.employee?.sureName || '-'}</TableCell>
                    <TableCell className="text-center">{data.employee?.Cognometion}</TableCell>
                    <TableCell className="text-center">{data.attestedName}</TableCell>
                    <TableCell className="text-center">
                      {format(new Date(data.expiration), "dd/MM/yyyy", { locale: it })}
                      <span className={`ml-2 ${expiryStatus.color}`}>
                        ({expiryStatus.status})
                      </span>
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-center space-x-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleViewDocument(data.image)}
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => editDocument(data._id)}
                        >
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            setSelectedId(data._id);
                            setIsDeleteModalOpen(true);
                          }}
                        >
                          <Trash2 className='h-4 w-4 text-red-600' />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {trainingData?.length > 0 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onPageChange={(page) => setCurrentPage(page)}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <AddCertificate
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsModalOpen={setIsModalOpen}
        employeeData={employeeData}
        selectedId={selectedId}
        setIsUpdated={setIsUpdated}
        isUpdated={isUpdated}
        companyId={companyId}
        singleTraining={singleTraining}
      />

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Elimina Certificato</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questo certificato? Questa azione è irreversibile.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteDocument}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Training;