export const QUALIFICATION_LEVELS = [
  {
    key: "1",
    value: "Impiegati di 4ª categoria primo impiego e operai comuni (Manovale)",
    level: "1"
  },
  {
    key: "2",
    value: "Impiegati di 4ª categoria e operai qualificati",
    level: "2"
  },
  {
    key: "3",
    value: "Impiegati di 3ª categoria e operai specializzati",
    level: "3"
  },
  {
    key: "4",
    value: "Assistenti tecnici già inquadrati in 3ª categoria e operai di 4° livello",
    level: "4"
  },
  {
    key: "5",
    value: "Impiegati di 2ª categoria e operai di 5° livello",
    level: "5"
  },
  {
    key: "6",
    value: "Impiegati di 1ª categoria",
    level: "6"
  },
  {
    key: "7",
    value: "Quadri e impiegati di 1ª categoria super",
    level: "7"
  }
];

export const ADD_EMPLOYEE_CONTACT_FIELDS = [
  {
    label: 'Tipo',
    name: 'guy',
    type: 'select',
    options: [
      { key: "determined", value: "A tempo determinato" },
      { key: "undetermined", value: "A tempo indeterminato" }
    ],
  },
  {
    label: 'Matricola',
    name: 'freshman',
    type: 'text',
  },
  { label: 'Inizio contratto', name: 'start', type: 'date' },
  { label: 'Fine contratto', name: 'end', type: 'date' },
  { label: 'Mansione', name: 'task', type: 'text' },
  {
    label: 'Qualifica',
    name: 'qualification',
    type: 'select',
    options: QUALIFICATION_LEVELS
  },
  {
    label: 'Livello',
    name: 'level',
    type: 'text',
    readOnly: true
  },
  {
    label: 'Tipologia di orario',
    name: 'timeType',
    type: 'select',
    options: [
      { key: "full-time", value: "Full-time" },
      { key: "part-time", value: "Part-time" },
      { key: "mixed", value: "Mixed" }
    ]
  },
  {
    label: 'Ore di lavoro settimanali',
    name: 'weeklyWorkingHours',
    type: 'number',
    min: 0,
  },
  {
    label: 'Ore di lavoro mensili',
    name: 'monthlyWorkingHours',
    type: 'number',
    min: 0,
  },
  {
    label: 'Ore di lavoro annuali',
    name: 'annualWorkingHours',
    type: 'number',
    min: 0,
  },
  {
    label: 'Ore di ferie annuali',
    name: 'maxVacationHours',
    type: 'number',
    min: 0,
  },
  {
    label: 'Ore di permessi ROL annuali',
    name: 'maxROLHours',
    type: 'number',
    min: 0,
  },
];

export const ADD_EMPLOYEE_INFO_FIELDS = [
  { label: "Nome", name: "firstName", type: "text" },
  { label: "Cognome", name: "sureName", type: "text" },
  { label: "Indirizzo", name: "address", type: "text" },
  { label: "Città", name: "city", type: "text" },
  { label: "Provincia", name: "province", type: "text" },
  { label: "CAP", name: "postalCode", type: "" },
  { label: "Data di nascita", name: "dateOfBirth", type: "date" },
  {
    label: "Sesso", name: "sex", type: "select", options: [
      { key: "male", value: "Uomo" },
      { key: "female", value: "Donna" },
      { key: "other", value: "Altro" }
    ]
  },
  { label: "Cellulare", name: "telephone", type: "number" },
  { label: "E-mail", name: "email", type: "email" },
  { label: "IBAN", name: "iban", type: "text" },
  { label: "Codice fiscale", name: "fiscalCode", type: "text" },
];

export const ADD_EMPLOYEE_REMUNERATION = [
  { label: "Retribuzione oraria lorda", name: "grosshourlywage", type: "text" },
  { label: "Contingenza", name: "contingency", type: "text" },
  { label: "EDR", name: "edr", type: "text" },
  { label: "Indennità territoriale", name: "territorialAllowance", type: "text" },
];

export const ADD_AGENCY_INFO_FIELDS = [
  { label: "Logo 1", name: "logo1", type: "image" },
  { label: "Logo 2", name: "logo2", type: "image" },
  { label: "Business Name", name: "businessName", type: "text" },
  { label: "Nickname", name: "nickname", type: "text" },
  { label: "Subtitle Line 1", name: "subtitleLine1", type: "text" },
  { label: "Subtitle Line 2", name: "subtitleLine2", type: "text" },
  { label: "Indirizzo", name: "address", type: "text" },
  { label: "Nation", name: "nation", type: "select", options: [] },
  { label: "State", name: "state", type: "select", options: [] },
  { label: "Città", name: "city", type: "select", options: [] },
  { label: "CAP", name: "postalCode", type: "text" },
  { label: "Indirizzo Note", name: "addressNote", type: "textarea" },
  { label: "Tax ID Code", name: "taxIdCode", type: "text" },
  { label: "Business Email", name: "businessEmail", type: "email" },
  { label: "Secondary Email", name: "secondaryEmail", type: "email" },
  { label: "Telephone Number", name: "telephoneNumber", type: "number" },
  { label: "Mobile Number", name: "mobileNumber", type: "number" },
  { label: "Fax", name: "fax", type: "text" },
  { label: "Type of Business", name: "typeOfBusiness", type: "text" },
  { label: "Website", name: "website", type: "text" },
  { label: "INPS Position", name: "inpsPosition", type: "text" },
  { label: "INAIL Position", name: "inailPosition", type: "text" },
  { label: "Bank", name: "bank", type: "text" },
  { label: "IBAN", name: "iban", type: "text" }
];
