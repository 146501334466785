import {createJsonTypeInstance} from './index';
const axios = createJsonTypeInstance();

export const AddCompanyShares = async (data) => {
  try {
    const response = await axios.post('propertyShares/addOrUpdateMembers', data);
    return response;
  } catch (error) {
    throw error.response?.data || error; 
  }
};

export const getCompanySharesData = async (id) => {
  try {
    const response = await axios.get(`/propertyShares/${id}`);
    return response;
  } catch (error) {
    throw error.response || error;
  }
};

export const deleteMemberShares = async (data) => {
  try {
    const response = await axios.delete('/propertyShares/memberDelete', {
      data: data
    });
    return response;
  } catch (error) {
    console.error('Delete error:', error.response || error);
    throw error.response?.data || error;
  }
};

export const updateMemberShare = async (data) => {
  try {
    // Ensure the data is properly structured
    const { companyId, memberId, updates } = data;
    const response = await axios.patch('/propertyShares/updateMember', {
      companyId,
      memberId,
      updates: {
        ...updates,
        _id: memberId  // Include the _id in the updates
      }
    });
    return response;
  } catch (error) {
    console.error('Update error:', error.response || error);
    throw error.response?.data || error;
  }
};