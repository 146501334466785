import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createTax = async (data) => {
  try {
    const response = await axios.post('/tax', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getTaxes = async (params) => {
  try {
    const response = await axios.get('/tax', { params });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updateTax = async (id, data) => {
  try {
    const response = await axios.put(`/tax/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteTax = async (id) => {
  try {
    const response = await axios.delete(`/tax/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateStatus = async (ids, status) => {
  try {
    const response = await axios.patch('/tax/status', { ids, status });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};