import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/button';

const CheckEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || 'your email';

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-950 to-blue-800 p-4">
      <div className="max-w-md w-full bg-white rounded-lg p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-6">
          Verify Your Email Address
        </h2>
        <div className="text-center space-y-4">
          <p className="text-gray-600">
            We've sent a verification link to <span className="font-semibold">{email}</span>.
            Please check your inbox and click the link to activate your account.
          </p>
          <div className="space-y-4">
            <Button
              onClick={() => navigate('/login')}
              className="w-full bg-blue-600 hover:bg-blue-700"
            >
              Return to Login
            </Button>
            <p className="text-sm text-gray-600">
              Didn't receive the email?{' '}
              <button 
                onClick={() => navigate('/resend-verification')}
                className="text-blue-600 hover:underline"
              >
                Resend Verification Email
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;