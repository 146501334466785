import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { Card } from "../../ui/card";
import { Loader2, Save, ArrowLeft, FileDown } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { getDocument, updateDocument } from "../../../apis/Documents";
import { fetchCompany } from "../../../apis/CompanyDetail";
import { getSingleClient } from "../../../apis/client";
import { getConstructionSites } from "../../../apis/ConstructionSite";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../ui/alert-dialog";
import { exportDocumentToPDF } from "../../../config/helper";

const templateFieldMappings = {
  Company: {
    impresa: "companyName",
    ragione_sociale: "companyName",
    nome_azienda: "companyName",
    soprannome: "nickName",
    sottotitolo1: "subtitleLine1",
    sottotitolo2: "subtitleLine2",
    "sede legale": "address",
    indirizzo: "address",
    nazione: "country",
    stato: "state",
    città_impresa: "city",
    cap: "postalCode",
    provincia_impresa: "province",
    nota_indirizzo: "addressNote",
    "P.IVA": "taxIdCode",
    codice_fiscale: "taxIdCode",
    email: "businessEmail",
    email_secondaria: "secondaryEmail",
    pec: "pec",
    tel: "telephoneNumber",
    cellulare: "mobileNumber",
    fax: "fax",
    tipo_attività: "typeOfBusiness",
    sito_web: "website",
    posizione_inps: "inpsPosition",
    posizione_inail: "inailPosition",
    posizione_edilcassa: "edilcassaPosition",
    banca: "bank",
    iban: "iban"
  },
  Client: {
    tipologia: "typology",
    "sig./sig.ra": "fullName",
    nome_completo: "fullName",
    ragione_sociale_cliente: "companyName",
    p_iva: "vatNumber",
    "C.F.": "taxId",
    codice_fiscale_cliente: "taxId",
    indirizzo_cliente: "address",
    città_cliente: "city",
    cap_cliente: "postalCode",
    provincia_cliente: "province",
    nazione_cliente: "nation",
    email_cliente: "email",
    cellulare_cliente: "mobilePhone",
    telefono_cliente: "telephone",
    pec_cliente: "pec",
    sito_web_cliente: "website",
    note_cliente: "note",
    codice_fatturazione: "billingCode"
  },
  ConstructionSite: {
    nome_cantiere: "name",
    lavori: "workType",
    tipo_lavoro: "workType",
    immobile: "address",
    indirizzo_cantiere: "address",
    importo: "amount",
    descrizione: "description",
    città_cantiere: "city",
    cap_cantiere: "postalCode",
    provincia_cantiere: "province",
    nazione_cantiere: "country",
    data_apertura: "openingDate",
    data_chiusura: "closingDate",
    stato: "status",
    costo_lavori: "workCost"
  }
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["clean"]
  ]
};

export default function DocumentEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(true);
  const [editedContent, setEditedContent] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [error, setError] = useState(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const replaceTemplateFields = (content) => {
    if (!content) return "";
    return content.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
      // First try the mappings
      for (const [model, mappings] of Object.entries(templateFieldMappings)) {
        for (const [templateField, dataField] of Object.entries(mappings)) {
          if (p1.toLowerCase().includes(templateField.toLowerCase())) {
            const value = formData[model]?.[dataField];
            return value || "________";
          }
        }
      }
      // If no mapping found, try direct field access
      const [model, field] = p1.split(".");
      const value = formData[model]?.[field];
      return value || "________";
    });
  };

  useEffect(() => {
    if (id) fetchDocumentAndData();
  }, [id]);

  useEffect(() => {
    if (document && formData && Object.keys(formData).length > 0) {
      // For new documents, populate from template
      if (!document.content || document.status === "draft") {
        const processedContent = replaceTemplateFields(
          document.templateId.content
        );
        setEditedContent(processedContent);
      } else {
        // For existing documents, use saved content
        setEditedContent(document.content);
      }
      setIsEditing(document.status !== "final");
    }
  }, [document, formData]);

  const fetchDocumentAndData = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user?.companyId) throw new Error("User information not found");

      const documentData = await getDocument(id);

      const [companyResponse, sitesResponse] = await Promise.all([
        fetchCompany(user.companyId),
        getConstructionSites(user.companyId)
      ]);

      const site = sitesResponse.data?.sites?.find(
        (s) => s._id === documentData.constructionSiteId
      );

      if (!site) throw new Error("Construction site not found");

      let clientData = {};
      if (site.client) {
        try {
          const clientResponse = await getSingleClient(site.client);
          clientData = clientResponse.data?.client || {};
        } catch (error) {
          console.error("Error fetching client:", error);
        }
      }

      const newFormData = {
        Company: companyResponse.data?.company || {},
        Client: clientData,
        ConstructionSite: {
          ...site,
          amount: site.amount?.toString() || "",
          openingDate: site.openingDate
            ? new Date(site.openingDate).toISOString().split("T")[0]
            : "",
          closingDate: site.closingDate
            ? new Date(site.closingDate).toISOString().split("T")[0]
            : ""
        }
      };

      setFormData(newFormData);
      setDocument(documentData);
    } catch (error) {
      console.error("Error loading document:", error);
      setError(error.message || "Error loading document");
      toast.error(error.message || "Errore nel caricamento del documento");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (asDraft = true) => {
    if (!document) return;
    setIsSaving(true);
    try {
      const payload = {
        content: editedContent,
        status: asDraft ? "draft" : "final",
        formData
      };

      const updatedDoc = await updateDocument(id, payload);
      setDocument((prevDoc) => ({
        ...prevDoc,
        content: updatedDoc.content,
        status: updatedDoc.status
      }));

      toast.success(asDraft ? "Bozza salvata" : "Documento salvato");
      setHasUnsavedChanges(false);

      if (!asDraft) {
        setIsEditing(false);
        navigate("/documenti");
      }
    } catch (error) {
      console.error("Save error:", error);
      toast.error("Errore nel salvataggio del documento");
    } finally {
      setIsSaving(false);
    }
  };

  const handleExportPDF = async () => {
    if (!document) return;
    try {
      setIsExporting(true);
      await exportDocumentToPDF(document, formData);
      toast.success("Documento esportato con successo");
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Errore nell'esportazione del documento");
    } finally {
      setIsExporting(false);
    }
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogOpen(true);
      return;
    }
    navigate("/documenti");
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error || !document) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error || "Document not found"}</p>
        <Button onClick={() => navigate("/documenti")}>
          Return to Documents
        </Button>
      </div>
    );
  }

  return (
    <div className="mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button variant="ghost" size="sm" onClick={handleBack}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-2xl font-bold">{document.name}</h1>
        </div>
        <div className="flex gap-2">
          {isEditing && (
            <>
              <Button
                variant="outline"
                onClick={() => handleSave(true)}
                disabled={isSaving}
              >
                {isSaving ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Save className="h-4 w-4 mr-2" />
                )}
                Salva bozza
              </Button>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button disabled={isSaving}>Salva documento</Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Salva documento finale</AlertDialogTitle>
                    <AlertDialogDescription>
                      Sei sicuro di voler salvare il documento come versione
                      finale? Questa azione non può essere annullata e il
                      documento non sarà più modificabile.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annulla</AlertDialogCancel>
                    <AlertDialogAction onClick={() => handleSave(false)}>
                      Conferma
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
          <Button
            variant="outline"
            onClick={handleExportPDF}
            disabled={isExporting}
          >
            {isExporting ? (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            ) : (
              <FileDown className="h-4 w-4 mr-2" />
            )}
            Esporta PDF
          </Button>
        </div>
      </div>

      <Card className="p-6">
        <ScrollArea className="h-[700px]">
          {isEditing ? (
            <ReactQuill
              value={editedContent}
              onChange={(content) => {
                setEditedContent(content);
                setHasUnsavedChanges(true);
              }}
              modules={modules}
              className="h-[600px]"
            />
          ) : (
            <div
              className="prose max-w-none"
              dangerouslySetInnerHTML={{ __html: editedContent }}
            />
          )}
        </ScrollArea>
      </Card>

      <AlertDialog
        open={unsavedChangesDialogOpen}
        onOpenChange={setUnsavedChangesDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Modifiche non salvate</AlertDialogTitle>
            <AlertDialogDescription>
              Hai delle modifiche non salvate. Vuoi salvare prima di uscire?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annulla</AlertDialogCancel>
            <Button
              variant="outline"
              onClick={() => {
                setHasUnsavedChanges(false);
                setUnsavedChangesDialogOpen(false);
                navigate("/documenti");
              }}
            >
              Esci senza salvare
            </Button>
            <AlertDialogAction
              onClick={async () => {
                await handleSave(true);
                setUnsavedChangesDialogOpen(false);
                navigate("/documenti");
              }}
            >
              Salva ed esci
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
