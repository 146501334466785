import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Edit, Trash, Plus, FileText, Printer } from "lucide-react";
import { fetchComputimetriciaddrec, fetchComputimetricsumm, updateComputimetricsumm, deleteComputimetricsumm, addComputimetricsumm, fetchUms, updateComputimetricaddrec, addComputimetricaddrec, downloadComputimetricCalculationsPDF, fetchGeneralData } from '../../../../apis/ComputimetricAddrecSumm';
import { toast } from 'react-toastify';

import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Textarea } from "../../../ui/textarea";
import { exportComputiMetriciToPDF, exportToPDF } from '../../../../config/helper';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import GeneralDataDialog from './GeneralDataDialog';
import { fetchCompany } from "../../../../apis/CompanyDetail";

const DetailComponent = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const [summData, setSummData] = useState([]);
  const [total, setTotal] = useState(0);
  const [editState, setEditState] = useState({});
  const [ums, setUms] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    token: id,
    voce: '',
    codice: '',
    prezzo_unitario: '',
    um: '',
    descrizione_lavori: '',
  });
  const [formDataEdit, setFormDataEdit] = useState({
    voce: '',
    codice: '',
    prezzo_unitario: '',
    um: '',
    descrizione_lavori: '',
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const formatNumber = (num) => {
    return num && !isNaN(parseFloat(num)) ? parseFloat(num).toFixed(2) : '0.00';
  };

  const calculateRowTotal = (row) => {
    const { numero, lunghezza, larghezza, hpeso } = row;
    return parseFloat(numero || 0) * parseFloat(lunghezza || 0) * parseFloat(larghezza || 0) * parseFloat(hpeso || 0);
  };

  const calculateItemTotal = (detail, itemSummData) => {
    const quantityTotal = itemSummData.reduce((acc, curr) => acc + calculateRowTotal(curr), 0);
    return quantityTotal * parseFloat(detail.prezzo_unitario);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const umData = await fetchUms();
        setUms(umData?.um || []);

        const itemDetailsResponse = await fetchComputimetriciaddrec(id);
        const fetchedItemDetails = itemDetailsResponse.data.computimetriciaddrec || [];
        setItemDetails(fetchedItemDetails);

        const summDataResponse = await fetchComputimetricsumm(id);
        const fetchedSummData = summDataResponse.data.computimetricsumm || [];
        setSummData(fetchedSummData);

        const computedTotal = fetchedItemDetails.reduce((acc, curr) => {
          const itemSummData = fetchedSummData.filter(sum => sum.token === curr.token_sum);
          return acc + calculateItemTotal(curr, itemSummData);
        }, 0);
        setTotal(formatNumber(computedTotal));
      } catch (error) {
        console.error('There was an error fetching the data!', error);
        toast.error("Error fetching data. Please try again.");
      }
    };

    getData();
  }, [id]);

  const handleAddRow = async (detail) => {
    console.log('handleAddRow called with detail:', detail);

    if (!detail?._id) {
      toast.error("Invalid detail configuration");
      return;
    }

    const newRow = {
      token: detail._id, // Use the detail's _id as the token for the new row
      numero: '0',
      description: '',
      lunghezza: '0',
      larghezza: '0',
      hpeso: '0',
      quantita_riga: '0',
    };

    try {
      const response = await addComputimetricsumm(newRow);
      if (response?.data) {
        setSummData(prevData => [...prevData, response.data]);
        toast.success("New row added successfully");
      }
    } catch (error) {
      console.error('Error adding new row:', error);
      toast.error("Error adding new row. Please try again.");
    }
  };


  const handleDeleteRow = async (itemId) => {
    if (!itemId) {
      console.error('Item ID is undefined');
      return;
    }
    try {
      await deleteComputimetricsumm(itemId);
      setSummData(summData.filter((item) => item._id !== itemId));
      toast.success("Row deleted successfully");
    } catch (error) {
      console.error('Error deleting row:', error);
      toast.error("Error deleting row. Please try again.");
    }
  };

  const handleInputChange = (e, itemId, field) => {
    const updatedSummData = summData.map(item =>
      item._id === itemId ? { ...item, [field]: e.target.value } : item
    );
    setSummData(updatedSummData);
  };

  const handleBlur = async (itemId) => {
    const updatedItem = summData.find(item => item._id === itemId);
    if (!updatedItem) return;

    const { numero, lunghezza, larghezza, hpeso } = updatedItem;
    const quantita_riga = calculateRowTotal(updatedItem);

    const finalUpdatedItem = {
      ...updatedItem,
      quantita_riga: formatNumber(quantita_riga),
      numero: formatNumber(numero),
      lunghezza: formatNumber(lunghezza),
      larghezza: formatNumber(larghezza),
      hpeso: formatNumber(hpeso)
    };

    try {
      await updateComputimetricsumm(itemId, finalUpdatedItem);
      setSummData(prevData => prevData.map(item =>
        item._id === itemId ? finalUpdatedItem : item
      ));
      toast.success("Data updated successfully");
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error("Error updating data. Please try again.");
    }
  };

  const renderEditableCell = (summItem, field) => (
    <Input
      type="number"
      step="0.01"
      value={summItem[field] || ''}
      onChange={(e) => handleInputChange(e, summItem._id, field)}
      onBlur={() => handleBlur(summItem._id)}
      className="w-full text-center"
    />
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addComputimetricaddrec(formData);
      if (response?.data) {
        toast.success("Item added successfully");
        setIsAddModalOpen(false);

        // Fetch updated details
        const updatedItemDetails = await fetchComputimetriciaddrec(id);
        const details = updatedItemDetails.data.computimetriciaddrec || [];

        // Log the received data
        console.log('Updated items:', details);

        setItemDetails(details);
        setFormData({
          token: id,
          voce: '',
          codice: '',
          prezzo_unitario: '',
          um: '',
          descrizione_lavori: '',
        });
      } else {
        toast.error("Failed to add item!");
      }
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Error creating item!");
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateComputimetricaddrec(formDataEdit._id, formDataEdit);
      setIsEditModalOpen(false);
      toast.success("Item updated successfully");
      const updatedItemDetails = await fetchComputimetriciaddrec(id);
      setItemDetails(updatedItemDetails.data.computimetriciaddrec || []);
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item!");
    }
  };

  const handleExport = async () => {
    try {
      // Get general data first
      const generalDataResponse = await fetchGeneralData(id);
      const generalData = generalDataResponse.data?.data || {};

      // Fetch and store company info
      const user = JSON.parse(localStorage.getItem("user"));
      if (user?.companyId) {
        const companyResponse = await fetchCompany(user.companyId);
        localStorage.setItem("companyInfo", JSON.stringify(companyResponse.data.company));
      }

      const formattedData = [];

      itemDetails.forEach((detail, index) => {
        // Add main item line
        formattedData.push({
          voce: (index + 1).toString(),
          codice: detail.codice || '',
          descrizione: detail.descrizione_lavori || '',
          nr: '',
          lung: '',
          larg: '',
          hpeso: '',
          quantita: '',
          unitario: '',
          importo: ''
        });

        // Add measurement rows
        const relatedSumm = summData.filter(summItem => summItem.token === detail._id);
        relatedSumm.forEach(summItem => {
          formattedData.push({
            voce: '',
            codice: '',
            descrizione: summItem.description || '',
            nr: formatNumber(summItem.numero),
            lung: formatNumber(summItem.lunghezza),
            larg: formatNumber(summItem.larghezza),
            hpeso: formatNumber(summItem.hpeso),
            quantita: formatNumber(calculateRowTotal(summItem)),
            unitario: '',
            importo: ''
          });
        });

        // Add subtotal row
        const itemQuantityTotal = relatedSumm.reduce((acc, curr) => acc + calculateRowTotal(curr), 0);
        const itemTotal = calculateItemTotal(detail, relatedSumm);
        formattedData.push({
          voce: '',
          codice: '',
          descrizione: `Sommano ${detail.um}`,
          nr: '',
          lung: '',
          larg: '',
          hpeso: '',
          quantita: formatNumber(itemQuantityTotal),
          unitario: formatNumber(detail.prezzo_unitario),
          importo: `€ ${formatNumber(itemTotal)}`
        });
      });

      // Add final total
      const finalTotal = itemDetails.reduce((acc, detail) => {
        const itemSummData = summData.filter(summItem => summItem.token === detail._id);
        return acc + calculateItemTotal(detail, itemSummData);
      }, 0);

      formattedData.push({
        voce: '',
        codice: '',
        descrizione: 'Totale Complessivo',
        nr: '',
        lung: '',
        larg: '',
        hpeso: '',
        quantita: '',
        unitario: '',
        importo: `€ ${formatNumber(finalTotal)}`
      });

      const columns = [
        { header: "Voce", key: "voce", width: 0.4 },
        { header: "Codice", key: "codice", width: 0.6 },
        { header: "Descrizione dei lavori", key: "descrizione", width: 2.0 },
        { header: "Nr.", key: "nr", width: 0.4 },
        { header: "Lung.", key: "lung", width: 0.4 },
        { header: "Larg.", key: "larg", width: 0.4 },
        { header: "H/Peso", key: "hpeso", width: 0.4 },
        { header: "Quantità", key: "quantita", width: 0.6 },
        { header: "Unitario", key: "unitario", width: 0.6 },
        { header: "Importo", key: "importo", width: 0.6 }
      ];

      const stats = [{
        label: "Data",
        value: new Date().toLocaleDateString('it-IT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-')
      }];

      const summaries = [{
        label: "Totale Complessivo",
        value: `€ ${formatNumber(finalTotal)}`
      }];

      // Generate PDF with general data
      const doc = await exportComputiMetriciToPDF(
        formattedData,
        columns,
        stats,
        summaries,
        generalData
      );

      // Save the PDF
      doc.save("Computo_Metrico.pdf");

      toast.success("Esportazione PDF completata con successo");
    } catch (error) {
      console.error('Errore durante l\'esportazione PDF:', error);
      toast.error("Errore durante l'esportazione PDF");
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <Button onClick={() => setIsAddModalOpen(true)}>
          Aggiungi
        </Button>
        <div className="flex space-x-2">
          <Button variant="outline" onClick={handleExport}>
            Stampa
          </Button>
          <GeneralDataDialog id={id} companyId={user?.companyId} />
        </div>
      </div>


      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead rowSpan={2} className="text-center w-[8%]">Voce</TableHead>
              <TableHead rowSpan={2} className="text-center w-[10%]">Codice</TableHead>
              <TableHead rowSpan={2} className="text-center w-[30%]">Descrizione dei lavori</TableHead>
              <TableHead colSpan={5} className="text-center border-r">Misure</TableHead>
              <TableHead colSpan={2} className="text-center">Prezzi</TableHead>
            </TableRow>
            <TableRow>
              <TableHead className="text-center w-[8%]">Nr.</TableHead>
              <TableHead className="text-center w-[8%]">Lung.</TableHead>
              <TableHead className="text-center w-[8%]">Larg.</TableHead>
              <TableHead className="text-center w-[8%]">H/Peso</TableHead>
              <TableHead className="text-center w-[10%] border-r">Quantità</TableHead>
              <TableHead className="text-center w-[10%]">Unitario</TableHead>
              <TableHead className="text-center w-[10%]">Totale</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {itemDetails.map((detail, index) => (
              <React.Fragment key={detail._id}>
                <TableRow>
                  <TableCell className="text-center">
                    <Button variant="ghost" size="sm" onClick={() => {
                      setFormDataEdit(detail);
                      setIsEditModalOpen(true);
                    }}>
                      <Edit className="h-4 w-4" />
                    </Button>
                    {index + 1}
                  </TableCell>
                  <TableCell className="text-center">{detail.codice}</TableCell>
                  <TableCell>{detail.descrizione_lavori}</TableCell>
                  <TableCell colSpan={6}></TableCell>
                </TableRow>
                {summData
                  .filter(summItem => summItem.token === detail._id)
                  .map((summItem) => (
                    <TableRow key={summItem._id}>
                      <TableCell></TableCell>
                      <TableCell className="text-center">
                        <Button variant="ghost" size="sm" onClick={() => handleDeleteRow(summItem._id)}>
                          <Trash className="h-4 w-4" />
                        </Button>
                      </TableCell>
                      <TableCell>{summItem.description}</TableCell>
                      {['numero', 'lunghezza', 'larghezza', 'hpeso'].map(field => (
                        <TableCell key={field} className="text-center">
                          {renderEditableCell(summItem, field)}
                        </TableCell>
                      ))}
                      <TableCell className="text-center">{formatNumber(calculateRowTotal(summItem))}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="text-center">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        console.log('Add row clicked for detail:', detail);
                        handleAddRow(detail);
                      }}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </TableCell>
                  <TableCell colSpan={7}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="text-right font-bold">Sommano {detail.um}</TableCell>
                  <TableCell colSpan={4}></TableCell>
                  <TableCell className="text-center">
                    {formatNumber(summData
                      .filter(summItem => summItem.token === detail._id)
                      .reduce((acc, curr) => acc + calculateRowTotal(curr), 0)
                    )}
                  </TableCell>
                  <TableCell className="text-center">{formatNumber(detail.prezzo_unitario)}</TableCell>
                  <TableCell className="text-center">
                    {formatNumber(calculateItemTotal(detail, summData.filter(summItem => summItem.token === detail._id)))}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="text-right font-bold">Totale Complessivo</TableCell>
              <TableCell colSpan={6}></TableCell>
              <TableCell className="text-center font-bold">
                {` ${formatNumber(
                  itemDetails.reduce((acc, detail) => {
                    const itemSummData = summData.filter(summItem => summItem.token === detail._id);
                    return acc + calculateItemTotal(detail, itemSummData);
                  }, 0)
                )}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiungi nuova voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 gap-4">
              <div className="space-y-2">
                <Label htmlFor="codice">Codice</Label>
                <Input id="codice" name="codice" value={formData.codice} onChange={(e) => setFormData({ ...formData, codice: e.target.value })} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="descrizione_lavori">Descrizione dei lavori</Label>
                <Textarea id="descrizione_lavori" name="descrizione_lavori" value={formData.descrizione_lavori} onChange={(e) => setFormData({ ...formData, descrizione_lavori: e.target.value })} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="um">U.M</Label>
                <Select name="um" value={formData.um} onValueChange={(value) => setFormData({ ...formData, um: value })}>
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona U.M." />
                  </SelectTrigger>
                  <SelectContent>
                    {ums.map(um => (
                      <SelectItem key={um.unit} value={um.unit}>{um.unit}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="prezzo_unitario">Prezzo unitario</Label>
                <Input
                  id="prezzo_unitario"
                  name="prezzo_unitario"
                  type="number"
                  step="0.01"
                  value={formData.prezzo_unitario}
                  onChange={(e) => setFormData({ ...formData, prezzo_unitario: e.target.value })}
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => setIsAddModalOpen(false)}>Annulla</Button>
              <Button type="submit">Salva</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifica voce</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmitEdit} className="space-y-4">
            <input type="hidden" name="_id" value={formDataEdit._id} />
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="codice">Codice</Label>
                <Input
                  id="codice"
                  name="codice"
                  value={formDataEdit.codice}
                  onChange={(e) => setFormDataEdit({ ...formDataEdit, codice: e.target.value })}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="descrizione_lavori">Descrizione dei lavori</Label>
                <Textarea
                  id="descrizione_lavori"
                  name="descrizione_lavori"
                  value={formDataEdit.descrizione_lavori}
                  onChange={(e) => setFormDataEdit({ ...formDataEdit, descrizione_lavori: e.target.value })}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="um">U.M</Label>
                <Select
                  name="um"
                  value={formDataEdit.um}
                  onValueChange={(value) => setFormDataEdit({ ...formDataEdit, um: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona U.M." />
                  </SelectTrigger>
                  <SelectContent>
                    {ums.map(um => (
                      <SelectItem key={um.unit} value={um.unit}>{um.unit}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="prezzo_unitario">Prezzo unitario</Label>
                <Input
                  id="prezzo_unitario"
                  name="prezzo_unitario"
                  type="number"
                  step="0.01"
                  value={formDataEdit.prezzo_unitario}
                  onChange={(e) => setFormDataEdit({ ...formDataEdit, prezzo_unitario: e.target.value })}
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => setIsEditModalOpen(false)}>Annulla</Button>
              <Button type="submit">Salva modifiche</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DetailComponent;