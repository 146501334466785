import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../ui/dialog";
import { Button } from "../../../../ui/button";
import { Label } from "../../../../ui/label";
import { Input } from "../../../../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { AddTraining, updateTraining } from "../../../../../apis/training";

const AddCertificate = ({
  isOpen,
  onClose,
  employeeData,
  selectedId,
  setIsUpdated,
  isUpdated,
  companyId,
  singleTraining,
}) => {
  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: singleTraining,
  });

  useEffect(() => {
    if (singleTraining) {
      reset(singleTraining);
    } else {
      reset({
        attestedName: "",
        expiration: "",
        employee: "",
        file: null,
      });
    }
  }, [reset, singleTraining]);

  const handleDocument = async (data, e) => {
    e.preventDefault();
    try {
      if (!data.employee) {
        toast.error("Please select an employee");
        return;
      }

      const payload = {
        ...data,
        companyId
      };
      let response;

      if (selectedId) {
        response = await updateTraining(selectedId, payload);
      } else {
        response = await AddTraining(payload);
      }

      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.message);
        onClose();
        setIsUpdated(!isUpdated);
      }
      reset();
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while processing the request.");
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
      return file;
    } else {
      toast.error("Please select a valid image or PDF file");
      return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {selectedId ? "Modifica Certificato" : "Aggiungi Certificato"}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(handleDocument)} className="space-y-4">


          <div className="space-y-2">
            <Label htmlFor="employee" className="flex items-center gap-1">
              Dipendente
              <span className="text-red-500">*</span>
            </Label>
            <Controller
              name="employee"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona Dipendente" />
                  </SelectTrigger>
                  <SelectContent>
                    {employeeData?.map((emp) => (
                      <SelectItem
                        key={emp.employeeId}
                        value={emp.employeeId}
                      >
                        {`${emp.name} ${emp.sureName} (${emp.freshman})`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="attestedName">Nome attestato</Label>
            <Input
              id="attestedName"
              {...register("attestedName", { required: true })}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="expiration">Data di scadenza</Label>
            <Input
              type="date"
              id="expiration"
              {...register("expiration", { required: true })}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="file">Documento</Label>
            <Input
              id="file"
              type="file"
              accept="image/*,.pdf"
              className="cursor-pointer"
              {...register("file")}
              onChange={handleFileChange}
            />
            <p className="text-sm text-gray-500">
              Formati supportati: PNG, JPG, SVG, WEBP, GIF, PDF
            </p>
          </div>

          <DialogFooter className="gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={handleClose}
            >
              Annulla
            </Button>
            <Button type="submit">
              {selectedId ? "Aggiorna" : "Aggiungi"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCertificate;