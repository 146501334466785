import React from "react";
import Layout from "../../../components/admin/sharedComponent/Layout";
import UserDocuments from "../../../components/admin/documents/index";

const Agency = () => {
  return (
    <Layout>
      <UserDocuments />
    </Layout>
  );
};

export default Agency;
