import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { MdOutlineDelete } from "react-icons/md";
import { fetchUtenti, deleteUser, updateUser, inviteUser, resendInvitation, updateUserPermissions, fetchUserPermissions } from '../../../apis/Utenti';
import { toast } from 'react-toastify';

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Checkbox } from "../../ui/checkbox";
import { ScrollArea } from "../../ui/scroll-area";
import Pagination from '../sharedComponent/Pgination';
import SearchBar from '../sharedComponent/SearchBar';

// Define available routes and their display names
const AVAILABLE_ROUTES = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
    description: 'Access to main dashboard overview'
  },
  calendar: {
    name: 'Calendar',
    path: '/calendar',
    description: 'Calendar and scheduling features'
  },
  agency: {
    name: 'Agency',
    path: '/agency',
    description: 'Agency management and information'
  },
  employees: {
    name: 'Employees',
    path: '/employees',
    description: 'Employee management'
  },
  clients: {
    name: 'Clients',
    path: '/clients',
    description: 'Client management system'
  },
  suppliers: {
    name: 'Suppliers',
    path: '/suppliers',
    description: 'Supplier management'
  },
  archive: {
    name: 'Archive',
    path: '/archive',
    description: 'Document archival system'
  },
  'computi-metrici': {
    name: 'Computi Metrici',
    path: '/computi-metrici',
    description: 'Metric computation tools'
  },
  flotta: {
    name: 'Flotta',
    path: '/flotta',
    description: 'Fleet management'
  },
  profilio: {
    name: 'Profile',
    path: '/profilio',
    description: 'User profile settings'
  },
  magazzino: {
    name: 'Magazzino',
    path: '/magazzino',
    description: 'Warehouse management'
  },
  movimenti: {
    name: 'Movimenti',
    path: '/movimenti',
    description: 'Movement tracking'
  },
  chat: {
    name: 'Chat',
    path: '/chat',
    description: 'Communication system'
  },
  assistenza: {
    name: 'Assistenza',
    path: '/assistenza',
    description: 'Support system'
  },
  impostazioni: {
    name: 'Settings',
    path: '/impostazioni',
    description: 'System settings'
  },
  ddt: {
    name: 'DDT',
    path: '/ddt',
    description: 'Transport document management'
  },
  preventivi: {
    name: 'Preventivi',
    path: '/preventivi',
    description: 'Quote management'
  },
  cantieri: {
    name: 'Cantieri',
    path: '/cantieri',
    description: 'Construction site management'
  },
  prezzari: {
    name: 'Prezzari',
    path: '/prezzari',
    description: 'Price list management'
  }
};

// Group routes by category
const ROUTE_CATEGORIES = {
  'Core Features': ['dashboard', 'calendar', 'chat'],
  'Management': ['agency', 'employees', 'clients', 'suppliers'],
  'Operations': ['computi-metrici', 'flotta', 'magazzino', 'movimenti'],
  'Documents': ['ddt', 'preventivi', 'archive'],
  'Projects': ['cantieri', 'prezzari'],
  'System': ['profilio', 'assistenza', 'impostazioni']
};

// Invite User Modal Component
const InviteUserModal = ({ isOpen, onClose, onInvite }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onInvite({ email });
    setEmail('');
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite New User</DialogTitle>
          <DialogDescription>
            Enter the email address of the user you want to invite
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">
              Send Invitation
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// Delete User Modal Component
const DeleteUserModal = ({ isOpen, onClose, userId, onConfirm }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete User</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Permissions Modal Component
const PermissionsModal = ({ isOpen, onClose, user, onSave }) => {
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchExistingPermissions = async () => {
      if (user?._id) {
        try {
          setIsLoading(true);
          const response = await fetchUserPermissions(user._id);
          const userPermissions = response.data?.user?.permissions || {};
          setPermissions(userPermissions);
        } catch (error) {
          console.error('Error fetching permissions:', error);
          toast.error('Failed to load user permissions');
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (isOpen && user) {
      fetchExistingPermissions();
    }
  }, [user, isOpen]);

  const handlePermissionChange = (route) => {
    setPermissions(prev => ({
      ...prev,
      [route]: !prev[route]
    }));
  };

  const handleCategoryChange = (category) => {
    const categoryRoutes = ROUTE_CATEGORIES[category];
    const allSelected = categoryRoutes.every(route => permissions[route]);

    const newPermissions = { ...permissions };
    categoryRoutes.forEach(route => {
      newPermissions[route] = !allSelected;
    });

    setPermissions(newPermissions);
  };

  if (isLoading) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-2xl max-h-[80vh]">
          <div className="flex items-center justify-center p-4">
            <div className="text-center">Loading permissions...</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Gestisci permessi per {user?.email}</DialogTitle>
          <DialogDescription>
          Seleziona le sezioni in cui l’utente può accedere
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[60vh] w-full pr-4">
          <div className="space-y-6">
            {Object.entries(ROUTE_CATEGORIES).map(([category, routes]) => (
              <div key={category} className="space-y-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold">{category}</h3>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleCategoryChange(category)}
                  >
                    {routes.every(route => permissions[route])
                      ? 'Deselect All'
                      : 'Seleziona tutto'}
                  </Button>
                </div>
                <div className="grid grid-cols-2 gap-4 ml-4">
                  {routes.map(route => (
                    <div key={route} className="space-y-1">
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id={route}
                          checked={permissions[route] || false}
                          onCheckedChange={() => handlePermissionChange(route)}
                        />
                        <label
                          htmlFor={route}
                          className="text-sm font-medium leading-none"
                        >
                          {AVAILABLE_ROUTES[route].name}
                        </label>
                      </div>
                      <p className="text-xs text-gray-500 ml-6">
                        {AVAILABLE_ROUTES[route].description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Annulla</Button>
          <Button onClick={() => {
            onSave(user._id, permissions);
            onClose();
          }}>Salva Permessi</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Main Utenti Component
function UtentiComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const isAdmin = user?.role === 'admin';
  const userId = user?.id;
  const isInvitedUser = user?.isInvitedUser;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      const response = await fetchUtenti(companyId, currentPage, limit);
      setTotalItems(response.data.totalItems);
      setTotalPage(Math.ceil(response.data.totalItems / limit));
      const filteredUsers = response.data.users.filter(user =>
        user.role !== 'admin' &&
        user._id !== userId
      );
      setData(filteredUsers);
      setFilteredData(filteredUsers);
      setDataIsLoaded(true);
      setCurrentPage(response.data?.currentPage);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      toast.error('Error fetching users');
    }
  };

  const handleInvite = async (userData) => {
    try {
      if (!isAdmin) {
        toast.error('Only admins can invite users');
        return;
      }
      const response = await inviteUser({ email: userData.email });
      setIsInviteUserModalOpen(false);
      getItems();
      toast.success('Invitation sent successfully');
    } catch (error) {
      console.error('There was an error inviting the user!', error);
      toast.error(error.response?.data?.message || 'Error inviting user');
    }
  };

  const handleDelete = async (id) => {
    if (!isAdmin) {
      toast.error('Only admins can delete users');
      return;
    }
    try {
      const response = await deleteUser(id);
      getItems();
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('There was an error deleting the user!', error);
      toast.error('Error deleting user');
    }
  };

  const handleResendInvitation = async (userId) => {
    if (!isAdmin) {
      toast.error('Only admins can resend invitations');
      return;
    }
    try {
      const response = await resendInvitation(userId);
      toast.success('Invitation resent successfully');
      getItems();
    } catch (error) {
      console.error('Error resending invitation:', error);
      toast.error(error.response?.data?.message || 'Error resending invitation');
    }
  };

  const handlePermissionsSave = async (userId, newPermissions) => {
    try {
      // Send the permissions object directly without wrapping it
      await updateUserPermissions(userId, newPermissions);
      getItems();
      toast.success('Permissions updated successfully');
    } catch (error) {
      console.error('Error updating permissions:', error);
      toast.error('Failed to update permissions');
    }
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    getItems();
  }, [currentPage]);

  if (!isAdmin && isInvitedUser) {
    return (
      <Card className="m-4 border-0">
        <CardContent>
          <div className="text-center py-8">
            You don't have permission to access this section.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="m-4 border-0">
      <CardHeader>
        <CardTitle>Gestisci il tuo team aziendale</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between mb-4">
          {isAdmin && (
            <Button onClick={() => setIsInviteUserModalOpen(true)}>
              Invita nuovo utente
            </Button>
          )}
          <div className="w-[20%]">
            <SearchBar onSearch={handleSearch} />
          </div>
        </div>

        <div className="rounded-lg border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Stato</TableHead>
                {isAdmin && <TableHead>Azioni</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>{(currentPage - 1) * limit + index + 1}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    {isAdmin && (
                      <TableCell>
                        <div className="flex space-x-2">
                          {item.status === 'Invited' && (
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleResendInvitation(item._id)}
                              title="Resend Invitation"
                            >
                              <FaEnvelope className="h-4 w-4" />
                            </Button>
                          )}
                          {item.status === 'Active' && (
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                setSelectedUser(item);
                                setIsPermissionsModalOpen(true);
                              }}
                              title="Gestisci permessi"
                            >
                              <FaLock className="h-4 w-4" />
                            </Button>
                          )}
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              setSelectedUser(item);
                              setIsDeleteModalOpen(true);
                            }}
                            title="Delete User"
                          >
                            <MdOutlineDelete className="h-4 w-4 text-red-600" />
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={isAdmin ? 4 : 3} className="text-center">
                    No invited users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {filteredData?.length > 0 && (
          <Pagination
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}

        {isAdmin && (
          <>
            <InviteUserModal
              isOpen={isInviteUserModalOpen}
              onClose={() => setIsInviteUserModalOpen(false)}
              onInvite={handleInvite}
            />

            <DeleteUserModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              userId={selectedUser?._id}
              onConfirm={() => handleDelete(selectedUser?._id)}
            />

            <PermissionsModal
              isOpen={isPermissionsModalOpen}
              onClose={() => setIsPermissionsModalOpen(false)}
              user={selectedUser}
              onSave={(permissions) => handlePermissionsSave(selectedUser?._id, permissions)}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default UtentiComponent;