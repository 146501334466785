import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchCompany } from "../apis/CompanyDetail";

export const DateFormat = (date) => {
  const parts = date?.split("-");
  const formattedExpireDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedExpireDate;
};

export const changeDateFormat = (dateString) => {
  let parts = dateString.split("-");
  let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

export const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const exportComputiMetriciToPDF = async (formattedData, columns, stats, summaries, generalData = {}) => {
  const doc = new jsPDF();

  // Add custom landing page with general data
  await createComputiMetriciLandingPage(doc, generalData);

  // Add data pages - remove the total row from formatted data
  const dataWithoutTotal = formattedData.slice(0, -1); // Remove last row (total)
  doc.addPage();
  await addComputiMetriciDataPages(doc, dataWithoutTotal, columns, stats, summaries);

  return doc;
};

const createComputiMetriciLandingPage = async (doc, generalData = {}) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 16;

  // Set navy blue color
  const navyBlue = [0, 0, 128];
  doc.setDrawColor(...navyBlue);
  doc.setFillColor(...navyBlue);
  doc.setTextColor(...navyBlue);

  // Header Section
  doc.setFontSize(32);
  doc.setFont("Montserrat", "bold");
  const headerTitle = `Comune di ${generalData.comune || ''}`;
  const headerTitleWidth = doc.getTextWidth(headerTitle);
  doc.text(headerTitle, (pageWidth - headerTitleWidth) / 2, 40);

  doc.setFontSize(20);
  const subHeader = `Provincia di ${generalData.provincia || ''}`;
  const subHeaderWidth = doc.getTextWidth(subHeader);
  doc.text(subHeader, (pageWidth - subHeaderWidth) / 2, 55);

  // Divider Line
  doc.setLineWidth(0.5);
  doc.line(margin, 65, pageWidth - margin, 65);

  // Main Title
  doc.setFontSize(32);
  const mainTitle = "COMPUTO METRICO";
  const mainTitleWidth = doc.getTextWidth(mainTitle);
  doc.text(mainTitle, (pageWidth - mainTitleWidth) / 2, 90);

  // OGGETTO Section
  const labelY = 120;
  doc.setFillColor(...navyBlue);
  doc.setTextColor(255, 255, 255);

  doc.rect(margin, labelY, 50, 12, 'F');
  doc.setFontSize(14);
  doc.setFont("Montserrat", "bold");
  doc.text("OGGETTO :", margin + 5, labelY + 8);

  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");

  // Split oggetto text into multiple lines if needed
  const maxWidth = pageWidth - (margin + 60 + margin);
  const oggetto = generalData.oggetto || "";
  const splitOggetto = doc.splitTextToSize(oggetto, maxWidth);
  splitOggetto.forEach((line, index) => {
    doc.text(line, margin + 60, labelY + 8 + (index * 8));
  });

  // COMMITTENTE Section
  const committenteLabelY = labelY + 40;
  doc.setTextColor(255, 255, 255);
  doc.setFillColor(...navyBlue);
  doc.rect(margin, committenteLabelY, 50, 12, 'F');
  doc.setFont("Montserrat", "bold");
  doc.text("COMMITTENTE :", margin + 5, committenteLabelY + 8);

  doc.setTextColor(0, 0, 0);
  doc.setFont("Montserrat", "normal");
  const clientName = generalData.committente?.typology === 'Private'
    ? generalData.committente?.fullName
    : generalData.committente?.companyName;
  doc.text(clientName || "", margin + 60, committenteLabelY + 8);

  // Signature Section
  const signatureY = pageHeight - 80;
  doc.line(margin, signatureY, margin + 48, signatureY);
  doc.line(pageWidth - margin - 48, signatureY, pageWidth - margin, signatureY);

  doc.setFontSize(12);
  doc.text("Data", margin + 20, signatureY + 10);
  doc.text("IL tecnico", pageWidth - margin - 40, signatureY + 10);

  // Custom Footer Section
  const footerHeight = 24;
  const footerY = pageHeight - footerHeight;

  // Get company info
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;

  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  }

  // Navy blue background
  doc.setFillColor(...navyBlue);
  doc.rect(0, footerY, pageWidth, footerHeight, 'F');

  // Set text color to white for footer
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(10);

  // Left side footer text with dynamic company info
  const leftFooterLine1 = companyInfo ? [
    companyInfo.companyName,
    companyInfo.address,
    companyInfo.postalCode,
    companyInfo.city,
    companyInfo.province
  ].filter(Boolean).join(", ") : "";

  const leftFooterLine2 = companyInfo ? [
    companyInfo.telephoneNumber && `Tel: ${companyInfo.telephoneNumber}`,
    companyInfo.businessEmail
  ].filter(Boolean).join(" - ") : "";

  // Add left footer text
  doc.text(leftFooterLine1, margin, footerY + 12);
  doc.text(leftFooterLine2, margin, footerY + 20);

  // Right side footer text
  doc.text("Powered by Restruct © Galdiero Systems Srl", pageWidth - margin - 60, footerY + 20);
};


const addComputiMetriciDataPages = async (doc, formattedData, columns, stats, summaries) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 8;
  const navyBlue = [0, 0, 128];

  // Add the table starting from top of page
  await doc.autoTable({
    startY: margin,
    // Define two level headers
    head: [
      // First level - Group headers
      [
        { content: '', colSpan: 3 },                    // Empty cells for Voce, Codice, Descrizione
        { content: 'Misure', colSpan: 5, styles: { halign: 'center' } },  // Group header for Misure
        { content: 'Prezzi', colSpan: 2, styles: { halign: 'center' } }   // Group header for Prezzi
      ],
      // Second level - Column headers
      columns.map(col => col.header)
    ],
    body: formattedData.map(row => columns.map(col => row[col.key] ?? '')),
    styles: {
      font: "Montserrat",
      lineWidth: 0.1,
      lineColor: [80, 80, 80],
      textColor: [0, 0, 0],
      fillColor: false
    },
    headStyles: {
      fontStyle: 'bold',
      halign: 'left',
      fillColor: false,
      textColor: [0, 0, 0]
    },
    bodyStyles: {
      fillColor: false
    },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = {
        cellWidth: (col.width || 1) * (pageWidth - (2 * margin)) /
          columns.reduce((sum, c) => sum + (c.width || 1), 0),
        cellPadding: 2,
        fontSize: 9,
        overflow: 'linebreak'
      };
      return styles;
    }, {}),
    theme: 'plain',
    margin: { left: margin, right: margin },
    didDrawPage: (data) => {
      // Add footer with navy blue background
      const footerHeight = 24;
      const footerY = pageHeight - footerHeight;

      // Draw navy blue background
      doc.setFillColor(...navyBlue);
      doc.rect(0, footerY, pageWidth, footerHeight, 'F');

      // Set text color to white for footer
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(10);

      // Get company info synchronously from localStorage
      const user = JSON.parse(localStorage.getItem("user"));
      const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

      if (companyInfo) {
        // Left side footer text
        const leftFooterText = [
          companyInfo.companyName,
          companyInfo.address,
          companyInfo.postalCode,
          companyInfo.city,
          companyInfo.province
        ].filter(Boolean).join(", ");

        const contactInfo = [
          companyInfo.telephoneNumber && `Tel: ${companyInfo.telephoneNumber}`,
          companyInfo.businessEmail
        ].filter(Boolean).join(" - ");

        doc.text(leftFooterText, margin, footerY + 12);
        doc.text(contactInfo, margin, footerY + 20);
      }

      // Right side footer text
      doc.text("Powered by Restruct © Galdiero Systems Srl", pageWidth - margin - 60, footerY + 16);
    }
  });

  // Add total after the table
  const finalY = doc.previousAutoTable.finalY + 10;
  doc.setFontSize(10);
  doc.setFont("Montserrat", "bold");
  doc.setTextColor(0, 0, 0);

  const totalText = "Totale Complessivo:";
  const totalValue = `€ ${summaries[0]?.value.split(' ')[1] || '0.00'}`;

  const totalWidth = doc.getTextWidth(totalText);
  const valueWidth = doc.getTextWidth(totalValue);

  doc.text(totalText, pageWidth - margin - valueWidth - totalWidth - 5, finalY);
  doc.text(totalValue, pageWidth - margin - valueWidth, finalY);
};


const addCompanyHeader = async (doc) => {
  // Get company info
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;

  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      console.log('Error fetching company info:', error);
      return 15; // Return default starting Y position if no company info
    }
  }

  let yPos = 15;
  const leftMargin = 14;
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // Add logo if exists
  if (companyInfo?.logo1) {
    try {
      const logoUrl = `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}`;

      // Create a temporary image element to get the original dimensions
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = logoUrl;
      });

      // Set a fixed square size of 30 units
      const squareSize = 30;

      // Calculate dimensions maintaining aspect ratio within the square
      const aspectRatio = img.width / img.height;
      let logoWidth, logoHeight;

      if (aspectRatio > 1) {
        // Image is wider than tall
        logoWidth = squareSize;
        logoHeight = squareSize / aspectRatio;
      } else {
        // Image is taller than wide
        logoHeight = squareSize;
        logoWidth = squareSize * aspectRatio;
      }

      // Left-aligned position
      const xPos = leftMargin;

      doc.addImage(logoUrl, 'JPEG', xPos, yPos, logoWidth, logoHeight);
      yPos += logoHeight + 5;
    } catch (error) {
      console.log('Error adding logo:', error);
    }
  }

  // Company name - left aligned and bold
  if (companyInfo?.companyName) {
    doc.setFontSize(12);
    doc.setFont("Montserrat", "bold");
    doc.text(companyInfo.companyName, leftMargin, yPos);
    yPos += 6;
  }

  // Reset font
  doc.setFontSize(10);
  doc.setFont("Montserrat", "normal");

  // Address line
  let addressLine = '';
  if (companyInfo?.address) addressLine += companyInfo.address;
  if (companyInfo?.postalCode) addressLine += addressLine ? `, ${companyInfo.postalCode}` : companyInfo.postalCode;
  if (companyInfo?.province) addressLine += addressLine ? `, ${companyInfo.province}` : companyInfo.province;

  if (addressLine) {
    doc.text(addressLine, leftMargin, yPos);
    yPos += 5;
  }

  // Contact info line
  let contactLine = '';
  if (companyInfo?.taxIdCode) contactLine += `P.IVA: ${companyInfo.taxIdCode}`;
  if (companyInfo?.telephoneNumber) contactLine += contactLine ? `, Tel. ${companyInfo.telephoneNumber}` : `Tel. ${companyInfo.telephoneNumber}`;

  if (contactLine) {
    doc.text(contactLine, leftMargin, yPos);
    yPos += 5;
  }

  // Email
  if (companyInfo?.businessEmail) {
    doc.text(companyInfo.businessEmail, leftMargin, yPos);
    yPos += 8;
  }

  // Add separator line
  if (companyInfo && Object.keys(companyInfo).some(key => companyInfo[key])) {
    doc.setDrawColor(200, 200, 200);
    doc.line(leftMargin, yPos, pageWidth - leftMargin, yPos);
    yPos += 10;
  }

  return yPos;
};

export const exportToPDF = async (data,
  columns,
  fileName,
  stats,
  companyInfo = null,
  summaries = null,
  existingDoc = null,
  startY = null) => {
  const doc = existingDoc || new jsPDF();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFont("Montserrat");

  // If using existing doc, use the provided startY or get company header
  let yPos = startY;
  if (!existingDoc) {
    yPos = await addCompanyHeader(doc);
  }

  // Document title
  if (!existingDoc) {
    doc.setFontSize(16);
    doc.setFont("Montserrat", "bold");
    doc.text(fileName, 14, yPos);
    yPos += 10;
  }

  // Stats section with two columns if stats is an object with left and right
  if (stats && stats.left && stats.right) {
    doc.setFontSize(10);
    doc.setFont("Montserrat", "normal");

    // Left column
    let leftYPos = yPos;
    stats.left.forEach((stat) => {
      doc.text(`${stat.label}: ${stat.value}`, 14, leftYPos);
      leftYPos += 5;
    });

    // Right column - align to right
    let rightYPos = yPos;

    stats.right.forEach((stat) => {
      const text = `${stat.label}: ${stat.value}`;
      const textWidth = doc.getTextWidth(text);
      doc.text(text, pageWidth - 14 - textWidth, rightYPos);
      rightYPos += 5;
    });

    yPos = Math.max(leftYPos, rightYPos) + 10;
  } else if (stats) {
    // Original stats handling for backward compatibility
    doc.setFontSize(10);
    doc.setFont("Montserrat", "normal");
    stats.forEach((stat) => {
      doc.text(`${stat.label}: ${stat.value}`, 14, yPos);
      yPos += 5;
    });
    yPos += 5;
  }

  // Add table
  const tableResult = doc.autoTable({
    startY: yPos,
    head: [columns.map(col => col.header)],
    body: data.map(row => columns.map(col => row[col.key] ?? '')),
    styles: {
      font: "Montserrat",
      lineWidth: 0.1,
      lineColor: [80, 80, 80],
      textColor: [0, 0, 0],
      fillColor: false
    },
    headStyles: {
      fontStyle: 'bold',
      halign: 'left',
      fillColor: false,
      textColor: [0, 0, 0]
    },
    bodyStyles: {
      fillColor: false
    },
    columnStyles: columns.reduce((styles, col, index) => {
      styles[index] = {
        cellWidth: (col.width || 1) * (pageWidth - 28) /
          columns.reduce((sum, c) => sum + (c.width || 1), 0),
        cellPadding: 2,
        fontSize: 9,
        overflow: 'linebreak'
      };
      return styles;
    }, {}),
    theme: 'plain',
    margin: { left: 14, right: 14 }
  });

  // Add summaries after the table
  if (summaries && summaries.length > 0) {
    let summaryY = tableResult.lastAutoTable.finalY + 10;
    doc.setFontSize(10);

    summaries.forEach((summary, index) => {
      const isLastItem = index === summaries.length - 1;
      if (isLastItem) doc.setFont("Montserrat", "bold");
      else doc.setFont("Montserrat", "normal");

      const text = `${summary.label}: ${summary.value}`;
      const textWidth = doc.getTextWidth(text);
      doc.text(text, pageWidth - 14 - textWidth, summaryY);

      summaryY += 6;
    });
  }

  // Add footer
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  const footerText = "Powered by Restruct © Galdiero Systems Srl";
  doc.setFontSize(8);
  doc.setFont("Montserrat", "normal");
  const footerWidth = doc.getTextWidth(footerText);
  doc.text(footerText, pageWidth - footerWidth - 14, pageHeight - 10);

  doc.save(`${fileName}.pdf`);
};

const flattenTreeStructure = (node, result = [], level = 0, parentName = null) => {
  if (!node) return result;

  result.push({
    level: level,
    name: node.name || node.designation,
    designation: node.designation,
    parent: parentName,
  });

  if (node.children && node.children.length > 0) {
    node.children.forEach(child => {
      flattenTreeStructure(child, result, level + 1, node.name || node.designation);
    });
  }

  return result;
};

export const exportOrgChartToPDF = async (tree) => {
  // Flatten the tree structure
  const flattenedData = flattenTreeStructure(tree);

  // Define columns for the PDF table
  const columns = [
    { header: 'Livello', key: 'level', width: 0.5 },
    { header: 'Nome', key: 'name', width: 1.5 },
    { header: 'Ruolo', key: 'designation', width: 1.5 },
    { header: 'Riporta a', key: 'parent', width: 1.5 }
  ];

  // Generate statistics
  const stats = {
    left: [
      { label: 'Totale dipendenti', value: flattenedData.length },
      { label: 'Livelli organizzativi', value: Math.max(...flattenedData.map(item => item.level)) + 1 }
    ],
    right: [
      { label: 'Data', value: new Date().toLocaleDateString('it-IT') }
    ]
  };

  // Export to PDF
  await exportToPDF(
    flattenedData,
    columns,
    'Organigramma Aziendale',
    stats
  );
};

const addDocumentHeader = async (doc) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let companyInfo = null;

  if (user?.companyId) {
    try {
      const response = await fetchCompany(user.companyId);
      companyInfo = response.data.company;
    } catch (error) {
      console.error('Error fetching company info:', error);
      return 15;
    }
  }

  let yPos = 15;
  const margin = 14;
  const pageWidth = doc.internal.pageSize.width;

  // Add logo if exists
  if (companyInfo?.logo1) {
    try {
      const logoUrl = `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}`;
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = logoUrl;
      });

      const aspectRatio = img.width / img.height;
      const maxHeight = 40;
      const logoHeight = Math.min(maxHeight, img.height);
      const logoWidth = logoHeight * aspectRatio;

      doc.addImage(logoUrl, 'JPEG', margin, yPos, logoWidth, logoHeight);
      yPos += logoHeight + 10;
    } catch (error) {
      console.error('Error adding logo:', error);
    }
  }

  // Company name in bold
  if (companyInfo?.companyName) {
    doc.setFontSize(12);
    doc.setFont("Montserrat", "bold");
    doc.text(companyInfo.companyName, margin, yPos);
    yPos += 12;
  }

  // Reset font for details
  doc.setFontSize(8);
  doc.setFont("Montserrat", "normal");

  // Company details
  if (companyInfo?.address) {
    doc.text(companyInfo.address, margin, yPos);
    yPos += 8;
  }

  const locationLine = [
    companyInfo?.postalCode,
    companyInfo?.city,
    companyInfo?.province
  ].filter(Boolean).join(", ");

  if (locationLine) {
    doc.text(locationLine, margin, yPos);
    yPos += 8;
  }

  if (companyInfo?.taxIdCode) {
    doc.text(`P.IVA: ${companyInfo.taxIdCode}`, margin, yPos);
    yPos += 8;
  }

  if (companyInfo?.businessEmail) {
    doc.text(companyInfo.businessEmail, margin, yPos);
    yPos += 8;
  }

  // Separator line
  if (companyInfo) {
    yPos += 5;
    doc.setDrawColor(0, 0, 128);
    doc.setLineWidth(0.5);
    doc.line(margin, yPos, pageWidth - margin, yPos);
    yPos += 10;
  }

  return yPos;
};

export const exportDocumentToPDF = async (document, formData) => {
  const doc = new jsPDF({
    unit: 'pt'
  });
  
  const pageWidth = doc.internal.pageSize.width;
  const margin = 14;
  
  const yPos = await addDocumentHeader(doc);
  
  doc.setFontSize(8);
  doc.setFont("Montserrat", "bold");
  doc.text(document.name, margin + 60, yPos + 20);
 
  doc.setFontSize(7);
  doc.setFont("Montserrat", "normal");
  
  const stats = {
    left: [
      { label: 'Data creazione', value: new Date(document.createdAt).toLocaleDateString('it-IT') },
      { label: 'Stato', value: document.status === 'draft' ? 'Bozza' : 'Finale' }
    ],
    right: [
      { label: 'Cliente', value: formData.Client?.fullName || formData.Client?.companyName || '' }, 
      { label: 'Cantiere', value: formData.ConstructionSite?.name || '' }
    ]
  };

  let statsYPos = yPos + 30;
  
  // Left side stats
  stats.left.forEach((stat) => {
    doc.text(`${stat.label}: ${stat.value}`, margin + 10, statsYPos);
    statsYPos += 6;
  });
 
  // Right side stats
  statsYPos = yPos + 30;
  stats.right.forEach((stat) => {
    const text = `${stat.label}: ${stat.value}`;
    const textWidth = doc.getTextWidth(text);
    doc.text(text, pageWidth - margin - textWidth - 10, statsYPos);
    statsYPos += 6;
  });
 
  const styledContent = document.content
    .replace(/<h1/g, '<h1 style="font-size: 8px; font-weight: bold; margin: 4px 0; font-family: Montserrat;"')
    .replace(/<h2/g, '<h2 style="font-size: 7px; font-weight: bold; margin: 3px 0; font-family: Montserrat;"')
    .replace(/<h3/g, '<h3 style="font-size: 7px; font-weight: bold; margin: 2px 0; font-family: Montserrat;"')
    .replace(/<p/g, '<p style="font-size: 7px; margin: 2px 0; font-family: Montserrat; line-height: 1.2;"')
    .replace(/<div/g, '<div style="font-size: 7px; font-family: Montserrat;"')
    .replace(/<span/g, '<span style="font-size: 7px; font-family: Montserrat;"');
 
  const processedContent = `
    <div style="font-size: 7px; font-family: Montserrat; line-height: 1.2;">
      ${styledContent}
    </div>
  `;
  
  await doc.html(processedContent, {
    callback: () => doc.save(`${document.name}.pdf`),
    x: margin,
    y: statsYPos + 10,
    width: pageWidth - (2 * margin),
    windowWidth: pageWidth - (2 * margin),
    html2canvas: {
      scale: 1,
      letterRendering: true,
      logging: false
    }
  });
};

// Utility functions for time formatting
export const formatTimeStringToAMPM = (timeString) => {
  if (!timeString) return '';
  let [hours, minutes] = timeString.trim().split(':').map(Number);
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const formatWorkingHours = (workingHours) => {
  if (!workingHours) return '';
  const [hours, minutes] = workingHours.toString().split('.').map(Number);
  const formattedMinutes = minutes || 0;
  return `${hours}h ${formattedMinutes.toString().padStart(2, '0')}m`;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};