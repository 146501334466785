import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../../ui/button';
import { ScrollArea } from '../../ui/scroll-area';
import { Card } from '../../ui/card';
import {
  Loader2,
  Save,
  FileDown,
  ArrowLeft
} from 'lucide-react';
import { toast } from 'react-toastify';
import {
  getDocument,
  updateDocument,
  exportDocumentToPdf
} from '../../../apis/Documents';
import { fetchCompany } from '../../../apis/CompanyDetail';
import { getSingleClient } from '../../../apis/client';
import { getConstructionSites } from '../../../apis/ConstructionSite';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog";

// Field mappings for the contract template
const templateFieldMappings = {
  Company: {
    'impresa': 'companyName',
    'sede legale': 'address',
    'città_impresa': 'city',
    'provincia_impresa': 'province',
    'P.IVA': 'taxIdCode',
    'pec': 'pec',
    'tel': 'telephoneNumber'
  },
  Client: {
    'sig./sig.ra': 'fullName',
    'C.F.': 'taxId',
    'città_cliente': 'city',
    'indirizzo': 'address',
    'provincia_cliente': 'province'
  },
  ConstructionSite: {
    'lavori': 'workType',
    'immobile': 'address',
    'città_cantiere': 'city',
    'provincia_cantiere': 'province',
    'descrizione': 'description'
  }
};

export default function DocumentEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);

  useEffect(() => {
    if (id) {
      fetchDocumentAndData();
    }
  }, [id]);

  const fetchDocumentAndData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user?.companyId) {
        throw new Error('User information not found');
      }

      // First fetch the document
      const documentData = await getDocument(id);
      console.log('Document Data:', documentData);

      // Fetch all required data in parallel
      const [companyResponse, sitesResponse] = await Promise.all([
        fetchCompany(user.companyId),
        getConstructionSites(user.companyId)
      ]);
      
      console.log('Company Data:', companyResponse.data);
      console.log('Sites Data:', sitesResponse.data);

      const site = sitesResponse.data?.sites?.find(
        s => s._id === documentData.constructionSiteId
      );
      
      console.log('Selected Site:', site);

      if (!site) {
        throw new Error('Construction site not found');
      }

      // Get client data
      let clientData = {};
      if (site.client) {
        try {
          const clientResponse = await getSingleClient(site.client);
          clientData = clientResponse.data?.client || {};
          console.log('Client Data:', clientData);
        } catch (error) {
          console.error('Error fetching client:', error);
        }
      }

      const newFormData = {
        Company: companyResponse.data?.company || {},
        Client: clientData,
        ConstructionSite: {
          ...site,
          amount: site.amount?.toString() || '',
          openingDate: site.openingDate ? new Date(site.openingDate).toISOString().split('T')[0] : '',
          closingDate: site.closingDate ? new Date(site.closingDate).toISOString().split('T')[0] : ''
        }
      };

      console.log('Final Form Data:', newFormData);

      setDocument(documentData);
      setFormData(newFormData);

    } catch (error) {
      console.error('Error loading document and data:', error);
      setError(error.message || 'Error loading document');
      toast.error(error.message || 'Errore nel caricamento del documento');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (asDraft = true) => {
    if (!document) return;

    setIsSaving(true);
    try {
      const payload = {
        formData,
        status: asDraft ? 'draft' : 'final',
        name: document.name,
        templateId: document.templateId._id
      };

      await updateDocument(id, payload);
      
      toast.success(asDraft ? 'Bozza salvata' : 'Documento salvato');
      setHasUnsavedChanges(false);
      if (!asDraft) navigate('/documenti');
    } catch (error) {
      toast.error('Errore nel salvataggio del documento');
      console.error('Error saving document:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleExport = async () => {
    if (!document) return;

    try {
      setIsExporting(true);
      const blob = await exportDocumentToPdf(id, formData);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${document.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      toast.error('Errore nell\'esportazione del documento');
      console.error('Error exporting document:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogOpen(true);
      return;
    }
    navigate('/documenti');
  };

  const replaceTemplateFields = (content) => {
    return content.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
      console.log('Replacing field:', p1); // Debug log for field replacement
      // Check if this field has a mapping
      for (const [model, mappings] of Object.entries(templateFieldMappings)) {
        for (const [templateField, dataField] of Object.entries(mappings)) {
          if (p1.toLowerCase().includes(templateField.toLowerCase())) {
            const value = formData[model]?.[dataField];
            console.log(`Mapped ${templateField} to ${dataField}:`, value); // Debug log for mapping
            return value || '________';
          }
        }
      }
      // If no mapping found, try direct field access
      const [model, field] = p1.split('.');
      const value = formData[model]?.[field];
      console.log(`Direct access ${model}.${field}:`, value); // Debug log for direct access
      return value || '________';
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error || !document) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-red-500 mb-4">{error || 'Document not found'}</p>
        <Button onClick={() => navigate('/documenti')}>
          Return to Documents
        </Button>
      </div>
    );
  }

  return (
    <div className="mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={handleBack}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-2xl font-bold">{document.name}</h1>
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => handleSave(true)}
            disabled={isSaving}
          >
            {isSaving ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Save className="h-4 w-4 mr-2" />
            )}
            Salva bozza
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button disabled={isSaving}>Salva documento</Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Salva documento finale</AlertDialogTitle>
                <AlertDialogDescription>
                  Sei sicuro di voler salvare il documento come versione finale?
                  Questa azione non può essere annullata.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annulla</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleSave(false)}>
                  Conferma
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {/* <Button
            variant="outline"
            onClick={handleExport}
            disabled={isExporting}
          >
            {isExporting ? (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            ) : (
              <FileDown className="h-4 w-4 mr-2" />
            )}
            Esporta PDF
          </Button> */}
        </div>
      </div>

      <Card className="p-6">
        <ScrollArea className="h-[700px]">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{
              __html: replaceTemplateFields(document.templateId.content)
            }}
          />
        </ScrollArea>
      </Card>

      <AlertDialog open={unsavedChangesDialogOpen} onOpenChange={setUnsavedChangesDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Modifiche non salvate</AlertDialogTitle>
            <AlertDialogDescription>
              Hai delle modifiche non salvate. Vuoi salvare prima di uscire?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annulla</AlertDialogCancel>
            <Button
              variant="outline"
              onClick={() => {
                setHasUnsavedChanges(false);
                setUnsavedChangesDialogOpen(false);
                navigate('/documenti');
              }}
            >
              Esci senza salvare
            </Button>
            <AlertDialogAction
              onClick={async () => {
                await handleSave(true);
                setUnsavedChangesDialogOpen(false);
                navigate('/documenti');
              }}
            >
              Salva ed esci
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}