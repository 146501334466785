import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { X, Plus, FileDown, Save } from "lucide-react";
import { AddCompanyShares, deleteMemberShares } from "../../../../apis/companyShares";
import { toast } from "react-toastify";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Card, CardHeader, CardTitle, CardContent } from "../../../ui/card";
import { exportToPDF } from "../../../../config/helper";

const Ownership = ({ SharesData, dataUpdated, setDataUpdated, printRef }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const { register, control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      members: SharesData,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "members",
  });

  useEffect(() => {
    reset({ members: SharesData });
  }, [SharesData, reset]);

  const addMember = () => {
    append({
      role: "Socio",
      name: "",
      shares: "",
      labelColor: "#FFFFFF",
    });
  };

  const handleShares = async (data) => {
    const transformData = {
      companyId: companyId,
      members: data.members,
    };

    try {
      const response = await AddCompanyShares(transformData);
      if (response.status === 201) {
        toast.success("CompanyShares added");
        setDataUpdated(!dataUpdated);
      }
    } catch (error) {
      console.error("Error adding company shares:", error);
      toast.error("Failed to add company shares");
    }
  };

  const handleDelete = async (id) => {
    try {
      const data = {
        companyId: companyId,
        memberId: id,
      };
      const response = await deleteMemberShares(data);
      if (response.status === 200) {
        toast.success(response.data.message);
        setDataUpdated(!dataUpdated);
      }
    } catch (error) {
      console.error("Error deleting member shares:", error);
      toast.error("Failed to delete member shares");
    }
  };

  const handleExportPDF = async () => {
    try {
      const currentMembers = watch('members');

      const data = currentMembers.map(member => ({
        ruolo: member.role,
        nome: member.name || '-',
        quote: `${member.shares}%`,
      }));

      const columns = [
        { header: 'Ruolo', key: 'ruolo', width: 1 },
        { header: 'Nome', key: 'nome', width: 2 },
        { header: 'Quote', key: 'quote', width: 1 }
      ];

      const totalShares = currentMembers.reduce((sum, member) => sum + (parseFloat(member.shares) || 0), 0);

      const stats = [
        { label: 'Numero di soci', value: data.length },
        { label: 'Quote totali', value: `${totalShares.toFixed(2)}%` },
        { label: 'Data', value: new Date().toLocaleDateString('it-IT') }
      ];

      await exportToPDF(
        data,
        columns,
        'Composizione Societaria',
        stats
      );
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      toast.error("Errore durante l'esportazione del PDF");
    }
  };

  return (
    <Card className="w-full sm:w-2/3">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex gap-2">
          <Button onClick={addMember} size="sm" className="flex items-center gap-1">
            Aggiungi
          </Button>
          <Button onClick={handleExportPDF} variant="ghost" size="sm" className="flex items-center gap-1">
            Esporta
          </Button>
        </div>
        <Button
          onClick={handleSubmit(handleShares)}
          size="sm"
          className="flex items-center gap-1"
        >
          Salva
        </Button>
      </CardHeader>
      <CardContent>
        <CardTitle className="text-xl font-bold mb-4">Proprietà dell'azienda</CardTitle>
        <form ref={printRef} onSubmit={handleSubmit(handleShares)} className="space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex items-end gap-2">
              <div className="flex-1">
                <Label htmlFor={`name-${index}`}>{field.role}</Label>
                <Input
                  id={`name-${index}`}
                  {...register(`members[${index}].name`)}
                  defaultValue={field.name}
                  placeholder="John Doe"
                />
              </div>
              <div className="w-1/4">
                <Label htmlFor={`shares-${index}`}>Quote</Label>
                <Input
                  id={`shares-${index}`}
                  type="number"
                  min={0}
                  required
                  {...register(`members[${index}].shares`)}
                  defaultValue={field.shares}
                />
              </div>
              <div>
                <Label htmlFor={`color-${index}`}>Colore</Label>
                <Input
                  id={`color-${index}`}
                  type="color"
                  {...register(`members[${index}].labelColor`)}
                  defaultValue={field.labelColor}
                  className="h-9 w-20 p-1"
                />
              </div>
              {index > 0 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDelete(field._id)}
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
        </form>
      </CardContent>
    </Card>
  );
};

export default Ownership;