import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../ui/dialog";
import { Checkbox } from "../../../ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { ScrollArea } from "../../../ui/scroll-area";
import { Edit, Trash2, Plus, Minus } from 'lucide-react';
import { createViolation, getViolations, deleteViolation, updateViolation } from '../../../../apis/Violation';
import { getConstructionSites } from '../../../../apis/ConstructionSite';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateCompany, fetchCompany } from '../../../../apis/CompanyDetail';
import Spinner from '../../../Spinner';

const violations = [
  { id: 1, description: "Omessa elaborazione del documento di valutazione dei rischi", points: -5 },
  { id: 2, description: "Omessa elaborazione del Piano di emergenza ed evacuazione", points: -3 },
  { id: 3, description: "Omessi formazione ed addestramento", points: -2 },
  { id: 4, description: "Omessa costituzione del servizio di prevenzione o nomina del relativo responsabile", points: -3 },
  { id: 5, description: "Omessa elaborazione del piano operativo di sicurezza", points: -3 },
  { id: 6, description: "Omessa fornitura del dispositivo di protezione individuale contro le cadute dall'alto", points: -2 },
  { id: 7, description: "Mancanza di protezioni verso il vuoto", points: -3 },
  { id: 8, description: "Mancata installazione delle armature di sostegno, fatte salve le prescrizioni desumibili dalla relazione tecnica sulla consistenza del terreno", points: -2 },
  { id: 9, description: "Lavori in prossimità di linee elettriche in assenza di disposizioni organizzative e procedurali idonee a proteggere i lavoratori dai conseguenti rischi", points: -2 },
  { id: 10, description: "Presenza di conduttori nudi in tensione in assenza di disposizioni organizzative e procedurali idonee a proteggere i lavoratori dai conseguenti rischi", points: -2 },
  { id: 11, description: "Mancanza di protezione contro i contatti diretti e indiretti (impianto di terra, interruttore magnetotermico, interruttore differenziale)", points: -2 },
  { id: 12, description: "Omessa vigilanza in ordine alla rimozione o modifica dei dispositivi di sicurezza o di segnalazione o di controllo", points: -2 },
  { id: 13, description: "Omessa notifica all'organo di vigilanza prima dell'inizio di lavori che possono comportare il rischio di esposizione all'amianto", points: -1 },
  { id: 14, description: "Omessa valutazione dei rischi derivanti dal possibile rinvenimento di ordigni bellici inesplosi ai sensi dell'articolo 28", points: -3 },
  { id: 15, description: "Omessa valutazione del rischio biologico e da sostanze chimiche", points: -3 },
  { id: 16, description: "Omessa individuazione delle zone controllate o sorvegliate ai sensi del decreto legislativo 31 luglio 2020, n. 101", points: -3 },
  { id: 17, description: "Omessa valutazione del rischio di annegamento", points: -2 },
  { id: 18, description: "Omessa valutazione dei rischi collegati a lavori in pozzi, sterri sotterranei e gallerie", points: -2 },
  { id: 19, description: "Omessa valutazione dei rischi collegati all'impiego di esplosivi", points: -3 },
  { id: 20, description: "Omessa formazione dei lavoratori che operano in ambienti confinati o sospetti di inquinamento ai sensi del regolamento di cui al decreto del Presidente della Repubblica 14 settembre 2011, n. 177", points: -1 },
  { id: 21, description: "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera a), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73", points: -1 },
  { id: 22, description: "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera b), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73", points: -2 },
  { id: 23, description: "Condotta sanzionata ai sensi dell'articolo 3, comma 3, lettera c), del decreto- legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73", points: -3 },
  { id: 24, description: "Condotta sanzionata ai sensi dell'articolo 3, comma 3-quater, del decreto-legge 22 febbraio 2002, n. 12, convertito, con modificazioni, dalla legge 23 aprile 2002, n. 73, in aggiunta alle condotte di cui ai numeri 21, 22 e 23", points: -1 },
  { id: 25, description: "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, dal quale derivi un'inabilità temporanea assoluta che importi l'astensione dal lavoro per più di 60 giorni", points: -5 },
  { id: 26, description: "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, che comporti una parziale inabilità permanente al lavoro", points: -8 },
  { id: 27, description: "Infortunio di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto, che comporti un'assoluta inabilità permanente al lavoro", points: -15 },
  { id: 28, description: "Infortunio mortale di lavoratore dipendente dell'impresa, occorso a seguito di violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto", points: -20 },
  { id: 29, description: "Malattia professionale di lavoratore dipendente dell'impresa, derivante dalla violazione delle norme sulla prevenzione degli infortuni sul lavoro di cui al presente decreto", points: -10 },
];

export default function License() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [licenses, setLicenses] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isPointsDialogOpen, setIsPointsDialogOpen] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingLicense, setEditingLicense] = useState(null);
  const [pointsToAdd, setPointsToAdd] = useState('');
  const [companyInfo, setCompanyInfo] = useState({});
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [newLicense, setNewLicense] = useState({
    controlNumber: '',
    date: '',
    constructionSiteId: '',
    violations: [],
    pointsVariation: 0,
    note: ''
  });
  const companyId = user.companyId;

  useEffect(() => {
    fetchViolations();
    fetchConstructionSites();
  }, []);

  const getCompanyData = async () => {
    try {
      const response = await fetchCompany(companyId);
      setCompanyInfo(response.data.company);
      console.log("Company Info:", response.data.company);
      setDataIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchViolations = async () => {
    try {
      setIsLoading(true);
      const response = await getViolations();
      if (response.data) {
        setLicenses(response.data.violations || []);
        setTotalPoints(response.data.totalPoints || 0);
      }
    } catch (error) {
      console.error('Error fetching violations:', error);
      toast.error('Errore nel recupero delle violazioni');
      setLicenses([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenEdit = (license) => {
    setEditingLicense({
      ...license,
      violations: (license.violations || []).map(v => v.violationId || v.id)
    });
    setIsAddDialogOpen(true);
  };

  const fetchConstructionSites = async () => {
    try {
      const response = await getConstructionSites();
      setConstructionSites(response.data.sites);
    } catch (error) {
      toast.error('Errore nel recupero dei cantieri');
    }
  };

  const calculateMaxReduction = (selectedViolations) => {
    if (!selectedViolations || selectedViolations.length === 0) return 0;
    const maxViolation = Math.max(...selectedViolations.map(id =>
      Math.abs(violations.find(v => v.id === id)?.points || 0)
    ));
    return maxViolation * 2;
  };

  const handleViolationChange = (violationId) => {
    const currentLicense = editingLicense || newLicense;
    const currentViolations = currentLicense.violations || [];

    const updatedViolations = currentViolations.includes(violationId)
      ? currentViolations.filter(id => id !== violationId)
      : [...currentViolations, violationId];

    let pointsVariation = updatedViolations.reduce((total, id) => {
      const violation = violations.find(v => v.id === id);
      return total + (violation ? violation.points : 0);
    }, 0);

    // Apply maximum reduction limit
    const maxReduction = calculateMaxReduction(updatedViolations);
    pointsVariation = Math.max(pointsVariation, -maxReduction);

    if (editingLicense) {
      setEditingLicense({
        ...editingLicense,
        violations: updatedViolations,
        pointsVariation
      });
    } else {
      setNewLicense({
        ...newLicense,
        violations: updatedViolations,
        pointsVariation
      });
    }
  };

  const handleAddLicense = async () => {
    try {
      setIsLoading(true);
      const selectedSite = constructionSites.find(site => site._id === newLicense.constructionSiteId);

      // Calculate maxReduction first
      const selectedViolations = newLicense.violations || [];
      const maxReduction = calculateMaxReduction(selectedViolations);

      // Calculate points variation with the limit
      const rawPointsVariation = selectedViolations.reduce((total, id) => {
        const violation = violations.find(v => v.id === id);
        return total + (violation ? violation.points : 0);
      }, 0);

      const limitedPointsVariation = Math.max(rawPointsVariation, -maxReduction);

      const violationData = {
        ...newLicense,
        constructionSite: selectedSite,
        maxReduction: maxReduction,
        pointsVariation: limitedPointsVariation || 0,
        violations: selectedViolations.map(id => {
          const violation = violations.find(v => v.id === id);
          return {
            violationId: violation.id,
            description: violation.description,
            points: violation.points
          };
        })
      };

      await createViolation(violationData);
      toast.success('Violazione registrata con successo');
      setIsAddDialogOpen(false);
      resetForm();
      fetchViolations();
    } catch (error) {
      console.error('Error adding violation:', error);
      toast.error('Errore nella registrazione della violazione');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditLicense = async () => {
    try {
      setIsLoading(true);
      const selectedSite = constructionSites.find(site => site._id === editingLicense.constructionSiteId);

      const selectedViolations = editingLicense.violations || [];
      const maxReduction = calculateMaxReduction(selectedViolations);

      const rawPointsVariation = selectedViolations.reduce((total, id) => {
        const violation = violations.find(v => v.id === id);
        return total + (violation ? violation.points : 0);
      }, 0);

      const limitedPointsVariation = Math.max(rawPointsVariation, -maxReduction);

      const violationData = {
        ...editingLicense,
        constructionSite: selectedSite,
        maxReduction: maxReduction,
        pointsVariation: limitedPointsVariation || 0,
        violations: selectedViolations.map(id => {
          const violation = violations.find(v => v.id === id);
          return {
            violationId: violation.id,
            description: violation.description,
            points: violation.points
          };
        })
      };

      await updateViolation(editingLicense._id, violationData);
      toast.success('Violazione aggiornata con successo');
      setIsAddDialogOpen(false);
      setEditingLicense(null);
      fetchViolations();
    } catch (error) {
      console.error('Error updating violation:', error);
      toast.error('Errore nell\'aggiornamento della violazione');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLicense = async (id) => {
    try {
      setIsLoading(true);
      await deleteViolation(id);
      toast.success('Violazione eliminata con successo');
      fetchViolations();
    } catch (error) {
      toast.error('Errore nell\'eliminazione della violazione');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPoints = async () => {
    try {
      const points = parseInt(pointsToAdd);
      if (isNaN(points)) {
        toast.error('Inserire un numero valido');
        return;
      }

      if (!companyId) {
        toast.error('ID azienda non trovato');
        return;
      }

      await updateCompany(companyId, { points: points });

      setTotalPoints(prev => prev + points);
      setPointsToAdd('');
      setIsPointsDialogOpen(false);
      toast.success('Punti aggiunti con successo');

      await fetchViolations();
    } catch (error) {
      console.error('Error adding points:', error);
      toast.error('Errore nell\'aggiunta dei punti');
    }
  };

  const resetForm = () => {
    setNewLicense({
      controlNumber: '',
      date: '',
      constructionSiteId: '',
      violations: [],
      pointsVariation: 0
    });
    setEditingLicense(null);
  };

  const handleModalClose = () => {
    setIsAddDialogOpen(false);
    resetForm();
  };

  return (
    <div className="mx-auto">
      <div className="md:flex justify-between items-start mb-6">
        <Card className="md:w-[300px] max-sm:mb-6">
          <CardHeader>
            <CardTitle>Punti</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{totalPoints}</p>
          </CardContent>
        </Card>
        <div className="space-x-2">
          <Button onClick={() => setIsPointsDialogOpen(true)}>
            Aggiunti punti
          </Button>
          <Button onClick={() => setIsAddDialogOpen(true)}>
            Aggiunti Violazione
          </Button>
          <Button variant="outline" onClick={() => {
            toast.info('Funzionalità di esportazione in sviluppo');
          }}>
            Esporta
          </Button>
        </div>
      </div>

      {/* Points Dialog */}
      <Dialog open={isPointsDialogOpen} onOpenChange={setIsPointsDialogOpen}>
        <DialogContent className="sm:max-w-[400px]">
          <DialogHeader>
            <DialogTitle>Aggiungi Punti</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="points">Punti da aggiungere</Label>
              <Input
                id="points"
                type="number"
                value={pointsToAdd}
                onChange={(e) => setPointsToAdd(e.target.value)}
              />
            </div>
            <div className="flex justify-end space-x-2">
              <Button variant="outline" onClick={() => setIsPointsDialogOpen(false)}>
                Annulla
              </Button>
              <Button onClick={handleAddPoints}>
                Aggiungi
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Violations Dialog */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              {editingLicense ? 'Modifica Violazione' : 'Aggiungi Violazione'}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="controlNumber">Controllo n.</Label>
              <Input
                id="controlNumber"
                value={editingLicense?.controlNumber || newLicense.controlNumber}
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({ ...editingLicense, controlNumber: e.target.value });
                  } else {
                    setNewLicense({ ...newLicense, controlNumber: e.target.value });
                  }
                }}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="date">Data</Label>
              <Input
                id="date"
                type="date"
                value={editingLicense?.date?.split('T')[0] || newLicense.date}
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({ ...editingLicense, date: e.target.value });
                  } else {
                    setNewLicense({ ...newLicense, date: e.target.value });
                  }
                }}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="constructionSite">Cantiere</Label>
              <Select
                value={editingLicense?.constructionSiteId || newLicense.constructionSiteId}
                onValueChange={(value) => {
                  if (editingLicense) {
                    setEditingLicense({ ...editingLicense, constructionSiteId: value });
                  } else {
                    setNewLicense({ ...newLicense, constructionSiteId: value });
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona un cantiere" />
                </SelectTrigger>
                <SelectContent>
                  {constructionSites.map((site) => (
                    <SelectItem key={site._id} value={site._id}>
                      {site.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label>Violazioni</Label>
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                {violations.map((violation) => (
                  <div key={violation.id} className="flex items-center space-x-2 mb-2">
                    <Checkbox
                      id={`violation-${violation.id}`}
                      checked={((editingLicense?.violations || []).includes(violation.id) ||
                        (newLicense.violations || []).includes(violation.id))}
                      onCheckedChange={() => handleViolationChange(violation.id)}
                    />
                    <label
                      htmlFor={`violation-${violation.id}`}
                      className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {violation.description} ({violation.points} punti)
                    </label>
                  </div>
                ))}
              </ScrollArea>
            </div>
            <div className="space-y-2">
              <Label>Variazione punti</Label>
              <div className="font-bold">
                {editingLicense?.pointsVariation || newLicense.pointsVariation}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="note">Note</Label>
              <Input
                id="note"
                type="text"
                placeholder="Aggiungi una nota"
                value={editingLicense?.note || newLicense.note || ''}
                onChange={(e) => {
                  if (editingLicense) {
                    setEditingLicense({ ...editingLicense, note: e.target.value });
                  } else {
                    setNewLicense({ ...newLicense, note: e.target.value });
                  }
                }}
              />
            </div>
            <div className="pt-4 flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={handleModalClose}
                disabled={isLoading}
              >
                Annulla
              </Button>
              <Button
                onClick={editingLicense ? handleEditLicense : handleAddLicense}
                disabled={isLoading}
              >
                {isLoading ? (
                  'Caricamento...'
                ) : editingLicense ? (
                  'Salva Modifiche'
                ) : (
                  'Aggiungi Violazione'
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {isLoading && !licenses.length ? (
        <Spinner />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Controllo n.</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Cantiere</TableHead>
              <TableHead>Violazioni</TableHead>
              <TableHead>Variazione punti</TableHead>
              <TableHead>Note</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {licenses.map((license) => (
              <TableRow key={license._id}>
                <TableCell>{license.controlNumber}</TableCell>
                <TableCell>{new Date(license.date).toLocaleDateString('it-IT')}</TableCell>
                <TableCell>
                  {license.constructionSiteId?.name ||
                    constructionSites.find(site => site._id === license.constructionSiteId)?.name ||
                    license.constructionSite?.name ||
                    'N/A'}
                </TableCell>
                <TableCell>{license.violations?.length || 0}</TableCell>
                <TableCell className={
                  (license.pointsVariation || 0) < 0 ? 'text-red-500' : 'text-green-500'
                }>
                  {license.pointsVariation || 0}
                </TableCell>
                <TableCell>{license.note || '-'}</TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleOpenEdit(license)}
                      disabled={isLoading}
                    >
                      <Edit className="h-4 w-4" />
                      <span className="sr-only">Modifica</span>
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteLicense(license._id)}
                      disabled={isLoading}
                    >
                      <Trash2 className="h-4 w-4" />
                      <span className="sr-only">Elimina</span>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {!licenses.length && !isLoading && (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-4">
                  Nessuna violazione registrata
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}