import React, { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../ui/dialog";
import { Label } from "../../ui/label";
import ColorPicker from "../../ui/color-picker";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  getCompanyEmployeePlannings,
  updateCompanyEmployeePlannings,
  deleteCompanyEmployeePlannings,
  getCompanyPlans,
  createCompanyPlan,
  updateCompanyPlan,
  deleteCompanyPlan,
} from "../../../apis/Planning";
import SearchBar from "../sharedComponent/SearchBar";
import { Checkbox } from "../../ui/checkbox";
import HelpSheet from "../../../components/admin/sharedComponent/HelpSheet";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";

const TABS = [
  { name: "Preferenze", route: "/impostazioni" },
  { name: "Turni di lavoro", route: "/settings" },
  { name: "Notifiche", route: "/notifications" },
  { name: "Codice Invito", route: "/invitation-code" }
];

const Turnidilavoro = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [planningData, setPlanningData] = useState([]);
  const [companyPlans, setCompanyPlans] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPlanningModalOpen, setPlanningModalOpen] = useState(false);
  const [isCreatePlanModalOpen, setCreatePlanModalOpen] = useState(false);
  const [isEditPlanModalOpen, setEditPlanModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("/settings");

  useEffect(() => {
    const currentTab = TABS.find(tab => location.pathname.startsWith(tab.route));
    if (currentTab) {
      setActiveTab(currentTab.route);
    }
  }, [location]);

  useEffect(() => {
    getEmployeePlannings(format(selectedMonth, "yyyy-MM"));
    fetchCompanyPlans();
  }, [searchQuery, selectedMonth]);

  const handleTabChange = (value) => {
    navigate(value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value));
    getEmployeePlannings(e.target.value);
  };

  const incrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() + 1));
      getEmployeePlannings(format(newDate, "yyyy-MM"));
      return newDate;
    });
  };

  const decrementMonth = () => {
    setSelectedMonth((prev) => {
      const newDate = new Date(prev.setMonth(prev.getMonth() - 1));
      getEmployeePlannings(format(newDate, "yyyy-MM"));
      return newDate;
    });
  };

  const fetchCompanyPlans = async () => {
    try {
      const response = await getCompanyPlans();
      if (response.status === 200) {
        setCompanyPlans(response.data.plans);
      }
    } catch (error) {
      console.error("Unable to fetch company plans", error);
      toast.error("Unable to fetch company plans");
    }
  };

  const getEmployeePlannings = async (monthQuery) => {
    try {
      const response = await getCompanyEmployeePlannings(monthQuery, searchQuery);
      if (response.status === 200) {
        console.log("Pianificazioni recuperate", response.data);
        setPlanningData(response.data?.employees || []);
      }
    } catch (error) {
      console.error("Impossibile recuperare i dati di pianificazione", error);
      toast.error("Impossibile recuperare i dati di pianificazione");
    }
  };

  const handlePlanningSubmit = async (planId) => {
    try {
      const response = await updateCompanyEmployeePlannings({
        employeeId: selectedEmployee._id,
        date: selectedDate,
        planId: planId,
      });
      if (response) {
        toast.success("Pianificazione aggiornata con successo");
        getEmployeePlannings(format(selectedMonth, "yyyy-MM"));
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile aggiornare la pianificazione");
    }
    setPlanningModalOpen(false);
  };

  const handlePlanningDelete = async (planningId) => {
    try {
      const response = await deleteCompanyEmployeePlannings(planningId);
      if (response.status === 200) {
        toast.success("Pianificazione eliminata con successo");
        getEmployeePlannings(format(selectedMonth, "yyyy-MM"));
        setPlanningModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile eliminare la pianificazione");
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
  };

  const handleCreatePlan = async (planData) => {
    try {
      const response = await createCompanyPlan(planData);
      if (response.status === 201) {
        toast.success("Piano creato con successo");
        fetchCompanyPlans();
        setCreatePlanModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Impossibile creare il piano");
    }
  };

  const handleUpdatePlan = async (planData) => {
    try {
      const response = await updateCompanyPlan(selectedPlan._id, planData);
      if (response.status === 200) {
        toast.success("Piano aggiornato con successo");
        fetchCompanyPlans();
        setEditPlanModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Impossibile aggiornare il piano");
    }
  };

  const handleDeletePlan = async () => {
    if (!planToDelete) return;
    try {
      const response = await deleteCompanyPlan(planToDelete._id);
      if (response.status === 200) {
        toast.success("Piano eliminato con successo");
        fetchCompanyPlans();
        setDeleteConfirmOpen(false);
        setPlanToDelete(null);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Impossibile eliminare il piano");
    }
  };

  return (
    <div className="mx-auto space-y-4 p-6">
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="bg-[#06052C] rounded-none w-full flex justify-start h-auto">
          {TABS.map((tab) => (
            <TabsTrigger
              key={tab.name}
              value={tab.route}
              className="py-2 px-4 text-white data-[state=active]:bg-white data-[state=active]:text-blue-950"
            >
              {tab.name}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>

      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between my-2">
        <div className="flex items-center gap-2 mb-4 md:mb-0">
          <Button onClick={() => setCreatePlanModalOpen(true)}>Crea Nuovo turno</Button>

          {/*<Button variant="outline" size="icon" onClick={decrementMonth}>
            <ChevronLeft className="text-lg" />
          </Button>
          <Input
            type="month"
            value={format(selectedMonth, "yyyy-MM")}
            onChange={handleMonthChange}
            className="w-full"
          />
          <Button variant="outline" size="icon" onClick={incrementMonth}>
            <ChevronRight className="text-lg" />
          </Button>*/}

        </div>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/planning" />
        </div>
      </div>


      <div className="space-y-4 border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nome</TableHead>
              <TableHead>Acronimo</TableHead>
              <TableHead>Colore</TableHead>
              <TableHead>Luogo di Lavoro</TableHead>
              <TableHead>Turno Continuo</TableHead>
              <TableHead>Ora Inizio</TableHead>
              <TableHead>Ora Fine</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {companyPlans.map((plan) => (
              <TableRow key={plan._id}>
                <TableCell>{plan.name}</TableCell>
                <TableCell>{plan.acronym}</TableCell>
                <TableCell>
                  <div
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: plan.color }}
                  ></div>
                </TableCell>
                <TableCell>{plan.workSite}</TableCell>
                <TableCell>{plan.isContinuous ? "Sì" : "No"}</TableCell>
                <TableCell>{plan.startTime}</TableCell>
                <TableCell>{plan.endTime}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setSelectedPlan(plan);
                      setEditPlanModalOpen(true);
                    }}
                    className="mr-2"
                  >
                    Modifica
                  </Button>
                  <Button
                    onClick={() => {
                      setPlanToDelete(plan);
                      setDeleteConfirmOpen(true);
                    }}
                    variant="destructive"
                  >
                    Elimina
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isPlanningModalOpen} onOpenChange={setPlanningModalOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Dettagli Pianificazione</DialogTitle>
          </DialogHeader>
          <PlanningModalContent
            employee={selectedEmployee}
            date={selectedDate}
            onSubmit={handlePlanningSubmit}
            onDelete={handlePlanningDelete}
            companyPlans={companyPlans}
            initialData={selectedEmployee?.plannings?.find((plan) => plan.date === selectedDate)}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isCreatePlanModalOpen} onOpenChange={setCreatePlanModalOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Crea Nuovo Piano</DialogTitle>
          </DialogHeader>
          <CreatePlanModalContent onSubmit={handleCreatePlan} />
        </DialogContent>
      </Dialog>

      <Dialog open={isEditPlanModalOpen} onOpenChange={setEditPlanModalOpen}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>Modifica Piano</DialogTitle>
          </DialogHeader>
          <CreatePlanModalContent onSubmit={handleUpdatePlan} initialData={selectedPlan} />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
          </DialogHeader>
          <p>Sei sicuro di voler eliminare il piano "{planToDelete?.name}"?</p>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeleteConfirmOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeletePlan}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PlanningModalContent = ({
  employee,
  date,
  onSubmit,
  onDelete,
  companyPlans,
  initialData,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(initialData?.planId || "");

  const handleSubmit = () => {
    if (selectedPlan) {
      onSubmit(selectedPlan);
    }
  };

  const handleDelete = () => {
    if (initialData?._id) {
      onDelete(initialData._id);
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <Label>Seleziona Piano</Label>
        <select
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Seleziona un piano</option>
          {companyPlans.map((plan) => (
            <option key={plan._id} value={plan._id}>
              {plan.name}
            </option>
          ))}
        </select>
      </div>

      <DialogFooter>
        <Button onClick={handleSubmit} className="px-6">Salva</Button>
        {initialData && (
          <Button variant="destructive" onClick={handleDelete} className="px-6">
            Elimina
          </Button>
        )}
      </DialogFooter>
    </div>
  );
};

const CreatePlanModalContent = ({ onSubmit, initialData = null }) => {
  const [planData, setPlanData] = useState(
    initialData || {
      name: "",
      acronym: "",
      color: "#ffffff",
      workSite: "",
      isContinuous: false,
      startTime: "",
      endTime: "",
      breakStartTime: "",
      breakEndTime: "",
    }
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPlanData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = () => {
    onSubmit(planData);
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="name">Nome del Piano</Label>
          <Input
            id="name"
            name="name"
            value={planData.name}
            onChange={handleChange}
            placeholder="Es. Turno Mattina"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="acronym">Acronimo</Label>
          <Input
            id="acronym"
            name="acronym"
            value={planData.acronym}
            onChange={handleChange}
            placeholder="Es. TM"
            maxLength={4}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="color">Colore</Label> <br />
          <ColorPicker
            value={planData.color}
            onChange={(color) => setPlanData(prev => ({ ...prev, color }))}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="workSite">Luogo di Lavoro</Label>
          <Input
            id="workSite"
            name="workSite"
            value={planData.workSite}
            onChange={handleChange}
            placeholder="Es. Ufficio Principale"
          />
        </div>
      </div>

      <div className="flex items-center space-x-2 py-2">
        <Checkbox
          id="isContinuous"
          name="isContinuous"
          checked={planData.isContinuous}
          onCheckedChange={(checked) => setPlanData(prev => ({ ...prev, isContinuous: checked }))}
        />
        <Label htmlFor="isContinuous">Turno Continuo</Label>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="startTime">Ora di Inizio</Label>
          <Input
            id="startTime"
            name="startTime"
            type="time"
            value={planData.startTime}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="endTime">Ora di Fine</Label>
          <Input
            id="endTime"
            name="endTime"
            type="time"
            value={planData.endTime}
            onChange={handleChange}
          />
        </div>
      </div>

      {!planData.isContinuous && (
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="breakStartTime">Inizio Pausa</Label>
            <Input
              id="breakStartTime"
              name="breakStartTime"
              type="time"
              value={planData.breakStartTime}
              onChange={handleChange}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="breakEndTime">Fine Pausa</Label>
            <Input
              id="breakEndTime"
              name="breakEndTime"
              type="time"
              value={planData.breakEndTime}
              onChange={handleChange}
            />
          </div>
        </div>
      )}

      <DialogFooter>
        <Button onClick={handleSubmit} className="px-6">
          {initialData ? 'Aggiorna Piano' : 'Crea Piano'}
        </Button>
      </DialogFooter>
    </div>
  );
};

export default Turnidilavoro;