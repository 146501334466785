import React, { useState, useEffect } from 'react';
import {
  getPrimaNotes,
  createPrimaNota,
  updatePrimaNota,
  deletePrimaNota,
  updateStatus
} from '../../../apis/PrimaNota';
import {
  fetchClients
} from '../../../apis/client';
import {
  fetchSuppliers
} from '../../../apis/supplier';
import { toast } from 'react-toastify';
import { Plus, Trash2, Filter, AlertTriangle, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { format } from 'date-fns';
import { exportToExcel, exportToPDF } from '../../../config/helper';
import Pagination from '../sharedComponent/Pgination';


// Categories defined with proper hierarchy
const CATEGORIES = {
  'Entrate': {
    label: 'Entrate',
    items: [
      'Vendite',
      'Corrispettivi',
      'Rimborsi',
      'Proventi straordinari',
      'Incassi Clienti'
    ]
  },
  'Uscite': {
    label: 'Uscite',
    items: [
      'Pagamento fornitori',
      'Stipendi e salari',
      'Rimborso spese',
      'Manutenzioni',
      'Costi generali',
      'Spese amministrative'
    ]
  },
  'Movimenti interni': {
    label: 'Movimenti interni',
    items: [
      'Anticipi',
      'Prelievi cassa',
      'Depositi su conto corrente',
      'Trasferimenti tra conti'
    ]
  },
  'Fiscali e tributari': {
    label: 'Fiscali e tributari',
    items: [
      'IVA',
      'Imposte',
      'Contributi previdenziali'
    ]
  },
  'Altro': {
    label: 'Altro',
    items: ['Altro']
  }
};

const TYPE_TO_CATEGORY_MAP = {
  'Entrata': ['Entrate'],
  'Uscita': ['Uscite', 'Fiscali e tributari', 'Altro'],
  'all': Object.keys(CATEGORIES),
  '': Object.keys(CATEGORIES),
  undefined: Object.keys(CATEGORIES),
};

const PrimaNota = () => {
  const [notes, setNotes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [totals, setTotals] = useState({ entrate: 0, uscite: 0 });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [limit, setLimit] = useState(10);
  const [allNotes, setAllNotes] = useState([]); // Store all fetched notes

  // Client and supplier state
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [customerSupplierSearchTerm, setCustomerSupplierSearchTerm] = useState('');
  const [customerSupplierOptions, setCustomerSupplierOptions] = useState([]);

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    category: '',
    status: '',
    type: 'all',
  });

  const [newNote, setNewNote] = useState({
    date: new Date().toISOString().split('T')[0],
    customerSupplier: '',
    category: '',
    description: '',
    amount: '',
    type: '',
  });

  useEffect(() => {
    fetchNotes();
  }, [currentPage, limit, searchTerm]);

  useEffect(() => {
    applyFrontendFilters();
  }, [filters, allNotes]);

  useEffect(() => {
    fetchCustomersAndSuppliers();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user?.companyId) {
      fetchCustomersAndSuppliers(user.companyId);
    } else {
      toast.error("Impossibile recuperare l'ID dell'azienda");
    }
  }, []);

  const fetchCustomersAndSuppliers = async (companyId) => {
    try {
      const [clientsResponse, suppliersResponse] = await Promise.all([
        fetchClients(companyId, 1, 1000, "", ""), // Get all clients with large limit
        fetchSuppliers(companyId) // Get all suppliers
      ]);

      if (clientsResponse?.data?.clients) {
        setClients(clientsResponse.data.clients);
      }

      if (suppliersResponse?.data?.suppliers) {
        setSuppliers(suppliersResponse.data.suppliers);
      }

      // Combine for select options
      const options = [
        ...((clientsResponse?.data?.clients || []).map(client => ({
          id: client._id,
          label: client.fullName || client.companyName,
          type: 'client'
        }))),
        ...((suppliersResponse?.data?.suppliers || []).map(supplier => ({
          id: supplier._id,
          label: supplier.businessName,
          type: 'supplier'
        })))
      ];

      setCustomerSupplierOptions(options);
    } catch (error) {
      console.error('Error fetching customers/suppliers:', error);
    }
  };


  const fetchNotes = async () => {
    try {
      const params = {
        page: currentPage,
        limit: limit,
        search: searchTerm
      };
      const data = await getPrimaNotes(params);
      const fetchedNotes = data.notes || [];
      setAllNotes(fetchedNotes);
      setNotes(fetchedNotes); // This will now be filtered
      setTotals(data.totals || { entrate: 0, uscite: 0 });
      setTotalPages(Math.ceil((data.total || 0) / limit));
    } catch (error) {
      toast.error('Errore durante il caricamento delle note');
      setAllNotes([]);
      setNotes([]);
      setTotals({ entrate: 0, uscite: 0 });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createPrimaNota(newNote);
      setIsModalOpen(false);
      fetchNotes();
      toast.success('Nota creata con successo');
      setNewNote({
        date: new Date().toISOString().split('T')[0],
        customerSupplier: '',
        category: '',
        description: '',
        amount: '',
        type: '',
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteClick = (note) => {
    setNoteToDelete(note);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deletePrimaNota(noteToDelete._id);
      fetchNotes();
      toast.success('Nota eliminata con successo');
      setDeleteDialogOpen(false);
      setNoteToDelete(null);
    } catch (error) {
      toast.error(error.message || 'Errore durante l\'eliminazione della nota');
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const resetFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      category: '',
      status: 'all',
      type: 'all'
    });
    setCurrentPage(1);
    setIsFilterDialogOpen(false);
    setNotes(allNotes);
  };

  const applyFrontendFilters = () => {
    let filteredResults = [...allNotes];

    if (filters.startDate) {
      filteredResults = filteredResults.filter(note =>
        new Date(note.date) >= new Date(filters.startDate)
      );
    }

    if (filters.endDate) {
      filteredResults = filteredResults.filter(note =>
        new Date(note.date) <= new Date(filters.endDate)
      );
    }

    if (filters.type && filters.type !== 'all') {
      filteredResults = filteredResults.filter(note =>
        note.type === filters.type
      );
    }

    if (filters.category) {
      filteredResults = filteredResults.filter(note =>
        note.category === filters.category
      );
    }

    if (filters.status && filters.status !== 'all') {
      filteredResults = filteredResults.filter(note =>
        note.status === filters.status
      );
    }

    setNotes(filteredResults);
    setTotalPages(Math.ceil(filteredResults.length / limit));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const CategorySelect = ({ value, onChange, type, placeholder }) => (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {TYPE_TO_CATEGORY_MAP[type || ''].map(groupKey => (
          <SelectGroup key={groupKey}>
            <SelectLabel>{CATEGORIES[groupKey].label}</SelectLabel>
            {CATEGORIES[groupKey].items.map(category => (
              <SelectItem key={category} value={category}>
                {category}
              </SelectItem>
            ))}
          </SelectGroup>
        ))}
      </SelectContent>
    </Select>
  );

  const getCustomerSupplierLabel = (id) => {
    const client = clients.find(c => c._id === id);
    if (client) return client.fullName || client.companyName;

    const supplier = suppliers.find(s => s._id === id);
    if (supplier) return supplier.businessName;

    return '';
  };

  const handleExport = (value) => {
    try {
      const formattedData = notes.map((note, index) => {
        return {
          id: (currentPage - 1) * 10 + index + 1,
          data: format(new Date(note.date), 'dd/MM/yyyy'),
          cliente_fornitore: getCustomerSupplierLabel(note.customerSupplier),
          categoria: note.category,
          descrizione: note.description,
          importo: `€ ${Math.abs(note.amount).toFixed(2)}`,
          tipo: note.type,
          stato: note.status
        };
      });

      const columns = [
        { header: "ID", key: "id", width: 0.5 },
        { header: "Data", key: "data", width: 1 },
        { header: "Cliente/Fornitore", key: "cliente_fornitore", width: 1.5 },
        { header: "Categoria", key: "categoria", width: 1 },
        { header: "Descrizione", key: "descrizione", width: 2 },
        { header: "Importo", key: "importo", width: 1 },
        { header: "Tipo", key: "tipo", width: 1 },
        { header: "Stato", key: "stato", width: 1 }
      ];

      const currentDate = new Date().toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');

      const stats = {
        left: [
          { label: "Totale Note", value: notes.length.toString() },
          { label: "Data", value: currentDate }
        ],
        right: [
          { label: "Totale Entrate", value: `€ ${totals.entrate.toFixed(2)}` },
          { label: "Totale Uscite", value: `€ ${totals.uscite.toFixed(2)}` }
        ]
      };

      // Calculate summary statistics
      const summaries = [
        { label: "Totale Entrate", value: `€ ${totals.entrate.toFixed(2)}` },
        { label: "Totale Uscite", value: `€ ${totals.uscite.toFixed(2)}` },
        { label: "Saldo", value: `€ ${(totals.entrate - totals.uscite).toFixed(2)}` }
      ];

      if (value === "excel") {
        exportToExcel(formattedData, "Prima Nota");
        toast.success("Esportazione Excel completata con successo");
      } else if (value === "pdf") {
        exportToPDF(
          formattedData,
          columns,
          "Prima Nota",
          stats,
          undefined,
          summaries
        );
        toast.success("Esportazione PDF completata con successo");
      }
    } catch (error) {
      console.error('Errore durante l\'esportazione:', error);
      toast.error(`Errore durante l'esportazione ${value.toUpperCase()}`);
    }
  };

  const applyFilters = () => {
    setCurrentPage(1);
    setIsFilterDialogOpen(false);
    applyFrontendFilters();
  };

  return (
    <div className="p-6">

      {/* Actions Row */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => setIsModalOpen(true)}
            className="bg-primary hover:bg-primary/90"
          >
            Aggiungi nuova nota
          </Button>
          <Select onValueChange={handleExport}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Esporta" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="excel">Esporta in Excel</SelectItem>
              <SelectItem value="pdf">Esporta in PDF</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <Input
              className="pl-10"
              placeholder="Cerca..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Button
            variant="outline"
            onClick={() => setIsFilterDialogOpen(true)}
          >
            <Filter className="h-4 w-4 mr-2" />
            Filtri
          </Button>
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Data</TableHead>
              <TableHead>Cliente/Fornitore</TableHead>
              <TableHead>Categoria</TableHead>
              <TableHead>Descrizione</TableHead>
              <TableHead>Importo</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead className="text-right">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {notes.map((note) => (
              <TableRow key={note._id}>
                <TableCell>{format(new Date(note.date), 'dd/MM/yyyy')}</TableCell>
                <TableCell>{getCustomerSupplierLabel(note.customerSupplier)}</TableCell>
                <TableCell>{note.category}</TableCell>
                <TableCell>{note.description}</TableCell>
                <TableCell className={note.type === 'Entrata' ? 'text-green-600' : 'text-red-600'}>
                  € {Math.abs(note.amount).toFixed(2)}
                </TableCell>
                <TableCell>
                  <span className={`px-2 py-1 rounded-full text-xs ${note.status === 'Contabilizzato'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                    }`}>
                    {note.status}
                  </span>
                </TableCell>
                <TableCell className="text-right">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteClick(note)}
                  >
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between px-2 py-4">
        <div className="text-sm text-gray-500">
          Showing {notes.length} entries
        </div>
        {notes.length > 0 && (
          <Pagination
            totalPages={totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>

      {/* New Note Modal */}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiungi Nuova Nota</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium">Data</label>
                <Input
                  type="date"
                  value={newNote.date}
                  onChange={(e) => setNewNote({ ...newNote, date: e.target.value })}
                  required
                />
              </div>
              <div>
                <label className="text-sm font-medium">Cliente/Fornitore</label>
                <Select
                  value={newNote.customerSupplier}
                  onValueChange={(value) => setNewNote({ ...newNote, customerSupplier: value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona cliente/fornitore" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Clienti</SelectLabel>
                      {clients.map(client => (
                        <SelectItem
                          key={client._id}
                          value={client._id}  // Missing value prop
                        >
                          {client.fullName || client.companyName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectGroup>
                      <SelectLabel>Fornitori</SelectLabel>
                      {suppliers.map(supplier => (
                        <SelectItem
                          key={supplier._id}
                          value={supplier._id}
                        >
                          {supplier.businessName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div>
              <label className="text-sm font-medium">Tipo</label>
              <Select
                value={newNote.type}
                onValueChange={(value) => setNewNote({ ...newNote, type: value, category: '' })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Entrata">Entrata</SelectItem>
                  <SelectItem value="Uscita">Uscita</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <label className="text-sm font-medium">Categoria</label>
              <CategorySelect
                value={newNote.category}
                onChange={(value) => setNewNote({ ...newNote, category: value })}
                type={newNote.type}
                placeholder="Seleziona categoria"
              />
            </div>

            <div>
              <label className="text-sm font-medium">Descrizione</label>
              <Input
                value={newNote.description}
                onChange={(e) => setNewNote({ ...newNote, description: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="text-sm font-medium">Importo</label>
              <Input
                type="number"
                step="0.01"
                value={newNote.amount}
                onChange={(e) => setNewNote({ ...newNote, amount: e.target.value })}
                required
              />
            </div>

            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => setIsModalOpen(false)}>
                Annulla
              </Button>
              <Button type="submit">Salva</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Filter Dialog */}
      <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Filtri</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium">Data inizio</label>
                <Input
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => handleFilterChange('startDate', e.target.value)}
                />
              </div>
              <div>
                <label className="text-sm font-medium">Data fine</label>
                <Input
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => handleFilterChange('endDate', e.target.value)}
                />
              </div>
            </div>

            <div>
              <label className="text-sm font-medium">Tipo</label>
              <select
                className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
                value={filters.type}
                onChange={(e) => handleFilterChange('type', e.target.value)}
              >
                <option value="all">Tutti</option>
                <option value="Entrata">Entrata</option>
                <option value="Uscita">Uscita</option>
              </select>
            </div>

            <div>
              <label className="text-sm font-medium">Categoria</label>
              <select
                className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
                value={filters.category}
                onChange={(e) => handleFilterChange('category', e.target.value)}
              >
                <option value="">Tutte le categorie</option>
                {TYPE_TO_CATEGORY_MAP[filters.type || 'all'].map(groupKey =>
                  CATEGORIES[groupKey].items.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div>
              <label className="text-sm font-medium">Stato</label>
              <select
                className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
              >
                <option value="">Tutti</option>
                <option value="Contabilizzato">Contabilizzato</option>
                <option value="Non contabilizzato">Non contabilizzato</option>
              </select>
            </div>
          </div>
          <DialogFooter className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={resetFilters}
            >
              Reset
            </Button>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsFilterDialogOpen(false)}
              >
                Annulla
              </Button>
              <Button
                type="button"
                onClick={applyFilters}
              >
                Applica
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center gap-2">
              <AlertTriangle className="text-yellow-500" />
              Conferma eliminazione
            </AlertDialogTitle>
            <AlertDialogDescription>
              Sei sicuro di voler eliminare questa nota?<br />
              Questa azione non può essere annullata.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setDeleteDialogOpen(false);
              setNoteToDelete(null);
            }}>
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-600"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default PrimaNota;