import React, { useEffect, useState } from "react";
import { Edit, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { it } from "date-fns/locale";

import {
  GetCompanyLeaves,
  GetSingleLeaves,
  deleteLeave,
} from "../../../../../apis/leave";

import { Button } from "../../../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import AddAbsence from "./AddAbsence";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";

const Absence = ({ employeeData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;
  const [leaveData, setLeaveData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [singleLeaveData, setSingleLeaveData] = useState({});
  const [dataUpdate, setDataUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const editLeave = async (id) => {
    try {
      setSelectedId(id);
      const response = await GetSingleLeaves(id);
      setSingleLeaveData(response.data.singleLeave);
      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Errore nel recupero dei dati dell'assenza", error);
      toast.error("Impossibile recuperare i dati dell'assenza");
    }
  };

  const handleDeleteModal = (id) => {
    setDeletedId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteEmployee = async () => {
    try {
      const response = await deleteLeave(deletedId);
      if (response.status === 200) {
        toast.success(response.data?.message);
        setDataUpdate(!dataUpdate);
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Errore nell'eliminazione dell'assenza", error);
      toast.error("Impossibile eliminare l'assenza");
    }
  };

  const fetchCompanyLeaves = async () => {
    try {
      const response = await GetCompanyLeaves(companyId, searchQuery);
      if (response.status === 200) {
        setLeaveData(response.data.leaves);
      }
    } catch (error) {
      console.error("Errore nel recupero delle assenze", error);
      toast.error("Impossibile recuperare le assenze");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    fetchCompanyLeaves();
  }, [searchQuery, dataUpdate]);

  return (
    <div className="mx-auto space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-center my-2 space-y-4 md:space-y-0">
        <Button onClick={() => setIsModalOpen(true)}>
          Aggiungi Assenza
        </Button>
        <div className="md:w-1/6 flex justify-center items-center gap-x-2">
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Matricola</TableHead>
              <TableHead className="text-center">Nome</TableHead>
              <TableHead className="text-center">Cognome</TableHead>
              <TableHead className="text-center">Tipologia</TableHead>
              <TableHead className="text-center">Data Inizio Assenza</TableHead>
              <TableHead className="text-center">Data Fine Assenza</TableHead>
              <TableHead className="text-center">Ore di Assenza</TableHead>
              <TableHead className="text-center">Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {leaveData?.length > 0 ? (
              leaveData?.map((data, index) => (
                <TableRow key={index}>
                  <TableCell className="text-center">{data.employeeId?.freshman}</TableCell>
                  <TableCell className="text-center">
                    {data.employeeId?.firstName}
                  </TableCell>
                  <TableCell className="text-center">
                    {data.employeeId?.sureName}
                  </TableCell>
                  <TableCell className="text-center">{data.absenceType}</TableCell>
                  <TableCell className="text-center">
                    {format(new Date(data.startDate), "dd/MM/yyyy", { locale: it })}
                  </TableCell>
                  <TableCell className="text-center">
                    {format(new Date(data.endDate), "dd/MM/yyyy", { locale: it })}
                  </TableCell>
                  <TableCell className="text-center">
                    {data.absenceHours}H
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-center space-x-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => editLeave(data._id)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleDeleteModal(data._id)}
                      >
                        <Trash2 className='h-4 w-4 text-red-600' />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-8">
                  Nessun dato disponibile
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <AddAbsence
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedId(null);
        }}
        setIsModalOpen={setIsModalOpen}
        employeeData={employeeData}
        selectedId={selectedId}
        singleLeaveData={singleLeaveData}
        dataUpdate={dataUpdate}
        setDataUpdate={setDataUpdate}
      />

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Elimina Assenza</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questa assenza? Questa azione è irreversibile.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button variant="destructive" onClick={handleDeleteEmployee}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Absence;