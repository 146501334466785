import React from 'react';
import Layout from '../../../components/admin/sharedComponent/Layout';
import PrimaNotaComponent from '../../../components/admin/primaNota';

const PrimaNotaPage = () => {
  return (
    <Layout>
      <PrimaNotaComponent />
    </Layout>
  );
};

export default PrimaNotaPage;