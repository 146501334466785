import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { resendVerification } from '../../apis/Auth';
import { toast } from 'react-toastify';
import logo from "../../assest/logo1.png";

const VerificationPending = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);
  const { email, username } = location.state || {};

  useEffect(() => {
    // Se non c'è un'email nello stato, reindirizza alla registrazione
    if (!email) {
      navigate('/register');
    }
  }, [email, navigate]);

  const handleResendVerification = async () => {
    try {
      setIsResending(true);
      await resendVerification(email);
      toast.success('Email di verifica reinviata con successo!');
    } catch (error) {
      toast.error(error.message || 'Impossibile reinviare l\'email di verifica');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-950 to-blue-800">
      {/* Intestazione con Logo */}
      <div className="w-full p-4 flex justify-center md:justify-start">
        <Link to='/'>
          <img src={logo} className="w-[180px]" alt="Logo" />
        </Link>
      </div>

      {/* Contenuto Principale */}
      <div className="flex-1 flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Controlla la tua Email
            </h2>
            
            <div className="mb-8">
              <div className="text-gray-600 mb-4">
                Abbiamo inviato un'email di verifica a
              </div>
              <div className="text-blue-600 font-semibold break-all">
                {email}
              </div>
            </div>

            <p className="text-gray-600 mb-8">
              Per favore, controlla la tua email e clicca sul link di verifica per verificare il tuo account.
              Il link scadrà tra 24 ore.
            </p>

            <div className="space-y-4">
              <button
                onClick={handleResendVerification}
                disabled={isResending}
                className="w-full bg-blue-600 text-white rounded-full py-3 px-4 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isResending ? 'Invio...' : 'Reinvia Email di Verifica'}
              </button>

              <button
                onClick={() => navigate('/login')}
                className="w-full border-2 border-blue-600 text-blue-600 rounded-full py-3 px-4 hover:bg-blue-50"
              >
                Torna al Login
              </button>
            </div>

            <div className="mt-8 text-sm text-gray-500">
              <p>Non hai ricevuto l'email? Controlla la tua cartella spam o prova a reinviare l'email di verifica.</p>
              <p className="mt-2">
                Hai bisogno di aiuto?{' '}
                <a href="mailto:support@restruct.com" className="text-blue-600 hover:underline">
                  Contatta il Supporto  
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;