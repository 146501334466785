import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signupSchema } from "../../config/validations";
import { useForm } from "react-hook-form";
import Content from "./Content";
import loginImage from "../../assest/singup.jpg";
import { Signup } from "../../apis/Auth";
import { validateReferralCode } from "../../apis/Referral";
import { toast } from 'react-toastify';
import logo from "../../assest/logo1.png";
import { Button } from "../../components/ui/button";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isValidatingRef, setIsValidatingRef] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const contentData = {
    title: "Costruire con passione, creare con precisione",
    description:
      "Costruisci con passione e precisione utilizzando la nostra app avanzata, progettata per potenziare l'efficienza delle imprese edili e garantire risultati eccezionali.",
    image: loginImage,
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/dashboard');
    }

    const params = new URLSearchParams(location.search);
    const refCode = params.get('ref');

    if (refCode) {
      setReferralCode(refCode);
      validateRef(refCode);
    }
  }, [navigate, location]);

  const validateRef = async (code) => {
    if (!code) return;

    try {
      setIsValidatingRef(true);
      const response = await validateReferralCode(code);

      if (response.success) {
        toast.success("Codice di invito valido!");
      }
    } catch (error) {
      if (!location.search.includes('ref=')) {
        setReferralCode("");
      }
    } finally {
      setIsValidatingRef(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const registrationData = {
        ...data,
        referralCode: referralCode || undefined
      };

      const response = await Signup(registrationData);
      if (response.status === 201) {
        navigate('/login', {
          state: {
            email: data.email,
            username: data.username
          }
        });
        toast.success("Registration successful! Please check your email to verify your account.");
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error(error.response?.data?.message || "An error occurred during registration");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="md:hidden w-full bg-gradient-to-r from-blue-950 to-blue-800 p-4 flex justify-center">
        <Link to='/'>
          <img src={logo} className="w-[180px]" alt="Logo" />
        </Link>
      </div>
      <div className="hidden md:block md:w-[45%]">
        <Content contentData={contentData} />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center p-4 md:p-8 bg-gradient-to-r from-blue-950 to-blue-800">
        <h1 className="mb-6 md:mb-10 text-white font-nunito text-2xl md:text-3xl font-bold text-center">
          Benvenuto su <span className="text-lime-400">Restruct</span>
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[350px] md:max-w-[400px] space-y-4"
        >
          <div className="space-y-1">
            <input
              {...register("companyName")}
              type="text"
              autoComplete="off"
              placeholder="Company Name"
              className={`h-12 p-4 ring-2 rounded-full text-normal text-black w-full ${errors.companyName ? "ring-red-500" : "ring-blue-700"}`}
            />
            <p className="text-red-500 text-sm">{errors.companyName?.message}</p>
          </div>

          <div className="space-y-1">
            <input
              {...register("username")}
              type="text"
              autoComplete="off"
              placeholder="Username"
              className={`h-12 p-4 ring-2 rounded-full text-normal text-black w-full ${errors.username ? "ring-red-500" : "ring-blue-700"}`}
            />
            <p className="text-red-500 text-sm">{errors.username?.message}</p>
          </div>

          <div className="space-y-1">
            <input
              {...register("email")}
              type="text"
              autoComplete="off"
              placeholder="Email"
              className={`h-12 p-4 ring-2 rounded-full text-normal text-black w-full ${errors.email ? "ring-red-500" : "ring-blue-700"}`}
            />
            <p className="text-red-500 text-sm">{errors.email?.message}</p>
          </div>

          <div className="space-y-1">
            <input
              {...register("mobileNumber")}
              type="tel"
              autoComplete="off"
              placeholder="Mobile Number"
              className={`h-12 p-4 ring-2 rounded-full text-normal text-black w-full ${errors.mobileNumber ? "ring-red-500" : "ring-blue-700"}`}
            />
            <p className="text-red-500 text-sm">{errors.mobileNumber?.message}</p>
          </div>

          <div className="space-y-1">
            <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              onBlur={() => validateRef(referralCode)}
              placeholder="Codice di Invito (Opzionale)"
              className="h-12 p-4 ring-2 rounded-full text-normal text-black w-full ring-blue-700"
              disabled={isValidatingRef}
            />
          </div>

          <div className="relative w-full">
            <input
              {...register("password")}
              type={passwordShown ? "text" : "password"}
              className={`h-12 p-4 pl-4 pr-10 rounded-full text-normal text-black w-full ring-2 ${errors.password ? "ring-red-500" : "ring-blue-700"}`}
              autoComplete="off"
              placeholder="Password"
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute top-0 bottom-0 right-4 flex items-center my-2 text-lg cursor-pointer"
            >
              {passwordShown ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
          <p className="text-red-500 text-sm">{errors.password?.message}</p>

          <div className="relative w-full">
            <input
              {...register("confirm_password")}
              type={passwordShown ? "text" : "password"}
              className={`h-12 p-4 pl-4 pr-10 rounded-full text-normal text-black w-full ring-2 ${errors.confirm_password ? "ring-red-500" : "ring-blue-700"}`}
              autoComplete="off"
              placeholder="Confirm Password"
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute top-0 bottom-0 right-4 flex items-center my-2 text-lg cursor-pointer"
            >
              {passwordShown ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
          <p className="text-red-500 text-sm">{errors.confirm_password?.message}</p>

          <button
            type="submit"
            disabled={isLoading}
            className="h-12 p-2 w-full rounded-full text-normal bg-green-500 hover:opacity-70 text-white disabled:opacity-50"
          >
            {isLoading ? "Registering..." : "Registrati"}
          </button>

          <p className="text-center text-white">
            Hai già un account?{" "}
            <a
              onClick={() => navigate("/login")}
              className="hover:opacity-75 font-semibold cursor-pointer"
            >
              Accedi
            </a>
          </p>
          <Link to="/" className="absolute top-1 left-4">
            <Button variant="link" className="flex items-center gap-2 text-white border-white hover:bg-white/10">
              Torna alla Home
            </Button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Register;