import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Check, X, HelpCircle, Loader2, CreditCard } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../components/ui/accordion';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Badge } from "../components/ui/badge";
import { fetchPlans, initiateFreeTrial } from '../apis/SubscriptionApi';
import { getUserInfo } from '../apis/UserApi';

const features = [
  { name: "Spazio di archiviazione in cloud", tiers: ["1 gb", "3 gb", "10 gb", "Unlimited"] },
  { name: "Utenti", tiers: [1, 5, 50, 100] },
  { name: "Chat", tiers: [false, true, true, true] },
  { name: "Calendario & Agenda", tiers: [true, true, true, true] },
  { name: "Movimenti", tiers: ["100/mese", "300/mese", "Unlimited", "Unlimited"] },
  { name: "Gestione clienti", tiers: [50, 200, 500, "Unlimited"] },
  { name: "Gestione fornitori", tiers: [25, 100, 300, "Unlimited"] },
  { name: "Gestione flotta", tiers: [false, "10 Veicoli", "50 Veicoli", "Unlimited"] },
  { name: "Magazzino", tiers: ["1 magazzino\n100 prodotti", "3 magazzini\n500 prodotti", "5 magazzini\n1000 prodotti", "Unlimited"] },
  { name: "DDT", tiers: ["20/mese", "50/mese", "200/mese", "Unlimited"] },
  { name: "Computi metrici", tiers: [false, false, true, true] },
  { name: "Cantieri", tiers: [3, 10, 70, "Unlimited"] },
  { name: "Cantieri -> Contabilità", tiers: [true, true, true, true] },
  { name: "Cantieri -> Documenti", tiers: [false, true, true, true] },
  { name: "Cantieri -> Rapportini", tiers: [false, true, true, true] },
  { name: "Cantieri -> Giornale dei lavori", tiers: [false, false, true, true] },
  { name: "Cantieri -> Media", tiers: [false, "Foto", "Foto", "Foto & Video"] },
  { name: "Supporto", tiers: ["Standard", "Standard", "Dedicato", "Dedicato 24/7"] },
  { name: "Accesso esclusivo a nuove funzionalità", tiers: [false, false, false, true] },
];

const faqs = [
  {
    question: "Quanto dura la prova gratuita?",
    answer: "La prova gratuita dura 7 giorni. Durante questo periodo, avrai accesso completo a tutte le funzionalità del piano che hai scelto."
  },
  {
    question: "Posso cambiare piano durante la prova gratuita?",
    answer: "Sì, puoi cambiare piano in qualsiasi momento durante la prova gratuita. Il nuovo piano sarà attivo immediatamente."
  },
  {
    question: "Cosa succede dopo la fine della prova gratuita?",
    answer: "Alla fine della prova gratuita di 7 giorni, ti chiederemo di inserire i dettagli di pagamento per continuare con il piano scelto. Se non desideri continuare, il tuo account tornerà automaticamente al piano gratuito."
  },
  {
    question: "Come posso cancellare il mio abbonamento?",
    answer: "Puoi cancellare il tuo abbonamento in qualsiasi momento dalle impostazioni del tuo account. La cancellazione sarà effettiva alla fine del periodo di fatturazione corrente."
  },
  {
    question: "Offrite sconti per pagamenti annuali?",
    answer: "Sì, offriamo uno sconto del 20% per i pagamenti annuali su tutti i nostri piani."
  }
];

const PricingTier = ({ plan, onSubscribe, isPopular, loadingPlanId }) => {
  const isLoading = loadingPlanId === plan.id;

  return (
    <Card className={`flex flex-col ${isPopular ? 'border-2 border-blue-500 relative' : ''}`}>
      {isPopular && (
        <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 text-sm rounded-bl">
          Più popolare
        </div>
      )}
      <CardHeader>
        <CardTitle>{plan.name}</CardTitle>
        <CardDescription className='font-bold text-xl'>
          €{(plan.prices.month?.unit_amount / 100).toFixed(2)}/mese
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="list-disc pl-5">
          {plan.features && Object.entries(plan.features).map(([feature, value]) => (
            <li key={feature}>{`${feature}: ${value}`}</li>
          ))}
        </ul>
      </CardContent>
      <CardFooter>
        <Button 
          onClick={() => onSubscribe(plan.id)} 
          className="w-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Per favore, attendi...
            </>
          ) : (
            'Inizia prova gratuita'
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

const RegisterSubscription = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingPlanId, setLoadingPlanId] = useState(null);

  useEffect(() => {
    const loadUserAndPlans = async () => {
      setIsLoading(true);
      try {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser && currentUser.subscription && ['active', 'trialing', 'canceled'].includes(currentUser.subscription.status)) {
          navigate('/dashboard');
          return;
        }
  
        const [userInfoResponse, plansResponse] = await Promise.all([
          getUserInfo(username),
          fetchPlans()
        ]);
        setUser(userInfoResponse.data);
        
        const planOrder = ['basic', 'standard', 'professional', 'enterprise'];
        
        const sortedPlans = plansResponse.sort((a, b) => {
          const indexA = planOrder.findIndex(p => a.name.toLowerCase().includes(p));
          const indexB = planOrder.findIndex(p => b.name.toLowerCase().includes(p));
          return indexA - indexB;
        });
        
        setPlans(sortedPlans);
      } catch (err) {
        console.error('Error fetching user info or plans:', err);
        setError('Errore nel caricamento dei dati. Riprova più tardi.');
      } finally {
        setIsLoading(false);
      }
    };
  
    loadUserAndPlans();
  }, [username, navigate]);
  
  const handleFreeTrial = async (planId) => {
    setSubscriptionError(null);
    setLoadingPlanId(planId);
    try {
      const response = await initiateFreeTrial(planId, username);
      if (response.success) {
        setSelectedPlan(plans.find(plan => plan.id === planId));
        setShowModal(true);
      } else {
        throw new Error('Errore nell\'attivazione della prova gratuita');
      }
    } catch (error) {
      console.error('Error handling free trial:', error);
      setSubscriptionError(error.message || 'Errore nell\'elaborazione della richiesta. Riprova.');
    } finally {
      setLoadingPlanId(null);
    }
  };

  const handleModalConfirm = () => {
    localStorage.clear();
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="m-4">
        <AlertTitle>Errore</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!user || !plans || plans.length === 0) {
    return (
      <Alert variant="warning" className="m-4">
        <AlertTitle>Dati non disponibili</AlertTitle>
        <AlertDescription>
          Non siamo riusciti a caricare i dati necessari. Per favore, ricarica la pagina o contatta il supporto se il problema persiste.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-center mb-4">
        <Badge variant="primary" className="bg-blue-200 text-lg py-2 px-4">
          <CreditCard className="mr-2 h-5 w-5" />
          Nessuna carta di credito richiesta
        </Badge>
      </div>
      <h1 className="text-4xl text-center font-bold mb-4">Benvenuto su Restruct, {user.username}</h1>
      {subscriptionError && (
        <Alert variant="destructive" className="mb-4">
          <AlertTitle>Errore di Sottoscrizione</AlertTitle>
          <AlertDescription>{subscriptionError}</AlertDescription>
        </Alert>
      )}
      <p className="text-xl mb-8">
        Siamo entusiasti di averti a bordo! Da oggi puoi gestire e organizzare la tua impresa edile con maggiore efficienza, ovunque tu sia. Inizia subito con una prova gratuita e scopri come Restruct può trasformare la tua impresa.
      </p>

      <h2 className="text-3xl font-bold mb-6 text-center">Inizia la prova gratuita di 7 giorni</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        {plans.map((plan) => (
          <PricingTier
            key={plan.id}
            plan={plan}
            onSubscribe={handleFreeTrial}
            isPopular={plan.name.toLowerCase() === "piano restruct professional"}
            loadingPlanId={loadingPlanId}
          />
        ))}
      </div>

      <h2 className="text-3xl font-bold mb-4 text-center">Confronta i piani</h2>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left py-2 px-4 text-xl">Confronta i piani</th>
              <th className="py-2 px-4 text-xl">Basic</th>
              <th className="py-2 px-4 text-xl">Standard</th>
              <th className="py-2 px-4 text-xl">Professional</th>
              <th className="py-2 px-4 text-xl">Enterprise</th>
            </tr>
          </thead>
          <tbody>
            {features.map((feature, index) => (
              <tr key={index} className="border-b">
                <td className="py-2">{feature.name}</td>
                {feature.tiers.map((value, i) => (
                  <td key={i} className="text-center py-4">
                    {typeof value === 'boolean' ? (
                      value ? <Check className="inline h-5 w-5 text-green-500" /> : <X className="inline h-5 w-5 text-red-500" />
                    ) : (
                      value
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="text-3xl font-bold text-center my-6">Scegli in Serenità: La Tua Prova Senza Rischi</h2>
      <p className="text-xl mb-8">
        Scopri come Restruct può semplificare la gestione della tua impresa edile. Con la nostra prova gratuita di 7 giorni, esplori senza impegno tutte le funzionalità disponibili. Non ci sono sorprese, solo la possibilità di vedere come possiamo aiutarti a organizzare i tuoi cantieri in modo efficace. Inizia oggi e scegli in serenità!
      </p>

      <Alert className="mb-8">
        <HelpCircle className="h-4 w-4" />
        <AlertTitle>Prova gratuita senza rischi</AlertTitle>
        <AlertDescription>
          Inizia la tua prova gratuita di 7 giorni senza impegno. Puoi cancellare in qualsiasi momento durante il periodo di prova senza alcun addebito.
        </AlertDescription>
      </Alert>

      <h2 className="text-3xl font-bold my-4 text-center">Domande Frequenti</h2>
      <Accordion type="single" collapsible className="w-full mb-8">
        {faqs.map((faq, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger>{faq.question}</AccordionTrigger>
            <AccordionContent>{faq.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Prova Gratuita Attivata</DialogTitle>
            <DialogDescription>
              Hai selezionato il piano {selectedPlan?.name}. La tua prova gratuita di 7 giorni è stata attivata.
              Dopo 7 giorni, ti verrà addebitato l'importo di €{(selectedPlan?.prices.month?.unit_amount / 100).toFixed(2)} al mese,
              a meno che tu non cancelli prima della fine del periodo di prova.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleModalConfirm} className='w-full'>
              Accedi ed Esplora le Funzionalità
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RegisterSubscription;