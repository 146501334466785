import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createWorkJournalEntry = async (siteId, data) => {
  try {
    // Convert the date to UTC midnight
    const utcDate = new Date(Date.UTC(
      data.date.getFullYear(),
      data.date.getMonth(),
      data.date.getDate()
    ));
    
    const response = await axios.post(`/construction-sites/${siteId}/work-journal`, {
      ...data,
      date: utcDate.toISOString()
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getWorkJournalEntries = async (siteId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/work-journal`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getWorkJournalEntry = async (siteId, date) => {
  try {
    // Convert to UTC date string
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    )).toISOString().split('T')[0];
    
    const response = await axios.get(`/construction-sites/${siteId}/work-journal/${utcDate}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error.response ? error.response.data : error;
  }
};



export const updateWorkJournalEntry = async (siteId, entryId, data) => {
  try {
    const response = await axios.put(`/construction-sites/${siteId}/work-journal/${entryId}`, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const deleteWorkJournalEntry = async (siteId, entryId) => {
  try {
    const response = await axios.delete(`/construction-sites/${siteId}/work-journal/${entryId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const clearWorkJournalEntry = async (siteId, date) => {
  try {
    // Format the date consistently
    const dateObj = new Date(date);
    const formattedDate = `${dateObj.getUTCFullYear()}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObj.getUTCDate()).padStart(2, '0')}`;
    
    const response = await axios.delete(`/construction-sites/${siteId}/work-journal/bydate/${formattedDate}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};


export const exportWorkJournalToPDF = async (siteId, entryId) => {
  try {
    const response = await axios.get(`/construction-sites/${siteId}/work-journal/${entryId}/export`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('Error in exportWorkJournalToPDF:', error);
    if (error.response) {
      const errorMessage = error.response.data instanceof Blob 
        ? await error.response.data.text() 
        : JSON.stringify(error.response.data);
      throw new Error(errorMessage);
    } else if (error.request) {
      throw new Error('No response from server');
    } else {
      throw error;
    }
  }
};