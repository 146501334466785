import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { Country, State, City } from "country-state-city";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Save } from "lucide-react";
import { fetchCompany, updateCompany } from "../../../../apis/CompanyDetail";
import { Button } from "../../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { Textarea } from "../../../ui/textarea";
import ImageController from "../../sharedComponent/ImageController";
import Spinner from "../../../Spinner";
import HelpSheet from "../../sharedComponent/HelpSheet";

const capitalizeWords = (str) => {
  return str
    .replace(/([A-Z0-9])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/(inail|iban|inps)/gi, (match) => match.toUpperCase())
    .trim();
};

const getFieldConfig = (key) => {
  if (key === "country") return { type: "select", options: "countries" };
  if (key === "state") return { type: "select", options: "regions" };
  if (key === "city") return { type: "select", options: "cities" };
  if (key.includes("description") || key.includes("comments")) return { type: "textarea" };
  if (key.includes("date")) return { type: "date" };
  if (key.includes("email")) return { type: "email" };
  if (key.includes("number") || key.includes("id")) return { type: "number" };
  return { type: "text" };
};

const translateName = (key) => {
  const italianLanguage = {
    companyName: "Ragione Sociale",
    address: "Indirizzo",
    state: "Regione",
    postalCode: "CAP",
    addressNote: "Note indirizzo",
    fax: "Fax",
    website: "Sito web",
    iban: "IBAN",
    nickName: "Soprannome",
    country: "Nazione",
    city: "Città",
    province: "Provincia",
    taxIdCode: "Partita IVA",
    businessEmail: "Email principale",
    secondaryEmail: "Email secondaria",
    pec: "PEC",
    telephoneNumber: "Telefono",
    mobileNumber: "Cellulare",
    typeOfBusiness: "Tipologia azienda",
    inpsPosition: "Posizione INPS",
    inailPosition: "Posizione INAIL",
    edilcassaPosition: "Posizione Cassa Edile / Edilcassa",
    bank: "Banca",
  };
  return italianLanguage[key] || capitalizeWords(key);
};

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [companyInfo, setCompanyInfo] = useState(null);
  const { control, handleSubmit, reset, setValue, watch } = useForm();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const selectedCountry = watch("country");
  const selectedState = watch("state");

  // Load initial company data
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetchCompany(id);
        if (response.status === 200) {
          const company = response.data.company;
          if (company.state) {
            company.state = company.state.toString();
          }
          setCompanyInfo(company);
          reset(company);
        }
      } catch (error) {
        console.error("Errore nel recupero delle informazioni aziendali:", error);
        toast.error("Impossibile recuperare le informazioni aziendali");
      }
    };

    fetchCompanyInfo();
  }, [id, reset]);

  // Load countries on mount
  useEffect(() => {
    const loadCountries = () => {
      const allCountries = Country.getAllCountries().map(country => ({
        label: country.name,
        value: country.isoCode,
      }));
      setCountries(allCountries);
    };
    loadCountries();
  }, []);

  // Handle country change and load states
  useEffect(() => {
    if (selectedCountry) {
      // Reset dependent fields
      setValue("state", "");
      setValue("city", "");
      setCities([]);
      
      // Load states/regions for selected country
      const statesData = State.getStatesOfCountry(selectedCountry) || [];
      const formattedStates = statesData.map(state => ({
        label: state.name,
        value: state.isoCode,
      }));
      setStates(formattedStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry, setValue]);

  // Handle state change and load cities
  useEffect(() => {
    const loadCities = async () => {
      if (selectedCountry && selectedState) {
        setIsLoadingCities(true);
        try {
          const citiesData = City.getCitiesOfState(selectedCountry, selectedState) || [];
          const formattedCities = citiesData.map(city => ({
            label: city.name,
            value: city.name,
          }));
          setCities(formattedCities);
        } catch (error) {
          console.error("Error loading cities:", error);
          toast.error("Errore nel caricamento delle città");
        } finally {
          setIsLoadingCities(false);
        }
      } else {
        setCities([]);
      }
    };

    loadCities();
  }, [selectedCountry, selectedState]);

  const onSubmit = async (data) => {
    try {
      const response = await updateCompany(companyInfo?._id, data);
      if (response.status === 200) {
        toast.success("Informazioni aggiornate con successo");
        navigate(-1);
      }
    } catch (error) {
      console.error("Errore nell'aggiornamento delle informazioni aziendali:", error);
      toast.error("Impossibile aggiornare le informazioni aziendali");
    }
  };

  const renderLocationFields = () => {
    return (
      <>
        <div className="w-full">
          <Label htmlFor="country" className="mb-2 block">
            {translateName("country")}
          </Label>
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                onValueChange={field.onChange}
                value={field.value || ""}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona la nazione" />
                </SelectTrigger>
                <SelectContent>
                  {countries.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>

        <div className="w-full">
          <Label htmlFor="state" className="mb-2 block">
            {translateName("state")}
          </Label>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                onValueChange={field.onChange}
                value={field.value || ""}
                disabled={!selectedCountry || states.length === 0}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona la regione" />
                </SelectTrigger>
                <SelectContent>
                  {states.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>

        <div className="w-full">
          <Label htmlFor="city" className="mb-2 block">
            {translateName("city")}
          </Label>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Select
                onValueChange={field.onChange}
                value={field.value || ""}
                disabled={!selectedState || isLoadingCities}
              >
                <SelectTrigger className="w-full">
                  <SelectValue 
                    placeholder={isLoadingCities ? "Caricamento città..." : "Seleziona la città"} 
                  />
                </SelectTrigger>
                <SelectContent>
                  {cities.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
      </>
    );
  };

  const renderField = useMemo(() => (key) => {
    const { type } = getFieldConfig(key);
    const label = translateName(key);

    if (["country", "state", "city"].includes(key)) {
      return null; // These fields are handled by renderLocationFields
    }

    switch (type) {
      case "textarea":
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Textarea {...field} placeholder={`Inserisci ${label}`} className="w-full" />
            )}
          />
        );
      default:
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Input {...field} type={type} placeholder={`Inserisci ${label}`} className="w-full" />
            )}
          />
        );
    }
  }, [control]);

  if (!companyInfo) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto p-2 min-h-screen">
      <Card className="w-full mx-auto">
        <CardHeader className="bg-gray-100 border-b p-2">
          <CardTitle className="text-lg font-bold flex items-center justify-between">
            Modifica Informazioni Aziendali
            <HelpSheet />
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-8">
              <ImageController
                label="Logo 1"
                name="logo1"
                control={control}
                rounded={false}
                defaultValue={companyInfo.logo1 ? `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}` : ""}
              />
              <ImageController
                label="Logo 2"
                name="logo2"
                control={control}
                rounded={false}
                defaultValue={companyInfo.logo2 ? `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo2}` : ""}
              />
            </div>
            
            <div className="space-y-6">
              {/* Dati Principali */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Dati Principali</h3>
                </div>
                <div className="border rounded-lg p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {["companyName", "nickName", "typeOfBusiness", "taxIdCode"].map((key) => (
                      <div key={key} className="w-full">
                        <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                        {renderField(key)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Sede Legale */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Sede Legale</h3>
                </div>
                <div className="border rounded-lg p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {renderLocationFields()}
                    {["address", "province", "postalCode", "addressNote"].map((key) => (
                      <div key={key} className="w-full">
                        <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                        {renderField(key)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Contatti */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Contatti</h3>
                </div>
                <div className="border rounded-lg p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {["telephoneNumber", "mobileNumber", "fax", "businessEmail", "secondaryEmail", "pec", "website"].map((key) => (
                      <div key={key} className="w-full">
                        <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                        {renderField(key)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Dati bancari and Posizioni azienda in a grid */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="bg-card rounded-lg relative">
                  <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                    <h3 className="text-lg font-semibold">Dati bancari</h3>
                  </div>
                  <div className="border rounded-lg p-6">
                    <div className="grid grid-cols-1 gap-6">
                      {["iban", "bank"].map((key) => (
                        <div key={key} className="w-full">
                          <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                          {renderField(key)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="bg-card rounded-lg relative">
                  <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                    <h3 className="text-lg font-semibold">Posizioni azienda</h3>
                  </div>
                  <div className="border rounded-lg p-6">
                    <div className="grid grid-cols-1 gap-6">
                      {["edilcassaPosition", "inpsPosition", "inailPosition"].map((key) => (
                        <div key={key} className="w-full">
                          <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                          {renderField(key)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-8">
              <Button type="submit" className="w-full sm:w-auto">
                <Save className="mr-2" size={16} /> Salva Modifiche
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Edit;