import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit, Trash, AlertTriangle, ArrowLeft } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { toast } from 'react-toastify';
import { getSingleRateizzi, updateRateizzi, updateInstallmentStatus } from '../../../apis/RateizziService';

const RateizziDetail = () => {
  const { id } = useParams();
  const [rateizzi, setRateizzi] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [editData, setEditData] = useState({ name: '', totalAmount: '' });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRateizzi();
  }, [id]);

  const fetchRateizzi = async () => {
    try {
      const data = await getSingleRateizzi(id);
      setRateizzi(data);
      setEditData({ name: data.name, totalAmount: data.totalAmount });
    } catch (error) {
      toast.error('Error loading rateizzi');
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateRateizzi(id, editData);
      await fetchRateizzi();
      setIsEditModalOpen(false);
      toast.success('Rateizzi updated successfully');
    } catch (error) {
      toast.error('Error updating rateizzi');
    }
  };

  const handleStatusUpdate = async (status) => {
    try {
      await updateInstallmentStatus(id, selectedInstallment._id, {
        status,
        paymentDate: status === 'Pagata' ? new Date() : null
      });
      await fetchRateizzi();
      setIsStatusModalOpen(false);
      toast.success('Status updated successfully');
    } catch (error) {
      toast.error('Error updating status');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!rateizzi) {
    return <div>Rateizzi not found</div>;
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pagata': return 'text-green-500';
      case 'In ritardo': return 'text-yellow-500';
      case 'Non pagata': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            onClick={() => navigate('/installment')}
            className="gap-1"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-2xl font-bold">{rateizzi.name}</h2>
        </div>
        <Button onClick={() => setIsEditModalOpen(true)}>
          <Edit className="w-4 h-4 mr-1" />
          Modifica
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Importo totale</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">€{rateizzi.totalAmount.toFixed(2)}</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Numero rate</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{rateizzi.numberOfInstallments}</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Stato</CardTitle>
          </CardHeader>
          <CardContent>
            <p className={`text-2xl font-bold ${getStatusColor(rateizzi.status)}`}>
              {rateizzi.status}
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Frequenza</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">
              {rateizzi.frequency === 'monthly' ? 'Mensile' :
                rateizzi.frequency === 'weekly' ? 'Settimanale' :
                  'Personalizzato'}
            </p>
          </CardContent>
        </Card>
      </div>

      {/* Installments Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Numero</TableHead>
              <TableHead>Data pagamento</TableHead>
              <TableHead>Importo rata</TableHead>
              <TableHead>Data scadenza</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {rateizzi.installments.map((installment) => (
              <TableRow key={installment._id}>
                <TableCell>{installment.number}</TableCell>
                <TableCell>
                  {installment.paymentDate
                    ? new Date(installment.paymentDate).toLocaleDateString()
                    : '-'}
                </TableCell>
                <TableCell>€{installment.amount.toFixed(2)}</TableCell>
                <TableCell>
                  {new Date(installment.dueDate).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <span className={getStatusColor(installment.status)}>
                    {installment.status}
                  </span>
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      setSelectedInstallment(installment);
                      setIsStatusModalOpen(true);
                    }}
                  >
                    <Edit className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifica rateizzi</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Nome</Label>
              <Input
                id="name"
                value={editData.name}
                onChange={(e) => setEditData(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="totalAmount">Importo totale</Label>
              <Input
                id="totalAmount"
                type="number"
                value={editData.totalAmount}
                onChange={(e) => setEditData(prev => ({
                  ...prev,
                  totalAmount: e.target.value
                }))}
                required
              />
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => setIsEditModalOpen(false)}>
                Annulla
              </Button>
              <Button type="submit">Salva</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Status Update Modal */}
      <Dialog open={isStatusModalOpen} onOpenChange={setIsStatusModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiorna stato rata</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Seleziona il nuovo stato per la rata {selectedInstallment?.number}
          </DialogDescription>
          <div className="space-y-4">
            <Select
              onValueChange={handleStatusUpdate}
              defaultValue={selectedInstallment?.status}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleziona stato" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Pagata">Pagata</SelectItem>
                <SelectItem value="In ritardo">In ritardo</SelectItem>
                <SelectItem value="Non pagata">Non pagata</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsStatusModalOpen(false)}>
              Annulla
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RateizziDetail;