import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ClientDetailsPage from "../../../components/admin/personalData/client/ClientDetailsPage"

const ClientDetails = () => {
  return (
    <Layout><ClientDetailsPage /></Layout>
  )
}

export default ClientDetails