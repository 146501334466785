import React, { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, startOfWeek, endOfWeek } from "date-fns";
import { Input } from "../../../../ui/input";
import { Button } from "../../../../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../../ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/select";
import { Label } from "../../../../ui/label";
import { Card, CardContent, CardHeader } from "../../../../ui/card";
import {
  getCompanyEmployeePlannings,
  updateCompanyEmployeePlannings,
  deleteCompanyEmployeePlannings,
  getCompanyPlans,
} from "../../../../../apis/Planning";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import EmployeeSelectionDialog from '../attendanceSheet/SelectEmployeeModal';
import { getEmployeeGroups } from "../../../../../apis/EmployeeGroups";

const Planning = ({ employeeData: initialEmployeeData }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [planningData, setPlanningData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPlanningModalOpen, setPlanningModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyPlans, setCompanyPlans] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isSelectEmployeeDialogOpen, setSelectEmployeeDialogOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const hasInitialized = useRef(false);
  const [allEmployees, setAllEmployees] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getEmployeeGroups(JSON.parse(localStorage.getItem("user")).companyId);
        setGroups(response.data.groups);
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast.error('Unable to fetch employee groups');
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchCompanyPlans = async () => {
      try {
        const response = await getCompanyPlans();
        if (response.status === 200) {
          setCompanyPlans(response.data.plans);
        }
      } catch (error) {
        console.error("Unable to fetch company plans", error);
        toast.error("Unable to fetch company plans");
      }
    };
    fetchCompanyPlans();
  }, []);

  useEffect(() => {
    const getEmployeePlannings = async () => {
      try {
        const monthQuery = format(selectedMonth, "yyyy-MM");
        const response = await getCompanyEmployeePlannings(monthQuery, searchQuery);
        if (response.status === 200) {
          // Merge planning data with employee data
          const planningEmployees = response.data?.employees || [];
          const mergedEmployees = initialEmployeeData.map(emp => {
            const planningEmployee = planningEmployees.find(pe => pe._id === emp.employeeId);
            return {
              _id: emp.employeeId,
              firstName: emp.name,
              sureName: emp.sureName,
              freshman: emp.freshman,
              plannings: planningEmployee?.plannings || []
            };
          });

          setAllEmployees(mergedEmployees);

          if (!hasInitialized.current && mergedEmployees.length > 0) {
            setSelectedEmployees(mergedEmployees.map(emp => emp._id));
            hasInitialized.current = true;
          }

          const filtered = mergedEmployees.filter(emp =>
            selectedEmployees.length === 0 || selectedEmployees.includes(emp._id)
          ).filter(emp =>
            searchQuery ? (
              emp.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              emp.sureName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              emp.freshman?.toLowerCase().includes(searchQuery.toLowerCase())
            ) : true
          );
          setPlanningData(filtered);
        }
      } catch (error) {
        console.error("Unable to fetch planning data", error);
        toast.error("Unable to fetch planning data");
      }
    };

    getEmployeePlannings();
  }, [selectedMonth, searchQuery, selectedEmployees, initialEmployeeData]);

  const handleEmployeeSelect = (newSelectedEmployees) => {
    setSelectedEmployees(newSelectedEmployees || []);
  };

  const incrementMonth = () => {
    setSelectedMonth(prev => new Date(prev.setMonth(prev.getMonth() + 1)));
  };

  const decrementMonth = () => {
    setSelectedMonth(prev => new Date(prev.setMonth(prev.getMonth() - 1)));
  };

  const getCalendarDays = () => {
    const start = startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(selectedMonth), { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  const days = getCalendarDays();
  const weeks = [];
  let currentWeek = [];

  days.forEach((day) => {
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
    currentWeek.push(day);
  });
  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
  }

  const openPlanningModal = (employee, date) => {
    setSelectedEmployee(employee);
    setSelectedDate(date);
    setPlanningModalOpen(true);
  };

  const handlePlanningSubmit = async (planId) => {
    try {
      const response = await updateCompanyEmployeePlannings({
        employeeId: selectedEmployee._id,
        date: format(selectedDate, "yyyy-MM-dd"),
        planId: planId,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Planning updated successfully");

        // Get the selected plan details from companyPlans
        const selectedPlan = companyPlans.find(plan => plan._id === planId);

        // Update the planningData state directly first
        setPlanningData(prevData => {
          return prevData.map(employee => {
            if (employee._id === selectedEmployee._id) {
              const updatedPlannings = [...(employee.plannings || [])];

              // Find if there's an existing planning for this date
              const existingPlanningIndex = updatedPlannings.findIndex(
                plan => format(new Date(plan.date), "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd")
              );

              const newPlanning = {
                _id: response.data.planning._id,
                employeeId: selectedEmployee._id,
                date: format(selectedDate, "yyyy-MM-dd"),
                planId: {
                  ...selectedPlan,
                  _id: planId
                }
              };

              if (existingPlanningIndex !== -1) {
                updatedPlannings[existingPlanningIndex] = newPlanning;
              } else {
                updatedPlannings.push(newPlanning);
              }

              return {
                ...employee,
                plannings: updatedPlannings
              };
            }
            return employee;
          });
        });

        // Refresh the data from the server in the background
        const monthQuery = format(selectedMonth, "yyyy-MM");
        const refreshResponse = await getCompanyEmployeePlannings(monthQuery, searchQuery);
        if (refreshResponse.status === 200) {
          const filtered = refreshResponse.data.employees.filter(emp =>
            selectedEmployees.length === 0 || selectedEmployees.includes(emp._id)
          );
          setPlanningData(filtered);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to update planning");
    }
    setPlanningModalOpen(false);
  };

  const handlePlanningDelete = async (planningId) => {
    try {
      const response = await deleteCompanyEmployeePlannings(planningId);
      if (response.status === 200) {
        toast.success("Planning deleted successfully");
        const monthQuery = format(selectedMonth, "yyyy-MM");
        const response = await getCompanyEmployeePlannings(monthQuery, searchQuery);
        if (response.status === 200) {
          const filtered = response.data.employees.filter(emp =>
            selectedEmployees.length === 0 || selectedEmployees.includes(emp._id)
          );
          setPlanningData(filtered);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to delete planning");
    }
    setPlanningModalOpen(false);
  };

  return (
    <Card className="mx-auto p-0 border-0">
      <CardHeader className="py-2 px-0">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center gap-2">
            <Button onClick={decrementMonth} variant="outline" className="p-1">
              <ChevronLeft className="w-4 h-4 text-gray-600" />
            </Button>
            <span className="text-gray-900 text-sm">
              {format(selectedMonth, 'd MMM yyyy')} {'>'} {format(endOfMonth(selectedMonth), 'd MMM yyyy')}
            </span>
            <Button onClick={incrementMonth} variant="outline" className="p-1">
              <ChevronRight className="w-4 h-4 text-gray-600" />
            </Button>
          </div>
          <div className="flex items-center gap-2 md:mt-0">
            <Button variant="outline" onClick={() => setSelectEmployeeDialogOpen(true)}>
              Seleziona Dipendenti
            </Button>
            <HelpSheet route="/employees" />
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-0 mt-3">
        <div className="overflow-x-auto">
          <div className="min-w-max">
            {/* Calendar Headers - Days and Dates */}
            <div className="ml-10 flex">
              {weeks.map((week, weekIndex) => (
                <div key={weekIndex} className="flex">
                  <div className="flex">
                    {week.map((day, dayIndex) => (
                      <div key={dayIndex} className="w-8">
                        <div className="text-[11px] text-gray-600 text-center font-medium">
                          {format(day, 'EEE').toUpperCase()}
                        </div>
                        <div className="text-[11px] text-blue-500 text-center">
                          {format(day, 'd')}
                        </div>
                      </div>
                    ))}
                  </div>
                  {weekIndex < weeks.length - 1 && (
                    <div className="w-4 flex items-center justify-center">
                      <div className="h-full w-px bg-gray-200"></div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Employees Section */}
            <div className="mt-4 space-y-6">
              {planningData.map((employee) => (
                <div key={employee._id} className="flex flex-col">
                  {/* Employee Info */}
                  <div className="flex items-center gap-2 mb-1">
                    <div className="w-9 h-9 bg-gray-100 rounded-full flex items-center justify-center">
                      <span className="text-md text-gray-600 font-medium">
                        {`${employee.firstName[0]}${employee.sureName?.[0] || ''}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-md text-gray-900">
                        {employee.firstName} <span className="font-medium">{employee.sureName}</span>
                      </span>
                      <span className="text-xs text-gray-500">
                        Matricola: {employee.freshman}
                      </span>
                    </div>
                  </div>

                  <div className="text-xs text-gray-600 ml-11 mb-2">nessun orario attivo per questa settimana</div>

                  {/* Calendar Boxes */}
                  <div className="ml-10 flex">
                    {weeks.map((week, weekIndex) => (
                      <div key={weekIndex} className="flex">
                        <div className="flex">
                          {week.map((day) => {
                            const planningForDate = employee?.plannings?.find((plan) => {
                              const planDate = new Date(plan.date);
                              return (
                                planDate.getDate() === day.getDate() &&
                                planDate.getMonth() === day.getMonth() &&
                                planDate.getFullYear() === day.getFullYear()
                              );
                            });

                            const planDetails = planningForDate?.planId;

                            return (
                              <div key={`${employee._id}-${day.getTime()}`} className="w-8">
                                <div
                                  onClick={() => openPlanningModal(employee, day)}
                                  className={`w-8 h-9 border rounded hover:bg-gray-50 cursor-pointer mx-auto flex items-center justify-center ${!isSameMonth(day, selectedMonth) ? 'bg-gray-100' : ''
                                    } ${planningForDate?.planId
                                      ? 'border-green-500 border-2'
                                      : ''
                                    }`}
                                  style={{
                                    backgroundColor: isSameMonth(day, selectedMonth)
                                      ? planDetails?.color || 'white'
                                      : '#f3f4f6'
                                  }}
                                >
                                  {planDetails && isSameMonth(day, selectedMonth) && (
                                    <div className="flex flex-col items-center text-[10px]">
                                      <span className="font-semibold text-white">
                                        {planDetails.acronym}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {weekIndex < weeks.length - 1 && (
                          <div className="w-4 flex items-center justify-center">
                            <div className="h-full w-px bg-gray-200"></div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>

      <Dialog open={isPlanningModalOpen} onOpenChange={setPlanningModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Pianificazione per {selectedEmployee?.firstName} {selectedEmployee?.sureName}
              <span className="text-sm font-normal text-gray-500 ml-2">
                (Matricola: {selectedEmployee?.freshman})
              </span>
            </DialogTitle>
          </DialogHeader>
          <PlanningModalContent
            employee={selectedEmployee}
            date={selectedDate}
            onSubmit={handlePlanningSubmit}
            onDelete={handlePlanningDelete}
            companyPlans={companyPlans}
            initialData={selectedEmployee?.plannings?.find((plan) =>
              format(new Date(plan.date), "yyyy-MM-dd") === format(selectedDate || new Date(), "yyyy-MM-dd")
            )}
          />
        </DialogContent>
      </Dialog>

      <EmployeeSelectionDialog
        isOpen={isSelectEmployeeDialogOpen}
        onClose={() => setSelectEmployeeDialogOpen(false)}
        employees={allEmployees}
        groups={groups}
        selectedEmployees={selectedEmployees}
        onSelectionChange={handleEmployeeSelect}
        onConfirm={() => setSelectEmployeeDialogOpen(false)}
      />

      <ToastContainer />
    </Card>
  );
};

const PlanningModalContent = ({
  employee,
  date,
  onSubmit,
  onDelete,
  companyPlans,
  initialData,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(initialData?.planId?._id || "");

  const handleSubmit = () => {
    if (selectedPlan) {
      onSubmit(selectedPlan);
    }
  };

  const handleDelete = () => {
    if (initialData?._id) {
      onDelete(initialData._id);
    }
  };

  const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);

  return (
    <div className="space-y-4">
      <p>Data: {format(date, 'dd/MM/yyyy')}</p>
      <div className="space-y-2">
        <Label htmlFor="planSelect">Seleziona turno</Label>
        <Select value={selectedPlan} onValueChange={setSelectedPlan}>
          <SelectTrigger id="planSelect">
            <SelectValue placeholder="Seleziona un turno" />
          </SelectTrigger>
          <SelectContent>
            {companyPlans.map((plan) => (
              <SelectItem key={plan._id} value={plan._id}>
                {`${plan.name} (${plan.acronym}) - ${plan.workSite}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {selectedPlanDetails && (
        <div className="space-y-2">
          <h3 className="font-bold">Dettagli Turno:</h3>
          <div className="grid gap-2 p-4 border rounded-lg">
            <p>Nome: {selectedPlanDetails.name}</p>
            <p>Acronimo: {selectedPlanDetails.acronym}</p>
            <p>Sede: {selectedPlanDetails.workSite}</p>
            <p>Orario: {selectedPlanDetails.startTime} - {selectedPlanDetails.endTime}</p>
            {selectedPlanDetails.isContinuous ? (
              <p>Turno Continuato</p>
            ) : (
              <p>Pausa: {selectedPlanDetails.breakStartTime} - {selectedPlanDetails.breakEndTime}</p>
            )}
          </div>
        </div>
      )}

      <DialogFooter>
        <Button onClick={handleSubmit} disabled={!selectedPlan}>
          {initialData ? 'Aggiornamento' : 'Salva'}
        </Button>
        {initialData && (
          <Button variant="destructive" onClick={handleDelete}>
            Elimina
          </Button>
        )}
      </DialogFooter>
    </div>
  );
};

export default Planning;