import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, Trash, Eye, Plus, HelpCircle, AlertTriangle } from "lucide-react";
import { toast } from 'react-toastify';
import {
  createRateizzi,
  getRateizzi,
  deleteRateizzi
} from '../../../apis/RateizziService';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../ui/tooltip";
import { Alert, AlertDescription } from "../../ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Skeleton } from "../../ui/skeleton";
import {
  ScrollArea,
  ScrollBar
} from "../../ui/scroll-area";

// Stats Card Component
const StatsCard = ({ title, value, isLoading, tooltip }) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium flex items-center gap-2">
          {title}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <HelpCircle className="h-4 w-4 text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-[200px] text-sm">{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="h-8 w-[100px]" />
        ) : (
          <div className="text-2xl font-bold">{value}</div>
        )}
      </CardContent>
    </Card>
  );
};

const RateizziManagement = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [rateizzi, setRateizzi] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [rateizzoToDelete, setRateizzoToDelete] = useState(null);
  const [password, setPassword] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [customAmounts, setCustomAmounts] = useState([]);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [customDates, setCustomDates] = useState([]);
  const [installmentData, setInstallmentData] = useState({
    totalAmount: '',
    numberOfInstallments: '',
    startDate: '',
    frequency: 'monthly',
    name: ''
  });
  const [previewInstallments, setPreviewInstallments] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [stats, setStats] = useState({
    inCorso: 0,
    totalAmount: 0,
    totalInstallments: 0,
    upcomingPayments: 0
  });

  useEffect(() => {
    fetchRateizzi();
  }, []);

  const calculateStats = (data) => {
    const now = new Date();
    const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

    const stats = {
      inCorso: data.filter(r => r.status === 'In corso').length,
      totalAmount: data.reduce((sum, r) => sum + r.totalAmount, 0),
      totalInstallments: data.reduce((sum, r) => {
        const unpaidInstallments = r.installments.filter(i => i.status !== 'Pagata');
        return sum + unpaidInstallments.length;
      }, 0),
      upcomingPayments: data.reduce((count, ratezzo) => {
        const upcomingInstallments = ratezzo.installments.filter(inst =>
          inst.status !== 'Pagata' &&
          new Date(inst.dueDate) <= nextWeek &&
          new Date(inst.dueDate) >= now
        );
        return count + upcomingInstallments.length;
      }, 0)
    };

    setStats(stats);
  };

  const fetchRateizzi = async () => {
    try {
      setIsLoading(true);
      const data = await getRateizzi();
      setRateizzi(Array.isArray(data) ? data : []);
      calculateStats(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error details:', error);
      toast.error('Errore nel caricamento dei rateizzi');
      setRateizzi([]);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateInstallments = () => {
    const { totalAmount, numberOfInstallments, startDate, frequency } = installmentData;
    if (!totalAmount || !numberOfInstallments || (!startDate && frequency !== 'custom')) {
      toast.error('Inserisci tutti i campi richiesti');
      return;
    }

    if (frequency === 'custom' && customDates.some(date => !date)) {
      toast.error('Inserisci tutte le date per le rate personalizzate');
      return;
    }

    const amount = parseFloat(totalAmount);
    const count = parseInt(numberOfInstallments);

    if (isNaN(amount) || amount <= 0) {
      toast.error('Importo non valido');
      return;
    }

    if (isNaN(count) || count <= 0) {
      toast.error('Numero rate non valido');
      return;
    }

    // Initialize custom amounts array with default equal installments
    const defaultAmount = Math.round((amount / count) * 100) / 100;
    const newCustomAmounts = Array(count).fill(defaultAmount);
    setCustomAmounts(newCustomAmounts);
    setRemainingAmount(0);

    const preview = [];

    if (frequency === 'custom') {
      // Handle custom dates
      const sortedDates = [...customDates].sort((a, b) => new Date(a) - new Date(b));
      for (let i = 0; i < count; i++) {
        preview.push({
          number: i + 1,
          dueDate: new Date(sortedDates[i]),
          amount: newCustomAmounts[i],
          status: 'Non pagata'
        });
      }
    } else {
      let currentDate = new Date(startDate);
      currentDate.setHours(0, 0, 0, 0);

      for (let i = 0; i < count; i++) {
        preview.push({
          number: i + 1,
          dueDate: new Date(currentDate),
          amount: newCustomAmounts[i],
          status: 'Non pagata'
        });

        if (frequency === 'monthly') {
          currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        } else {
          currentDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        }
      }
    }

    setPreviewInstallments(preview);
    setIsPreviewVisible(true);
  };

  const handleAmountChange = (index, value) => {
    const newAmount = parseFloat(value) || 0;
    const oldAmount = customAmounts[index];
    const totalAmount = parseFloat(installmentData.totalAmount);

    // Calculate the difference from the previous amount
    const difference = newAmount - oldAmount;

    // Calculate current total of all installments
    const currentTotal = customAmounts.reduce((sum, amount) => sum + amount, 0);

    // Check if the new total would exceed the total amount
    if (currentTotal + difference > totalAmount) {
      toast.error('La somma delle rate non può superare l\'importo totale');
      return;
    }

    // Update the amount for this installment
    const newCustomAmounts = [...customAmounts];
    newCustomAmounts[index] = newAmount;

    // Calculate remaining amount to be distributed
    const newTotal = newCustomAmounts.reduce((sum, amount) => sum + amount, 0);
    const remaining = Math.round((totalAmount - newTotal) * 100) / 100;

    // Update state
    setCustomAmounts(newCustomAmounts);
    setRemainingAmount(remaining);

    // Update preview installments
    const newPreviewInstallments = [...previewInstallments];
    newPreviewInstallments[index].amount = newAmount;
    setPreviewInstallments(newPreviewInstallments);
  };

  const distributeRemaining = () => {
    if (remainingAmount <= 0) return;

    const unpaidInstallments = previewInstallments
      .map((inst, index) => ({ index, amount: customAmounts[index] }))
      .filter((_, index) => index >= customAmounts.findIndex((amount, i) =>
        previewInstallments[i].amount !== amount
      ) + 1);

    if (unpaidInstallments.length === 0) return;

    const amountPerInstallment = Math.round((remainingAmount / unpaidInstallments.length) * 100) / 100;
    const newCustomAmounts = [...customAmounts];
    const newPreviewInstallments = [...previewInstallments];

    let distributedAmount = 0;
    unpaidInstallments.forEach(({ index }, i) => {
      const isLast = i === unpaidInstallments.length - 1;
      let amount;

      if (isLast) {
        // For the last installment, assign the remaining amount that hasn't been distributed
        amount = remainingAmount - distributedAmount;
      } else {
        amount = amountPerInstallment;
        distributedAmount += amountPerInstallment;
      }

      newCustomAmounts[index] = Math.round((customAmounts[index] + amount) * 100) / 100;
      newPreviewInstallments[index].amount = newCustomAmounts[index];
    });

    setCustomAmounts(newCustomAmounts);
    setPreviewInstallments(newPreviewInstallments);
    setRemainingAmount(0);
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();

    // Validate total matches sum of installments
    const totalInstallments = customAmounts.reduce((sum, amount) => sum + amount, 0);
    const roundedTotal = Math.round(totalInstallments * 100) / 100;
    const roundedExpected = Math.round(parseFloat(installmentData.totalAmount) * 100) / 100;

    if (roundedTotal !== roundedExpected) {
      toast.error('La somma delle rate deve corrispondere all\'importo totale');
      return;
    }

    try {
      const payload = {
        ...installmentData,
        totalAmount: parseFloat(installmentData.totalAmount),
        numberOfInstallments: parseInt(installmentData.numberOfInstallments),
        customDates: installmentData.frequency === 'custom' ? customDates : undefined,
        installments: previewInstallments.map(inst => ({
          number: inst.number,
          dueDate: inst.dueDate,
          amount: inst.amount,
          status: inst.status
        }))
      };

      await createRateizzi(payload);
      toast.success('Ratezzo creato con successo');
      setIsCreateModalOpen(false);
      setIsPreviewVisible(false);
      setCustomDates([]);
      setCustomAmounts([]);
      setRemainingAmount(0);
      setInstallmentData({
        totalAmount: '',
        numberOfInstallments: '',
        startDate: '',
        frequency: 'monthly',
        name: ''
      });
      fetchRateizzi();
    } catch (error) {
      toast.error(error.message || 'Errore nella creazione del ratezzo');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRateizzi(rateizzoToDelete._id, password);
      toast.success('Ratezzo eliminato con successo');
      setIsDeleteModalOpen(false);
      setPassword('');
      setDeleteError('');
      setRateizzoToDelete(null);
      fetchRateizzi();
    } catch (error) {
      setDeleteError(error.message || 'Errore nell\'eliminazione del ratezzo');
    }
  };

  const handleCustomDatesChange = (index, value) => {
    const newDates = [...customDates];
    newDates[index] = value;
    setCustomDates(newDates);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pagata': return 'text-green-500';
      case 'In ritardo': return 'text-yellow-500';
      case 'Non pagata': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('it-IT');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  return (
    <div className="p-6 space-y-6">
      {/* Stats Cards */}
      <div className="grid gap-4 md:grid-cols-4">
        <StatsCard
          title="Rateizzi in corso"
          value={stats.inCorso}
          isLoading={isLoading}
          tooltip="Numero totale di rateizzi attualmente in corso"
        />
        <StatsCard
          title="Importo totale dilazionato"
          value={formatCurrency(stats.totalAmount)}
          isLoading={isLoading}
          tooltip="Somma totale di tutti gli importi dilazionati"
        />
        <StatsCard
          title="Numero totale di rate da pagare"
          value={stats.totalInstallments}
          isLoading={isLoading}
          tooltip="Numero totale di rate ancora da pagare"
        />
        <StatsCard
          title="Rate da pagare nei prossimi giorni"
          value={stats.upcomingPayments}
          isLoading={isLoading}
          tooltip="Rate in scadenza nei prossimi 7 giorni"
        />
      </div>

      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold"></h2>
        <Button onClick={() => setIsCreateModalOpen(true)}>
          Aggiungi nuovo
        </Button>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nome rateizzo</TableHead>
              <TableHead>Importo totale</TableHead>
              <TableHead>Numero rate</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead>Data ultima rata</TableHead>
              <TableHead>Prossima rata</TableHead>
              <TableHead>Importo rata</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  <div className="flex justify-center items-center space-x-4">
                    <Skeleton className="h-4 w-[250px]" />
                  </div>
                </TableCell>
              </TableRow>
            ) : rateizzi.length > 0 ? (
              rateizzi.map((ratezzo) => (
                <TableRow key={ratezzo._id}>
                  <TableCell>{ratezzo.name}</TableCell>
                  <TableCell>{formatCurrency(ratezzo.totalAmount)}</TableCell>
                  <TableCell>{ratezzo.numberOfInstallments}</TableCell>
                  <TableCell className={getStatusColor(ratezzo.status)}>
                    {ratezzo.status}
                  </TableCell>
                  <TableCell>{formatDate(ratezzo.lastPaymentDate)}</TableCell>
                  <TableCell>{formatDate(ratezzo.nextPaymentDate)}</TableCell>
                  <TableCell>
                    {formatCurrency(ratezzo.totalAmount / ratezzo.numberOfInstallments)}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => navigate(`/installment/${ratezzo._id}`)}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Visualizza dettagli</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => {
                                setRateizzoToDelete(ratezzo);
                                setIsDeleteModalOpen(true);
                              }}
                            >
                              <Trash className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Elimina</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  Nessun Rateizzo trovato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Crea nuovo Rateizzo</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="name">Nome Rateizzo</Label>
                <Input
                  id="name"
                  value={installmentData.name}
                  onChange={(e) => setInstallmentData(prev => ({
                    ...prev,
                    name: e.target.value
                  }))}
                  required
                />
              </div>
              <div>
                <Label htmlFor="totalAmount">Importo totale</Label>
                <Input
                  id="totalAmount"
                  type="number"
                  step="0.01"
                  min="0"
                  value={installmentData.totalAmount}
                  onChange={(e) => setInstallmentData(prev => ({
                    ...prev,
                    totalAmount: e.target.value
                  }))}
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="numberOfInstallments">Numero rate</Label>
                <Input
                  id="numberOfInstallments"
                  type="number"
                  min="1"
                  value={installmentData.numberOfInstallments}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInstallmentData(prev => ({
                      ...prev,
                      numberOfInstallments: value
                    }));
                    if (installmentData.frequency === 'custom') {
                      setCustomDates(Array(parseInt(value) || 0).fill(''));
                    }
                  }}
                  required
                />
              </div>
              <div>
                <Label htmlFor="frequency">Frequenza</Label>
                <Select
                  value={installmentData.frequency}
                  onValueChange={(value) => {
                    setInstallmentData(prev => ({
                      ...prev,
                      frequency: value,
                      startDate: value === 'custom' ? '' : prev.startDate
                    }));
                    if (value === 'custom') {
                      setCustomDates(Array(parseInt(installmentData.numberOfInstallments) || 0).fill(''));
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleziona frequenza" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="monthly">Mensile</SelectItem>
                    <SelectItem value="weekly">Settimanale</SelectItem>
                    <SelectItem value="custom">Personalizzato</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {installmentData.frequency === 'custom' ? (
                <div className="col-span-2 space-y-4">
                  <Label>Date delle rate</Label>
                  <ScrollArea className="h-[200px] border rounded-md p-4">
                    <div className="grid grid-cols-2 gap-4 pr-4">
                      {customDates.map((date, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="w-8 flex-shrink-0">#{index + 1}</span>
                          <Input
                            type="date"
                            value={date}
                            onChange={(e) => handleCustomDatesChange(index, e.target.value)}
                            required
                          />
                        </div>
                      ))}
                    </div>
                    <ScrollBar />
                  </ScrollArea>
                </div>
              ) : (
                <div className="col-span-2">
                  <Label htmlFor="startDate">Data inizio</Label>
                  <Input
                    id="startDate"
                    type="date"
                    value={installmentData.startDate}
                    onChange={(e) => setInstallmentData(prev => ({
                      ...prev,
                      startDate: e.target.value
                    }))}
                    required
                  />
                </div>
              )}
            </div>

            <Button
              type="button"
              variant="secondary"
              onClick={calculateInstallments}
              className="w-full"
            >
              Anteprima rate
            </Button>

            {isPreviewVisible && (
              <div className="mt-4 border rounded-lg p-4">
                <h4 className="font-semibold mb-2">Anteprima rate</h4>
                {remainingAmount > 0 && (
                  <Alert className="mb-4">
                    <AlertTriangle className="h-4 w-4" />
                    <AlertDescription className="flex items-center">
                      Importo rimanente da distribuire: {formatCurrency(remainingAmount)}
                      <Button
                        variant="outline"
                        size="sm"
                        className="ml-4"
                        onClick={distributeRemaining}
                      >
                        Distribuisci sulle rate rimanenti
                      </Button>
                    </AlertDescription>
                  </Alert>
                )}
                <div className="max-h-40 overflow-y-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>N°</TableHead>
                        <TableHead>Scadenza</TableHead>
                        <TableHead>Importo</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {previewInstallments.map((installment, index) => (
                        <TableRow key={installment.number}>
                          <TableCell>{installment.number}</TableCell>
                          <TableCell>{formatDate(installment.dueDate)}</TableCell>
                          <TableCell>
                            <Input
                              type="number"
                              step="0.01"
                              min="0"
                              value={customAmounts[index]}
                              onChange={(e) => handleAmountChange(index, e.target.value)}
                              className="w-32"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setIsCreateModalOpen(false);
                  setIsPreviewVisible(false);
                  setCustomDates([]);
                  setCustomAmounts([]);
                  setRemainingAmount(0);
                  setInstallmentData({
                    totalAmount: '',
                    numberOfInstallments: '',
                    startDate: '',
                    frequency: 'monthly',
                    name: ''
                  });
                }}
              >
                Annulla
              </Button>
              <Button type="submit">Crea rateizzo</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conferma eliminazione</DialogTitle>
          </DialogHeader>
          <DialogDescription className="space-y-4">
            <p>Sei sicuro di voler eliminare il rateizzo "{rateizzoToDelete?.name}"?</p>
            <div className="space-y-2">
              <Label htmlFor="password">Inserisci la tua password per confermare</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setDeleteError('');
                }}
                placeholder="Inserisci la password"
              />
              {deleteError && (
                <Alert variant="destructive">
                  <AlertDescription>{deleteError}</AlertDescription>
                </Alert>
              )}
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDeleteModalOpen(false);
                setPassword('');
                setDeleteError('');
                setRateizzoToDelete(null);
              }}
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={!password}
            >
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RateizziManagement;