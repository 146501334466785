import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/admin/sharedComponent/Layout';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import {
  fetchCurrentSubscription,
  cancelSubscription,
  initiateStripeCheckout,
  changePlan,
  fetchPlans,
  resetSubscription,
} from '../../apis/SubscriptionApi';
import { useToast } from '../../components/ui/use-toast';
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Loader2, CheckCircle, XCircle, HardDrive, CircleCheck, FileText, ArrowLeftRight, Users, Truck, Store, Package, Building2, Car } from "lucide-react";
import { Badge } from "../../components/ui/badge";
import { Progress } from "../../components/ui/progress";

// Subscription status constants
const SubscriptionStatus = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  CANCELED: 'canceled',
  CANCELED_ACTIVE: 'canceled_active',
  PAST_DUE: 'past_due',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  UNPAID: 'unpaid',
};

// Add storage unit conversion utility
const formatStorage = (bytes) => {
  if (bytes === Infinity || bytes === 'Unlimited') return 'Illimitato';
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 B';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
};

// Add storage fetch function (you'll need to implement this in your API)
const fetchStorageUsage = async () => {
  // Implement your storage usage fetch logic here
  // For now, returning mock data
  return {
    used: 536870912, // 512 MB in bytes
    total: 1073741824 // 1 GB in bytes
  };
};

const SubscriptionPage = () => {
  // State management
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState('month');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [storageUsage, setStorageUsage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  // Feature translation map
  const featureTranslations = {
    cloudStorageSpace: 'Spazio Cloud',
    users: 'Utenti',
    chat: 'Chat',
    calendarAndAgenda: 'Calendario e Agenda',
    movimentsPerMonth: 'Movimenti al Mese',
    customerManagement: 'Gestione Clienti',
    supplierManagement: 'Gestione Fornitori',
    fleetManagement: 'Gestione Flotta',
    warehouseManagement: 'Gestione Magazzino',
    warehouses: 'Magazzini',
    products: 'Prodotti',
    ddtPerMonth: 'DDT al Mese',
    computiMetrici: 'Computi Metrici',
    cantieri: 'Cantieri',
    cantieriFeatures: 'Funzionalità Cantieri',
    contabilita: 'Contabilità',
    documenti: 'Documenti',
    rapportini: 'Rapportini',
    giornaleDeiBene: 'Giornale dei Beni',
    media: 'Media',
    support: 'Supporto',
    exclusiveBetaAccess: 'Accesso Beta Esclusivo'
  };

  // Utility functions
  const formatFeatureValue = useCallback((value) => {
    if (value === undefined || value === null) return 'No';
    if (typeof value === 'boolean') return value ? 'Sì' : 'No';
    if (typeof value === 'number') return value === Infinity ? 'Illimitato' : value.toLocaleString();
    if (typeof value === 'object') {
      return Object.entries(value)
        .map(([key, val]) => `${featureTranslations[key] || key}: ${formatFeatureValue(val)}`)
        .join(', ');
    }
    return value.toString();
  }, []);

  const formatFeatureName = useCallback((name) => {
    return featureTranslations[name] || name
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }, []);

  // Subscription status management
  const getSubscriptionStatus = useCallback((subscription) => {
    if (!subscription) return null;

    const now = new Date();
    const trialEnd = subscription.trialEndDate ? new Date(subscription.trialEndDate) : null;
    const currentPeriodEnd = subscription.currentPeriodEnd ? new Date(subscription.currentPeriodEnd) : null;

    const status = {
      isTrialing: subscription.status === SubscriptionStatus.TRIALING,
      isCanceled: subscription.status === SubscriptionStatus.CANCELED || subscription.cancelAtPeriodEnd,
      isActive: [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(subscription.status),
      endDate: subscription.status === SubscriptionStatus.TRIALING ? trialEnd : currentPeriodEnd,
      displayStatus: '',
      daysLeft: 0,
      canCancel: false
    };

    // Determine display status
    if (subscription.cancelAtPeriodEnd || status.isCanceled) {
      if (trialEnd && now < trialEnd) {
        status.displayStatus = 'Prova (Cancellazione Programmata)';
        status.endDate = trialEnd;
      } else if (currentPeriodEnd && now < currentPeriodEnd) {
        status.displayStatus = 'Attivo (Cancellazione Programmata)';
        status.endDate = currentPeriodEnd;
      } else {
        status.displayStatus = 'Cancellato';
      }
    } else if (status.isTrialing) {
      status.displayStatus = 'In Prova';
    } else {
      status.displayStatus = 'Attivo';
    }

    // Calculate days left
    if (status.endDate) {
      status.daysLeft = Math.max(0, Math.ceil((status.endDate - now) / (1000 * 60 * 60 * 24)));
    }

    // Determine if subscription can be canceled
    status.canCancel = status.isActive &&
      !status.isCanceled &&
      subscription.status !== SubscriptionStatus.PAST_DUE &&
      !subscription.cancelAtPeriodEnd;

    return status;
  }, []);

  // Data fetching
  const fetchSubscriptionData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const [subscriptionData, plansData, storageData] = await Promise.all([
        fetchCurrentSubscription(),
        fetchPlans(),
        fetchStorageUsage()
      ]);

      if (!subscriptionData) {
        console.warn('No subscription data received');
      }
      if (!Array.isArray(plansData)) {
        throw new Error('Invalid plans data received');
      }

      setCurrentSubscription(subscriptionData);
      setPlans(plansData);
      setStorageUsage(storageData);
    } catch (err) {
      console.error('Errore nel caricamento dei dati:', err);
      setError('Errore nel caricamento dei dati. Riprova più tardi.');
      toast({
        title: "Errore",
        description: "Impossibile caricare i dati dell'abbonamento. Riprova più tardi.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);
  // Effect for initial data load and URL parameter handling
  useEffect(() => {
    fetchSubscriptionData();
  }, [fetchSubscriptionData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const trial = searchParams.get('trial');

    if (success === 'true') {
      toast({
        title: trial === 'true' ? "Prova Attivata" : "Abbonamento Aggiornato",
        description: trial === 'true'
          ? "La tua prova gratuita di 30 giorni è iniziata con successo!"
          : "Il tuo abbonamento è stato aggiornato con successo.",
        duration: 5000,
      });
      navigate('/subscription', { replace: true });
      fetchSubscriptionData();
    } else if (success === 'false') {
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante l'elaborazione. Riprova.",
        variant: "destructive",
        duration: 5000,
      });
      navigate('/subscription', { replace: true });
    }
  }, [location, navigate, fetchSubscriptionData, toast]);

  // Action handlers
  const handleSubscribe = async (priceId) => {
    if (!priceId) {
      toast({
        title: "Errore",
        description: "Piano non valido. Riprova.",
        variant: "destructive",
      });
      return;
    }

    try {
      setActionLoading(true);
      const { checkoutUrl } = await initiateStripeCheckout(priceId);
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        throw new Error('URL di checkout non ricevuto');
      }
    } catch (error) {
      console.error('Errore durante avvio checkout:', error);
      toast({
        title: "Errore",
        description: "Impossibile avviare il checkout. Riprova.",
        variant: "destructive",
      });
    } finally {
      setActionLoading(false);
    }
  };

  const handleChangePlan = async (priceId) => {
    if (!priceId) {
      toast({
        title: "Errore",
        description: "Piano non valido. Riprova.",
        variant: "destructive",
      });
      return;
    }

    try {
      setActionLoading(true);
      const response = await changePlan(priceId);
      if (response.success) {
        toast({
          title: "Piano Modificato",
          description: "Il tuo piano di abbonamento è stato aggiornato con successo.",
        });
        await fetchSubscriptionData();
      }
    } catch (error) {
      console.error('Errore nel cambio piano:', error);
      toast({
        title: "Errore",
        description: "Impossibile cambiare il piano. Riprova.",
        variant: "destructive",
      });
    } finally {
      setActionLoading(false);
    }
  };

  const handleCancelSubscription = () => {
    const status = getSubscriptionStatus(currentSubscription);
    if (!status?.canCancel) {
      toast({
        title: "Errore",
        description: "Non è possibile cancellare l'abbonamento in questo momento.",
        variant: "destructive",
      });
      return;
    }

    setModalAction('cancel');
    setIsModalOpen(true);
  };

  const handleResetSubscription = () => {
    setModalAction('reset');
    setIsModalOpen(true);
  };

  const confirmAction = async () => {
    try {
      setActionLoading(true);
      if (modalAction === 'cancel') {
        const response = await cancelSubscription();
        if (response.success) {
          toast({
            title: "Abbonamento Cancellato",
            description: `Il tuo abbonamento è stato cancellato e rimarrà attivo fino al ${new Date(response.subscription.currentPeriodEnd).toLocaleDateString()}`,
          });
          await fetchSubscriptionData();
        }
      } else if (modalAction === 'reset') {
        const response = await resetSubscription();
        if (response.success) {
          toast({
            title: "Abbonamento Resettato",
            description: "Il tuo abbonamento è stato resettato con successo.",
          });
          await fetchSubscriptionData();
        }
      }
    } catch (error) {
      console.error('Errore durante esecuzione azione:', error);
      toast({
        title: "Errore",
        description: `Impossibile ${modalAction === 'cancel' ? 'cancellare' : 'resettare'} l'abbonamento. Riprova.`,
        variant: "destructive",
      });
    } finally {
      setActionLoading(false);
      setIsModalOpen(false);
    }
  };

  const sortedPlans = plans.sort((a, b) => {
    const aPrice = a.prices?.[selectedInterval]?.unit_amount || 0;
    const bPrice = b.prices?.[selectedInterval]?.unit_amount || 0;
    return aPrice - bPrice;
  });


  // const confirmAction = async () => {
  //   try {
  //     setActionLoading(true);
  //     if (modalAction === 'cancel') {
  //       const response = await cancelSubscription();
  //       if (response.success) {
  //         toast({
  //           title: "Abbonamento Cancellato",
  //           description: `Il tuo abbonamento è stato cancellato e rimarrà attivo fino al ${new Date(response.subscription.currentPeriodEnd).toLocaleDateString()}`,
  //         });
  //         await fetchSubscriptionData();
  //       } else {
  //         throw new Error('Cancellazione non riuscita');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Errore durante esecuzione azione:', error);
  //     toast({
  //       title: "Errore",
  //       description: `Impossibile ${modalAction === 'cancel' ? 'cancellare' : 'eseguire'} l'operazione. Riprova.`,
  //       variant: "destructive",
  //     });
  //   } finally {
  //     setActionLoading(false);
  //     setIsModalOpen(false);
  //   }
  // };

  const planDescriptions = {
    "Piano Restruct Basic": "Perfetto per piccole imprese edili",
    "Piano Restruct Standard": "Ideale per imprese in crescita",
    "Piano Restruct Professional": "Ottimo per aziende di medie dimensioni",
    "Piano Restruct Enterprise": "Completo per grandi imprese edili"
  };

  const PricingCard = ({ plan, selectedInterval, onAction, status, isPlanActive, isSubscriptionActive, actionLoading }) => {
    const price = plan.prices?.[selectedInterval];
    const priceAmount = price ? (price.unit_amount / 100).toFixed(2) : 'N/A';
    const isPopular = plan.name.toLowerCase().includes('professional');

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="h-full"
      >
        <div className={`relative bg-white rounded-2xl p-4 h-full flex flex-col ${isPopular
          ? 'border-2 border-primary shadow-lg shadow-blue-100 ring-1 ring-blue-500/20'
          : 'border'
          } ${isPlanActive ? 'bg-blue-50' : ''}`}
        >
          {isPopular && (
            <div className="absolute -top-4 left-0 right-0 mx-auto w-max bg-primary text-white px-4 py-1 text-sm rounded-full shadow-sm">
              Più popolare
            </div>
          )}
          <div className={`${isPopular ? 'pt-2' : ''}`}>
            <h3 className="font-semibold text-xl mb-2">
              {plan.name.replace('Piano Restruct ', '')}
            </h3>
            <p className="text-gray-600 text-sm">
              {planDescriptions[plan.name] || ''}
            </p>

            <div className="my-6 flex items-baseline flex-wrap">
              <span className="text-4xl lg:text-5xl font-bold">€{priceAmount}</span>
              <span className="text-gray-600 ml-2">
                /{selectedInterval === 'quarter' ? 'trimestre' : selectedInterval === 'year' ? 'anno' : 'mese'}
              </span>
            </div>

            {/* Features List */}
            <div className="space-y-3">
              {Object.entries(plan.features || {}).map(([feature, value]) => {
                if (value === undefined) return null;
                return (
                  <div key={feature} className="flex items-start gap-2">
                    <div className="mt-1">
                      {typeof value === 'boolean' ? (
                        value ? (
                          <CheckCircle className="h-4 w-4 text-green-500" />
                        ) : (
                          <XCircle className="h-4 w-4 text-red-500" />
                        )
                      ) : (
                        <CircleCheck className="h-4 w-4 text-blue-500" />
                      )}
                    </div>
                    <div>
                      <span className="text-sm text-gray-700">
                        {formatFeatureName(feature)}: {formatFeatureValue(value)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-auto pt-6">
            {isPlanActive ? (
              <p className="text-green-600 font-semibold w-full text-center">Piano Attuale</p>
            ) : (
              <div className="flex flex-col gap-2 w-full">
                <Button
                  onClick={() => onAction(price?.id)}
                  className={`w-full ${isPopular ? 'bg-primary' : ''}`}
                  disabled={!price || actionLoading}
                  variant={isPopular ? "default" : "outline"}
                >
                  {actionLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  {status?.isTrialing ? 'Passa a' :
                    isSubscriptionActive ? 'Cambia Piano' :
                      status?.isCanceled ? 'Riattiva' :
                        'Sottoscrivi'}
                </Button>
                {!isSubscriptionActive && (
                  <p className="text-sm text-center text-gray-600">
                    Include 30 giorni di prova gratuita
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </motion.div>
    );
  };

  // Rendering functions
  const renderUsageSection = useCallback(() => {
    if (!currentSubscription?.limits) return null;

    const { limits } = currentSubscription;

    const monthlyLimits = [
      {
        name: 'DDT',
        current: 20, // This would come from your API
        limit: limits.ddtPerMonth,
        icon: <FileText className="h-4 w-4" />
      },
      {
        name: 'Movimenti',
        current: 75, // This would come from your API
        limit: limits.movimentsPerMonth === Infinity ? 'Illimitato' : limits.movimentsPerMonth,
        icon: <ArrowLeftRight className="h-4 w-4" />
      }
    ];

    const totalLimits = [
      {
        name: 'Clienti',
        current: 45, // This would come from your API
        limit: limits.customerManagement === Infinity ? 'Illimitato' : limits.customerManagement,
        icon: <Users className="h-4 w-4" />
      },
      {
        name: 'Fornitori',
        current: 30, // This would come from your API
        limit: limits.supplierManagement === Infinity ? 'Illimitato' : limits.supplierManagement,
        icon: <Truck className="h-4 w-4" />
      },
      {
        name: 'Magazzini',
        current: 2, // This would come from your API
        limit: limits.warehouseManagement?.warehouses === Infinity ? 'Illimitato' : limits.warehouseManagement?.warehouses,
        icon: <Store className="h-4 w-4" />
      },
      {
        name: 'Prodotti',
        current: 250, // This would come from your API
        limit: limits.warehouseManagement?.products === Infinity ? 'Illimitato' : limits.warehouseManagement?.products,
        icon: <Package className="h-4 w-4" />
      },
      {
        name: 'Cantieri',
        current: 8, // This would come from your API
        limit: limits.cantieri === Infinity ? 'Illimitato' : limits.cantieri,
        icon: <Building2 className="h-4 w-4" />
      },
      {
        name: 'Veicoli',
        current: 5, // This would come from your API
        limit: limits.fleetManagement === 0 ? 0 : limits.fleetManagement === Infinity ? 'Illimitato' : limits.fleetManagement,
        icon: <Car className="h-4 w-4" />
      }
    ];

    const calculateProgress = (current, limit) => {
      if (limit === 'Illimitato' || limit === Infinity) return 100;
      if (limit === 0) return 0;
      return (current / limit) * 100;
    };

    return (
      <Card className="mb-8 border-0">
        <CardHeader>
          <CardTitle>Utilizzo del piano</CardTitle>
          <CardDescription>
            Monitora l'utilizzo delle funzionalità del tuo piano
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Monthly Limits Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Limiti mensili</h3>
            <div className="grid gap-4 md:grid-cols-2">
              {monthlyLimits.map((item) => (
                <div
                  key={item.name}
                  className="bg-gray-50 p-4 rounded-lg space-y-2"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      {item.icon}
                      <span className="font-medium">{item.name}</span>
                    </div>
                    <span className="text-sm text-gray-600">
                      {item.current}/{item.limit === Infinity ? '∞' : item.limit}
                    </span>
                  </div>
                  <Progress
                    value={calculateProgress(item.current, item.limit)}
                    className="h-2"
                  />
                  <p className="text-xs text-gray-500">
                    Si resetta il {format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Total Limits Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Limiti totali</h3>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {totalLimits.map((item) => (
                <div
                  key={item.name}
                  className="bg-gray-50 p-4 rounded-lg space-y-2"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      {item.icon}
                      <span className="font-medium">{item.name}</span>
                    </div>
                    <span className="text-sm text-gray-600">
                      {item.current}/{item.limit === Infinity ? '∞' : item.limit}
                    </span>
                  </div>
                  <Progress
                    value={calculateProgress(item.current, item.limit)}
                    className="h-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }, [currentSubscription]);

  const renderSubscriptionDetails = useCallback(() => {
    if (!currentSubscription) return null;

    const status = getSubscriptionStatus(currentSubscription);
    if (!status) return null;

    return (
      <Card className="mb-2 border-0">
        <CardHeader>
          <CardTitle>
            Piano Attuale: {currentSubscription.planType.charAt(0).toUpperCase() + currentSubscription.planType.slice(1)}
          </CardTitle>
          <CardDescription>
            Stato:{" "}
            <Badge variant={!status.isCanceled ? "default" : "secondary"}>
              {status.displayStatus}
            </Badge>
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Section 1: Subscription Details */}
            <div className="space-y-4">
              <div>
                <p className="mb-2 text-sm">
                  {status.isTrialing ? "La prova termina il" :
                    status.isCanceled ? "Accesso fino al" :
                      "Prossimo rinnovo il"}{" "}
                  {status.endDate?.toLocaleDateString()}
                </p>
                <Progress
                  value={status.daysLeft ? ((30 - status.daysLeft) / 30) * 100 : 100}
                  className="mb-2 h-2 bg-gray-200"
                  barClassName="bg-gray-500"
                />
                <p className="text-xs text-gray-500">{status.daysLeft} giorni rimanenti</p>
              </div>
            </div>

            {/* Section 2: Storage Usage */}
            {storageUsage && (
              <div className="space-y-4">
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <HardDrive className="h-4 w-4" />
                    <span className="font-medium text-sm">Spazio Cloud</span>
                  </div>
                  <Progress
                    value={(storageUsage.used / storageUsage.total) * 100}
                    className="mb-2 h-2 bg-gray-200"
                    barClassName="bg-gray-500"
                  />
                  <p className="text-xs text-gray-500">
                    {formatStorage(storageUsage.used)} utilizzati su {formatStorage(storageUsage.total)}
                  </p>
                </div>
              </div>
            )}
          </div>


        </CardContent>
        <CardFooter className="flex gap-2">
          {status.canCancel && (
            <Button
              variant="destructive"
              onClick={handleCancelSubscription}
              disabled={actionLoading}
            >
              {actionLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
              Cancella Abbonamento
            </Button>
          )}
          {/* <Button 
          variant="destructive"
          onClick={handleResetSubscription}
          disabled={actionLoading}
        >
          {actionLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
          Reset Abbonamento
        </Button> */}
        </CardFooter>
      </Card>
    );
  }, [currentSubscription, getSubscriptionStatus, actionLoading, handleCancelSubscription, storageUsage]);


  // Main render logic
  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-screen">
          <Loader2 className="h-8 w-8 animate-spin" />
          <span className="ml-2">Caricamento...</span>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="p-4 mx-auto">
          <Alert variant="destructive">
            <AlertTitle>Errore</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <Button
            onClick={fetchSubscriptionData}
            className="mt-4"
            disabled={actionLoading}
          >
            {actionLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
            Riprova
          </Button>
        </div>
      </Layout>
    );
  }

  const status = currentSubscription ? getSubscriptionStatus(currentSubscription) : null;
  const isSubscribed = currentSubscription && currentSubscription.planType !== 'free';
  const isSubscriptionActive = isSubscribed && !status?.isCanceled;

  return (
    <Layout>
      <div className="p-3 mx-auto">
        <h1 className="text-3xl font-bold mb-3 text-center">Gestione Abbonamento</h1>

        {/* Alerts Section */}
        <div className="my-4 space-y-4">
          {status.isCanceled && (
            <Alert>
              <AlertTitle>Abbonamento Cancellato</AlertTitle>
              <AlertDescription className="space-y-2 text-sm">
                <p>Il tuo accesso terminerà il {status.endDate?.toLocaleDateString()}.</p>
                <p>
                  Puoi riattivare l'abbonamento in qualsiasi momento prima di questa data
                  selezionando uno dei piani disponibili qui sotto.
                </p>
              </AlertDescription>
            </Alert>
          )}

          {currentSubscription.status === SubscriptionStatus.PAST_DUE && (
            <Alert variant="destructive">
              <AlertTitle>Pagamento Fallito</AlertTitle>
              <AlertDescription className="text-sm">
                L'ultimo pagamento non è andato a buon fine.
                Aggiorna il metodo di pagamento per evitare l'interruzione del servizio.
              </AlertDescription>
            </Alert>
          )}

          {status.isTrialing && !status.isCanceled && (
            <Alert>
              <AlertTitle>Periodo di Prova Attivo</AlertTitle>
              <AlertDescription className="text-sm">
                Stai utilizzando il periodo di prova gratuito.
                Al termine verrà attivato automaticamente l'abbonamento
                se non viene cancellato prima.
              </AlertDescription>
            </Alert>
          )}
        </div>

        {/* <h2 className="text-2xl font-semibold mb-4">
          {isSubscriptionActive ? 'Cambia Piano di Abbonamento' : 'Piani di Abbonamento Disponibili'}
        </h2> */}

        <Tabs value={selectedInterval} onValueChange={setSelectedInterval} className="mb-6">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="month">Mensile</TabsTrigger>
            <TabsTrigger value="quarter">Trimestrale</TabsTrigger>
            <TabsTrigger value="year">Annuale</TabsTrigger>
          </TabsList>
        </Tabs>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mb-2">
          {sortedPlans.map((plan) => {
            const normalizedPlanName = plan.name.toLowerCase().replace(/\s+/g, '');
            const currentPlanName = currentSubscription?.planType.toLowerCase().replace(/\s+/g, '');
            const isPlanActive = normalizedPlanName === currentPlanName;

            return (
              <PricingCard
                key={plan.id}
                plan={plan}
                selectedInterval={selectedInterval}
                onAction={isSubscriptionActive ? handleChangePlan : handleSubscribe}
                status={status}
                isPlanActive={isPlanActive}
                isSubscriptionActive={isSubscriptionActive}
                actionLoading={actionLoading}
              />
            );
          })}
        </div>
        {renderSubscriptionDetails()}
        <div className="xl:col-span-3">
          {/* Usage Section */}
          {renderUsageSection()}
        </div>

        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Conferma Cancellazione</DialogTitle>
              <DialogDescription>
                Sei sicuro di voler cancellare l'abbonamento?
                {status?.endDate && (
                  <p className="mt-2">
                    Il tuo abbonamento rimarrà attivo fino al {status.endDate.toLocaleDateString()}.
                    Potrai continuare ad utilizzare tutti i servizi fino a tale data.
                  </p>
                )}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                disabled={actionLoading}
              >
                Annulla
              </Button>
              <Button
                variant="destructive"
                onClick={confirmAction}
                disabled={actionLoading}
              >
                {actionLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                Conferma Cancellazione
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {modalAction === 'cancel' ? 'Conferma Cancellazione' : 'Conferma Reset'}
              </DialogTitle>
              <DialogDescription>
                {modalAction === 'cancel' ? (
                  <>
                    Sei sicuro di voler cancellare l'abbonamento?
                    {status?.endDate && (
                      <p className="mt-2">
                        Il tuo abbonamento rimarrà attivo fino al {status.endDate.toLocaleDateString()}.
                        Potrai continuare ad utilizzare tutti i servizi fino a tale data.
                      </p>
                    )}
                  </>
                ) : (
                  'Sei sicuro di voler resettare completamente labbonamento? Questa azione non può essere annullata e rimuoverà immediatamente tutti i dati dellabbonamento.'
                )}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
                disabled={actionLoading}
              >
                Annulla
              </Button>
              <Button
                variant={modalAction === 'cancel' ? "destructive" : "default"}
                onClick={confirmAction}
                disabled={actionLoading}
              >
                {actionLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                {modalAction === 'cancel' ? 'Conferma Cancellazione' : 'Conferma Reset'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

export default SubscriptionPage;