import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "../../ui/card";
import { Switch } from "../../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Button } from "../../ui/button";
import { Moon, Sun } from 'lucide-react';

const TABS = [
    { name: "Preferenze", route: "/impostazioni" },
    { name: "Turni di lavoro", route: "/settings" },
    { name: "Notifiche", route: "/notifications" },
    { name: "Codice Invito", route: "/invitation-code" }
];

function PreferencesTab() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("/impostazioni");

    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [language, setLanguage] = useState('it');
    const [theme, setTheme] = useState('light');
    const [timezone, setTimezone] = useState('europe/rome');

    useEffect(() => {
        const currentTab = TABS.find(tab => location.pathname.startsWith(tab.route));
        if (currentTab) {
            setActiveTab(currentTab.route);
        }
    }, [location]);

    const handleTabChange = (value) => {
        navigate(value);
    };

    return (
        <div className=" p-6">
            <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
                <TabsList className="bg-[#06052C] rounded-none w-full flex justify-start h-auto">
                    {TABS.map((tab) => (
                        <TabsTrigger
                            key={tab.name}
                            value={tab.route}
                            className="py-2 px-4 text-white data-[state=active]:bg-white data-[state=active]:text-blue-950"
                        >
                            {tab.name}
                        </TabsTrigger>
                    ))}
                </TabsList>
            </Tabs>

            <Card className="mt-6">
                <CardHeader>
                    <CardTitle>Preferenze Utente</CardTitle>
                    <CardDescription>Personalizza la tua esperienza sull'applicazione.</CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">

                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Lingua</h4>
                            <p className="text-sm text-gray-500">Seleziona la lingua dell'interfaccia</p>
                        </div>
                        {/*<Select value={language} onValueChange={setLanguage}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Seleziona lingua" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="it">Italiano</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                                <SelectItem value="es">Español</SelectItem>
                            </SelectContent>
                        </Select>*/}
                        <Button disabled variant='outline'>
                            Funzionalità in arrivo
                        </Button>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Tema</h4>
                            <p className="text-sm text-gray-500">Scegli tra tema chiaro e scuro</p>
                        </div>
                        {/*<Select value={theme} onValueChange={setTheme}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Seleziona tema" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="light">
                                    <div className="flex items-center">
                                        <Sun className="mr-2 h-4 w-4" />
                                        Chiaro
                                    </div>
                                </SelectItem>
                                <SelectItem value="dark">
                                    <div className="flex items-center">
                                        <Moon className="mr-2 h-4 w-4" />
                                        Scuro
                                    </div>
                                </SelectItem>
                            </SelectContent>
                        </Select>*/}
                        <Button disabled variant='outline'>
                            Funzionalità in arrivo
                        </Button>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            <h4 className="text-lg font-semibold">Timezone</h4>
                            <p className="text-sm text-gray-500">Imposta il tuo fuso orario</p>
                        </div>
                        {/* <Select value={timezone} onValueChange={setTimezone}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Seleziona timezone" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="europe/rome">Europe/Rome</SelectItem>
                                <SelectItem value="europe/london">Europe/London</SelectItem>
                                <SelectItem value="america/new_york">America/New York</SelectItem>
                            </SelectContent>
                        </Select>*/}
                        <Button disabled variant='outline'>
                            Funzionalità in arrivo
                        </Button>
                    </div>
                </CardContent>
                <CardFooter>
                    {/*<Button className="ml-auto" disabled>Salva Preferenze</Button>*/}
                    <Button disabled variant='outline'>
                        Funzionalità in arrivo
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default PreferencesTab;