import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '../ui/button';
import UserDropdown from '../admin/sharedComponent/UserDropdown';
import logo from "../../assest/logo2.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("breadcrumb");
    window.location.href = "/";
  };

  return (
    <div className="sticky top-0 z-50 w-full bg-white border-b">
      <nav className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  className="h-7 w-auto"
                  src={logo}
                  alt="Logo"
                />
              </motion.div>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:justify-center flex-1 px-8">
            <div className="flex space-x-8">
              <Link
                to="/"
                className="text-gray-700 hover:text-blue-500"
              >
                Homepage
              </Link>
              <Link
                to="/pricing"
                className="text-gray-700 hover:text-blue-500"
              >
                Prezzi
              </Link>
              <Link
                to="https://glds.it"
                target='_blank'
                className="text-gray-700 hover:text-blue-500"
              >
                Contattaci
              </Link>
            </div>
          </div>

          {/* Authentication Buttons */}
          <div className="hidden lg:flex lg:items-center gap-x-4">
            {user ? (
              <UserDropdown user={user} logoutHandle={logoutHandle} />
            ) : (
              <>
                <Link to="/login">
                  <Button variant='outline'>Login</Button>
                </Link>
                <Link to="/register">
                  <Button>Inizia</Button>
                </Link>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-500 hover:text-gray-600 focus:outline-none"
              aria-label="toggle menu"
            >
              {!isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`${isOpen ? 'block' : 'hidden'} lg:hidden py-4`}>
          <div className="flex flex-col space-y-4">
            <Link
              to="/pricing"
              className="text-gray-700 hover:text-blue-500"
            >
              Pricing
            </Link>
            <Link
              to="https://glds.it"
              target='_blank'
              className="text-gray-700 hover:text-blue-500"
            >
              Contattaci
            </Link>
            {user ? (
              <div className="pt-2">
                <UserDropdown user={user} logoutHandle={logoutHandle} />
              </div>
            ) : (
              <div className="flex flex-col space-y-2 pt-2">
                <Link to="/login">
                  <Button variant='outline' className="w-full">Login</Button>
                </Link>
                <Link to="/register">
                  <Button className="w-full">Inizia</Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;