import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { AddEmployee, singleEmployee, updateEmployee } from "../../../../../../apis/Employees"
import { toast } from "react-toastify"
import { Button } from "../../../../../ui/button"
import { Card } from "../../../../../ui/card"
import { Label } from "../../../../../ui/label"
import { Input } from "../../../../../ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../../ui/select"
import { Menu } from "lucide-react"
import ImageController from "../../../../sharedComponent/ImageController"
import {
  ADD_EMPLOYEE_INFO_FIELDS,
  ADD_EMPLOYEE_REMUNERATION,
  QUALIFICATION_LEVELS,
} from "../../../../../../constants/formFields"
import EmployeeLimitModal from "../../components/EmployeeLimitModal"
import { useSubscription } from "../../../../../../screens/auth/SubscriptionContext"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../../ui/tabs"
import { Sheet, SheetContent, SheetTrigger } from "../../../../../ui/sheet"

const EmployeeComponent = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("personal")
  const [employeeData, setEmployeeData] = useState()
  const { control, handleSubmit, reset, setValue, watch, setError } = useForm({
    defaultValues: {
      freshman: "",
    },
  })
  const [showLimitModal, setShowLimitModal] = useState(false)
  const [employeeStats, setEmployeeStats] = useState(null)
  const { subscription } = useSubscription()
  const user = JSON.parse(localStorage.getItem("user") || "{}")
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const imagePreview = watch("file")

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setValue("file", file)
    }
  }

  const validateMatricola = async (value) => {
    if (!value) return true
    if (!/^\d+$/.test(value)) {
      return "La matricola deve contenere solo numeri"
    }
    return true
  }

  useEffect(() => {
    if (id) {
      getEmployee()
    }
  }, [id, reset])

  const getEmployee = async () => {
    try {
      const response = await singleEmployee(id)
      if (response.status === 200) {
        setEmployeeData(response.data?.employee)
        reset(response.data?.employee)
      }
    } catch (error) {
      console.error(error)
      toast.error("Error fetching employee data")
      navigate("/employees/all-employees")
    }
  }

  const onSubmit = async (data) => {
    const sanitizePayload = (payload) => {
      const sanitized = {}
      Object.keys(payload).forEach((key) => {
        if (payload[key] !== undefined && payload[key] !== "" && payload[key] !== null) {
          sanitized[key] = payload[key]
        }
      })
      return sanitized
    }

    const sanitizedData = sanitizePayload(data)

    try {
      if (id) {
        const response = await updateEmployee(id, sanitizedData)
        if (response.status === 200) {
          toast.success("Dipendente aggiornato")
          navigate("/employees/all-employees")
          reset()
        }
      } else {
        const response = await AddEmployee(sanitizedData)
        if (response.status === 201) {
          toast.success("Dipendente aggiunto")
          navigate("/employees/all-employees")
          reset()
        }
      }
    } catch (error) {
      if (error.error === "DUPLICATE_MATRICOLA") {
        setError("freshman", {
          type: "manual",
          message: "Matricola già in uso",
        })
        toast.error("Matricola già in uso")
      } else if (error.error === "Employee limit reached") {
        const planLimits = {
          basic: 5,
          standard: 20,
          professional: 50,
          enterprise: Number.POSITIVE_INFINITY,
        }

        const basePlanType = subscription?.planType?.toLowerCase().includes("basic")
          ? "basic"
          : subscription?.planType?.toLowerCase().includes("standard")
            ? "standard"
            : subscription?.planType?.toLowerCase().includes("professional")
              ? "professional"
              : subscription?.planType?.toLowerCase().includes("enterprise")
                ? "enterprise"
                : "basic"

        const limit = planLimits[basePlanType] || 5

        setEmployeeStats({
          currentCount: error.currentCount || 0,
          limit: limit,
        })
        setShowLimitModal(true)
      } else {
        toast.error(error.message || "Errore durante il salvataggio")
      }
    }
  }

  const renderPersonalDataTab = () => (
    <div className="space-y-6">
      <form className="space-y-6">
        {/* Personal Information Card */}
        <Card className="p-4 sm:p-6">
          <h2 className="text-lg font-semibold mb-4">Dati Personali</h2>
          <div className="flex justify-start mb-6">
            <ImageController
              handleImageChange={handleImageChange}
              label=""
              name="file"
              control={control}
              rounded="rounded-full"
              defaultValue={employeeData?.image ? `${process.env.REACT_APP_BACKEND_URL}/${employeeData.image}` : ""}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {ADD_EMPLOYEE_INFO_FIELDS.map((field) => (
              <div key={field.name} className="space-y-2">
                <Label htmlFor={field.name}>{field.label}</Label>
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    field.type === "select" ? (
                      <Select onValueChange={onChange} value={value || ""}>
                        <SelectTrigger>
                          <SelectValue placeholder={`Select ${field.label}`} />
                        </SelectTrigger>
                        <SelectContent>
                          {field.options?.map((option) => (
                            <SelectItem key={option.key} value={option.key}>
                              {option.value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : (
                      <Input
                        type={field.type}
                        id={field.name}
                        placeholder={field.placeholder}
                        onChange={onChange}
                        value={value || ""}
                      />
                    )
                  }
                />
              </div>
            ))}
          </div>
        </Card>

        {/* Contract Information Card */}
        <Card className="p-4 sm:p-6">
          <h2 className="text-lg font-semibold mb-4">Contratto</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Contract Type */}
            <div className="space-y-2">
              <Label htmlFor="guy">Tipo</Label>
              <Controller
                name="guy"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onValueChange={(val) => {
                      onChange(val)
                      if (val === "undetermined") {
                        setValue("end", "")
                      }
                    }}
                    value={value || ""}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select contract type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="determined">A tempo determinato</SelectItem>
                      <SelectItem value="undetermined">A tempo indeterminato</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            {/* Matricola */}
            <div className="space-y-2">
              <Label htmlFor="freshman">Matricola</Label>
              <Controller
                name="freshman"
                control={control}
                rules={{ validate: validateMatricola }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Assegnazione automatica se vuoto"
                      className={error ? "border-red-500" : ""}
                    />
                    {error && <p className="text-red-500 text-xs italic mt-1">{error.message}</p>}
                  </div>
                )}
              />
            </div>

            {/* Start Date */}
            <div className="space-y-2">
              <Label htmlFor="start">Inizio contratto</Label>
              <Controller
                name="start"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="date" onChange={onChange} value={value || ""} />
                )}
              />
            </div>

            {/* End Date */}
            <div className="space-y-2">
              <Label htmlFor="end">Fine contratto</Label>
              <Controller
                name="end"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="date"
                    onChange={onChange}
                    value={value || ""}
                    disabled={watch("guy") === "undetermined"}
                    className={watch("guy") === "undetermined" ? "bg-gray-100 cursor-not-allowed" : ""}
                  />
                )}
              />
            </div>

            {/* Task */}
            <div className="space-y-2">
              <Label htmlFor="task">Mansione</Label>
              <Controller name="task" control={control} render={({ field }) => <Input {...field} type="text" />} />
            </div>

            {/* Qualification */}
            <div className="space-y-2">
              <Label htmlFor="qualification">Qualifica</Label>
              <Controller
                name="qualification"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onValueChange={(val) => {
                      onChange(val)
                      const selectedQualification = QUALIFICATION_LEVELS.find((q) => q.key === val)
                      if (selectedQualification) {
                        setValue("level", selectedQualification.level)
                      }
                    }}
                    value={value || ""}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select qualification" />
                    </SelectTrigger>
                    <SelectContent>
                      {QUALIFICATION_LEVELS.map((level) => (
                        <SelectItem key={level.key} value={level.key}>
                          {level.value}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            {/* Level */}
            <div className="space-y-2">
              <Label htmlFor="level">Livello</Label>
              <Controller
                name="level"
                control={control}
                render={({ field }) => <Input {...field} type="text" readOnly className="bg-gray-100" />}
              />
            </div>

            {/* Work Time Type */}
            <div className="space-y-2">
              <Label htmlFor="timeType">Tipologia di orario</Label>
              <Controller
                name="timeType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select onValueChange={onChange} value={value || ""}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select time type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="full-time">Full-time</SelectItem>
                      <SelectItem value="part-time">Part-time</SelectItem>
                      <SelectItem value="mixed">Mixed</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            {/* Weekly Hours */}
            <div className="space-y-2">
              <Label htmlFor="weeklyWorkingHours">Ore di lavoro settimanali</Label>
              <Controller
                name="weeklyWorkingHours"
                control={control}
                render={({ field }) => <Input {...field} type="number" min="0" />}
              />
            </div>

            {/* Monthly Hours */}
            <div className="space-y-2">
              <Label htmlFor="monthlyWorkingHours">Ore di lavoro mensili</Label>
              <Controller
                name="monthlyWorkingHours"
                control={control}
                render={({ field }) => <Input {...field} type="number" min="0" />}
              />
            </div>

            {/* Annual Hours */}
            <div className="space-y-2">
              <Label htmlFor="annualWorkingHours">Ore di lavoro annuali</Label>
              <Controller
                name="annualWorkingHours"
                control={control}
                render={({ field }) => <Input {...field} type="number" min="0" />}
              />
            </div>

            {/* Vacation Hours */}
            <div className="space-y-2">
              <Label htmlFor="maxVacationHours">Ore di ferie annuali</Label>
              <Controller
                name="maxVacationHours"
                control={control}
                render={({ field }) => <Input {...field} type="number" min="0" />}
              />
            </div>

            {/* ROL Hours */}
            <div className="space-y-2">
              <Label htmlFor="maxROLHours">Ore di permessi ROL annuali</Label>
              <Controller
                name="maxROLHours"
                control={control}
                render={({ field }) => <Input {...field} type="number" min="0" />}
              />
            </div>
          </div>
        </Card>

        {/* Remuneration Card */}
        <Card className="p-4 sm:p-6">
          <h2 className="text-lg font-semibold mb-4">Retribuzione</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {ADD_EMPLOYEE_REMUNERATION.map((field) => (
              <div key={field.name} className="space-y-2">
                <Label htmlFor={field.name}>{field.label}</Label>
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    field.type === "select" ? (
                      <Select onValueChange={onChange} value={value || ""}>
                        <SelectTrigger>
                          <SelectValue placeholder={`Select ${field.label}`} />
                        </SelectTrigger>
                        <SelectContent>
                          {field.options?.map((option) => (
                            <SelectItem key={option.key} value={option.key}>
                              {option.value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : field.type === "time" ? (
                      <div className="flex gap-2">
                        {field.times.map((time) => (
                          <Input
                            key={`${field.name}${time.key}`}
                            type="time"
                            onChange={(e) => onChange(e.target.value)}
                            value={value || ""}
                          />
                        ))}
                      </div>
                    ) : (
                      <Input
                        type={field.type}
                        id={field.name}
                        placeholder={field.placeholder}
                        onChange={onChange}
                        value={value || ""}
                      />
                    )
                  }
                />
              </div>
            ))}
          </div>
        </Card>
      </form>
      <div className="flex justify-between items-center">
        <p></p>
        <div className="flex space-x-2">
          <Button
            variant="destructive"
            onClick={() => {
              navigate("/employees/all-employees")
              reset()
            }}
          >
            Annulla
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>{id ? "Aggiorna" : "Salva"}</Button>
        </div>
      </div>
    </div>
  )

  const tabs = [{ id: "personal", label: "Dati Personali" }]

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
      <Tabs
        orientation="vertical"
        value={activeTab}
        onValueChange={setActiveTab}
        className="flex flex-col lg:flex-row w-full"
      >
        {/* Mobile menu button */}
        <div className="lg:hidden p-4 bg-white border-b">
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetTrigger asChild>
              <Button variant="outline" className="w-full flex justify-between items-center">
                <span>{tabs.find((tab) => tab.id === activeTab)?.label}</span>
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[240px] sm:w-[280px]">
              <nav className="flex flex-col space-y-2">
                {tabs.map((tab) => (
                  <Button
                    key={tab.id}
                    variant={activeTab === tab.id ? "default" : "ghost"}
                    className="justify-start"
                    onClick={() => {
                      setActiveTab(tab.id)
                      setIsMobileMenuOpen(false)
                    }}
                  >
                    {tab.label}
                  </Button>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
        </div>

        {/* Left sidebar with tab triggers (hidden on mobile) */}
        <div className="hidden lg:block w-48 lg:h-screen border-r ">
          <div className="p-4 sticky top-0  z-10">
            <TabsList className="flex flex-col justify-start bg-transparent space-y-2 text-start ">
              {tabs.map((tab) => (
                <TabsTrigger
                  key={tab.id}
                  value={tab.id}
                  className="w-full text-left py-1 border-r-2 border-transparent data-[state=active]:border-primary  rounded-none "
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
        </div>

        {/* Main content area */}
        <div className="flex-1 px-4 overflow-y-auto">
          <TabsContent value="personal">{renderPersonalDataTab()}</TabsContent>
        </div>
      </Tabs>

      <EmployeeLimitModal
        isOpen={showLimitModal}
        onClose={() => {
          setShowLimitModal(false)
          navigate("/employees/all-employees")
        }}
        currentCount={employeeStats?.currentCount}
        limit={employeeStats?.limit}
        planType={subscription?.planType}
        isAdmin={user.role === "admin"}
      />
    </div>
  )
}

export default EmployeeComponent

