import React from "react";
import moment from "moment";
import { X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";

const EventModal = ({
  modalIsOpen,
  closeModal,
  selectedEvent,
  setSelectedEvent,
  handleEventSubmit,
  handleDeleteEvent,
  selectedEventId,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    handleEventSubmit();
  };

  return (
    <Dialog open={modalIsOpen} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>
            {selectedEventId ? "Modifica evento" : "Aggiungi evento"}
          </DialogTitle>
          {/* <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4"
            onClick={closeModal}
          >
            <X className="h-4 w-4" />
          </Button> */}
        </DialogHeader>
        <form onSubmit={onSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="title">Nome evento</Label>
            <Input
              required
              type="text"
              id="title"
              value={selectedEvent?.title || ""}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  title: e.target.value,
                })
              }
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="start">Data ed ora inizio</Label>
            <Input
              required
              type="datetime-local"
              id="start"
              value={moment(selectedEvent?.start).format("YYYY-MM-DDTHH:mm")}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  start: new Date(e.target.value),
                })
              }
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="end">Data ed ora fine</Label>
            <Input
              required
              type="datetime-local"
              id="end"
              value={moment(selectedEvent?.end).format("YYYY-MM-DDTHH:mm")}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  end: new Date(e.target.value),
                })
              }
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Descrizione</Label>
            <Textarea
              required
              id="description"
              value={selectedEvent?.description || ""}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  description: e.target.value,
                })
              }
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="color">Colore evento</Label>
            <div className="flex w-full">
              <Input
                type="color"
                id="color"
                className="h-9 w-full"
                value={selectedEvent?.color || "#000000"}
                onChange={(e) =>
                  setSelectedEvent({
                    ...selectedEvent,
                    color: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            {selectedEventId && (
              <Button
                type="button"
                variant="destructive"
                onClick={() => handleDeleteEvent()}
              >
                Delete
              </Button>
            )}
            <Button type="submit">
              {selectedEventId ? "Salva" : "Aggiungi"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EventModal;