import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSingleClient } from '../../../../apis/client';
import { Card, CardContent } from '../../../ui/card';
import { Label } from '../../../ui/label';
import { Button } from '../../../ui/button';
import { ArrowLeft } from 'lucide-react';
import { toast } from 'react-toastify';

const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await getSingleClient(id);
        if (response.status === 200) {
          setClient(response.data.client);
        }
      } catch (error) {
        toast.error('Errore nel caricamento dei dati del cliente');
      }
    };

    if (id) {
      fetchClientData();
    }
  }, [id]);

  if (!client) return null;

  const DetailsSection = ({ label, value }) => (
    <div className="flex flex-col">
      <Label className="text-xs text-muted-foreground">{label}</Label>
      <span className="text-sm font-medium">{value || 'N/A'}</span>
    </div>
  );

  return (
    <div className="px-2 py-4">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => navigate('/clients')}
            className="flex items-center gap-2"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-2xl font-bold">
            {client.fullName || 'Dettagli Cliente'}
          </h1>
        </div>
      </div>

      <Card className="shadow-none border-0 px-0">
        <CardContent>
          <div className="grid grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="grid grid-rows-2 gap-6">
              {/* Personal Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Dati Personali</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-3 gap-4">
                    <DetailsSection label="Tipologia" value={client.typology} />
                    <DetailsSection label="Nome/Ragione Sociale" value={client.fullName} />
                    <DetailsSection label="Partita IVA" value={client.vatNumber} />
                    <DetailsSection label="Codice Fiscale" value={client.taxId} />
                    <DetailsSection label="Codice Univoco" value={client.billingCode} />
                  </div>
                </div>
              </div>

              {/* Contact Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Contatti</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-3 gap-4">
                    <DetailsSection label="Email" value={client.email} />
                    <DetailsSection label="PEC" value={client.pec} />
                    <DetailsSection label="Telefono" value={client.telephone} />
                    <DetailsSection label="Cellulare" value={client.mobilePhone} />
                    <DetailsSection label="Sito Web" value={client.website} />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="grid grid-rows-2 gap-6">
              {/* Address Details */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Indirizzo</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <div className="grid grid-cols-3 gap-4">
                    <DetailsSection label="Indirizzo" value={client.address} />
                    <DetailsSection label="Città" value={client.city} />
                    <DetailsSection label="CAP" value={client.postalCode} />
                    <DetailsSection label="Provincia" value={client.province} />
                    <DetailsSection label="Nazione" value={client.nation} />
                  </div>
                </div>
              </div>

              {/* Notes */}
              <div className="bg-card rounded-lg relative">
                <div className="absolute -top-3 left-4 px-2 bg-background border rounded-xl">
                  <h3 className="text-lg font-semibold">Note</h3>
                </div>
                <div className="border rounded-lg p-6 h-full">
                  <p className="text-sm">{client.note || 'Nessuna nota disponibile'}</p>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientDetailsPage;