import React from 'react';
import { format, isSameDay, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, subMonths } from 'date-fns';
import { it } from 'date-fns/locale';
import { cn } from "../../../../lib/utils";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "../../../ui/button";

const WorkJournalCalendar = ({
  selectedDate,
  onDateSelect,
  entries = [],
  className
}) => {
  const [currentMonth, setCurrentMonth] = React.useState(startOfMonth(selectedDate));
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const hasEntry = (date) => {
    return entries.some(entry => {
      const entryDate = new Date(entry.date);
      return isSameDay(entryDate, date);
    });
  };

  const handleDateSelect = (date) => {
    onDateSelect(date); // No need to convert to UTC here
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(prevMonth => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, 1));
  };

  return (
    <div className="flex gap-3">
      <div className={cn("p-3 w-fit bg-white rounded-lg shadow-sm", className)}>
        <div className="flex items-center justify-between mb-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={handlePreviousMonth}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <h2 className="font-semibold">
            {format(currentMonth, 'MMMM yyyy', { locale: it })}
          </h2>
          <Button
            variant="ghost"
            size="icon"
            onClick={handleNextMonth}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        <div className="grid grid-cols-7 gap-1">
          {['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'].map((day) => (
            <div
              key={day}
              className="w-10 h-9 flex items-center justify-center text-sm font-medium text-gray-500"
            >
              {day}
            </div>
          ))}
          {days.map((day) => {
            const hasData = hasEntry(day);
            return (
              <button
                key={day.toString()}
                type="button"
                onClick={() => handleDateSelect(day)}
                className={cn(
                  "w-10 h-9 relative flex items-center justify-center text-sm rounded-full",
                  isSameDay(day, selectedDate) && "bg-blue-100",
                  "hover:bg-gray-100"
                )}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
                <span
                  className={cn(
                    "absolute bottom-1 left-1/2 -translate-x-1/2 w-1.5 h-1.5 rounded-full",
                    hasData
                      ? "bg-blue-400"
                      : "border border-gray-400 bg-transparent"
                  )}
                />
              </button>
            );
          })}
        </div>
      </div>

      <div className="p-2 w-48 bg-white rounded-lg shadow-sm h-fit">
        <h3 className="font-semibold mb-3">Legenda</h3>
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <span className="w-1.5 h-1.5 rounded-full bg-blue-400" />
            <span className="text-sm">Giornata registrata</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="w-1.5 h-1.5 rounded-full border border-gray-400" />
            <span className="text-sm">Giornata non registrata</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkJournalCalendar;