import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { MdDashboard, MdConstruction, MdChat, MdChecklist } from "react-icons/md";
import {
  FaMagento, FaCalendarAlt, FaCalculator, FaTruck, FaHome, FaCar,
  FaBox, FaUsers, FaHandshake, FaIndustry,
  FaFileInvoiceDollar
} from "react-icons/fa";
import { IoSettings, IoLogOut } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { PiUsersThreeFill } from "react-icons/pi";
import { Archive, HandCoins, Menu, FileText } from "lucide-react";
import logo from "../../../assest/logo1.png";

const styles = {
  text: {
    color: "rgb(201,201,201)"
  }
};

const SidebarContent = ({ isActive, linkClass, dropdownStates, toggleDropdown, updateBreadcrumbs, isMobile }) => (
  <>
    <div className={`${isMobile ? 'mb-6' : ''}`}>
      <Link to='/'>
        <img src={logo} alt="logo" className="w-full" />
      </Link>
    </div>
    <ul className="space-y-2 font-medium mt-3">
      <li>
        <Link
          to="/dashboard"
          className={linkClass("/dashboard")}
          onClick={() => updateBreadcrumbs({ path: '/dashboard', label: 'Dashboard' })}
        >
          <MdDashboard size={18} />
          <span className="ms-3">Dashboard</span>
        </Link>
      </li>
      <li>
        <Link
          to="/calendar"
          className={linkClass("/calendar")}
          onClick={() => updateBreadcrumbs({ path: '/calendar', label: 'Calendario' })}
        >
          <FaCalendarAlt size={18} />
          <span className="ms-3 whitespace-nowrap">Calendario</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>Organizzazione</h4>
      <li>
        <Link
          to="/agency/information"
          className={linkClass("/agency/information")}
          onClick={() => updateBreadcrumbs({ path: '/agency/information', label: 'Azienda' })}
        >
          <FaMagento size={18} />
          <span className="ms-3 whitespace-nowrap">Azienda</span>
        </Link>
      </li>
      <li>
        <button
          type="button"
          className={`flex items-center w-full p-2 text-base ${isActive("/employees") || isActive("/clients") || isActive("/suppliers")
            ? "bg-gray-100 text-blue-950"
            : "text-white hover:bg-gray-100 hover:text-blue-950"
            } transition duration-75 rounded-lg group`}
          onClick={() => toggleDropdown("personalData", "Persone")}
        >
          <PiUsersThreeFill size={18} />
          <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
            Persone
          </span>
          <IoIosArrowDown />
        </button>
        {dropdownStates.personalData && (
          <ul className="py-2 space-y-2">
            <li>
              <Link
                to="/employees/all employees"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${isActive("/employees/all employees")
                  ? "bg-gray-100 text-blue-950"
                  : "text-white hover:bg-gray-100 hover:text-blue-950"
                  }`}
                onClick={() => updateBreadcrumbs({ path: '/employees/all employees', label: 'Dipendenti' })}
              >
                <FaUsers />
                <span>Dipendenti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/clients"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${isActive("/clients")
                  ? "bg-gray-100 text-blue-950"
                  : "text-white hover:bg-gray-100 hover:text-blue-950"
                  }`}
                onClick={() => updateBreadcrumbs({ path: '/clients', label: 'Cliente' })}
              >
                <FaHandshake />
                <span>Clienti</span>
              </Link>
            </li>
            <li>
              <Link
                to="/suppliers"
                className={`flex items-center w-full p-2 gap-3 transition duration-75 rounded-lg pl-6 group ${isActive("/suppliers")
                  ? "bg-gray-100 text-blue-950"
                  : "text-white hover:bg-gray-100 hover:text-blue-950"
                  }`}
                onClick={() => updateBreadcrumbs({ path: '/suppliers', label: 'Fornitori' })}
              >
                <FaIndustry />
                <span>Fornitori</span>
              </Link>
            </li>
          </ul>
        )}
      </li>
      <li>
        <Link
          to="/flotta"
          className={linkClass("/flotta")}
          onClick={() => updateBreadcrumbs({ path: '/flotta', label: 'Flotta' })}
        >
          <FaTruck size={18} />
          <span className="ms-3 whitespace-nowrap">Flotta</span>
        </Link>
      </li>
      <li>
        <Link
          to="/magazzino"
          className={linkClass("/magazzino")}
          onClick={() => updateBreadcrumbs({ path: '/magazzino', label: 'Magazzino' })}
        >
          <FaHome size={18} />
          <span className="ms-3 whitespace-nowrap">Magazzino</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>Contabilità</h4>
      <li>
        <Link
          to="/prima-nota"
          className={linkClass("/prima-nota")}
          onClick={() => updateBreadcrumbs({ path: '/prima-nota', label: 'Prima Nota' })}
        >
          <FileText size={18} />
          <span className="ms-3 whitespace-nowrap">Prima Nota</span>
        </Link>
      </li>
      <li>
        <Link
          to="/movimenti"
          className={linkClass("/movimenti")}
          onClick={() => updateBreadcrumbs({ path: '/movimenti', label: 'Movimenti' })}
        >
          <HandCoins size={18} />
          <span className="ms-3 whitespace-nowrap">Movimenti</span>
        </Link>
      </li>
      <li>
        <Link
          to="/tax"
          className={linkClass("/tax")}
          onClick={() => updateBreadcrumbs({ path: '/tax', label: 'Tasse' })}
        >
          <HandCoins size={18} />
          <span className="ms-3 whitespace-nowrap">Tasse</span>
        </Link>
      </li>
      <li>
        <Link
          to="/installment"
          className={linkClass("/installment")}
          onClick={() => updateBreadcrumbs({ path: '/installment', label: 'Rateizzi' })}
        >
          <FaFileInvoiceDollar size={18} />
          <span className="ms-3 whitespace-nowrap">Rateizzi</span>
        </Link>
      </li>
      <li>
        <Link
          to="/ddt"
          className={linkClass("/ddt")}
          onClick={() => updateBreadcrumbs({ path: '/ddt', label: 'DDT' })}
        >
          <FaBox size={18} />
          <span className="ms-3 whitespace-nowrap">DDT</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>Produzione</h4>
      <li>
        <Link
          to="/computi-metrici"
          className={linkClass("/computi-metrici")}
          onClick={() => updateBreadcrumbs({ path: '/computi-metrici', label: 'Computi metrici' })}
        >
          <FaCalculator size={18} />
          <span className="ms-3 whitespace-nowrap">Computi Metrici</span>
        </Link>
      </li>
      <li>
        <Link
          to="/preventivi"
          className={linkClass("/preventivi")}
          onClick={() => updateBreadcrumbs({ path: '/preventivi', label: 'Preventivi' })}
        >
          <FaCalculator size={18} />
          <span className="ms-3 whitespace-nowrap">Preventivi</span>
        </Link>
      </li>
      <li>
        <Link
          to="/cantieri"
          className={linkClass("/cantieri")}
          onClick={() => updateBreadcrumbs({ path: '/cantieri', label: 'Cantieri' })}
        >
          <MdConstruction size={18} />
          <span className="ms-3 whitespace-nowrap">Cantieri</span>
        </Link>
      </li>
      <li>
        <Link
          to="/documenti"
          className={linkClass("/documenti")}
          onClick={() => updateBreadcrumbs({ path: '/documenti', label: 'Documenti' })}
        >
          <FileText size={18} />
          <span className="ms-3 whitespace-nowrap">Documenti</span>
        </Link>
      </li>
      <li>
        <Link
          to="/archive"
          className={linkClass("/archive")}
          onClick={() => updateBreadcrumbs({ path: '/archive', label: 'Archivio' })}
        >
          <Archive size={18} />
          <span className="ms-3 whitespace-nowrap">Archivio</span>
        </Link>
      </li>
      <h4 className="text-white pt-4" style={styles.text}>Comunicazione</h4>
      <li>
        <Link
          to="/chat"
          className={linkClass("/chat")}
          onClick={() => updateBreadcrumbs({ path: '/chat', label: 'Chat' })}
        >
          <MdChat size={18} />
          <span className="ms-3 whitespace-nowrap">Chat</span>
        </Link>
      </li>
    </ul>
  </>
);

const ResponsiveSidebar = ({ updateBreadcrumbs }) => {
  const location = useLocation();
  const [dropdownStates, setDropdownStates] = useState({});

  const isActive = (path) => location.pathname === path;

  const toggleDropdown = (menu, label) => {
    setDropdownStates(prevState => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
    if (!dropdownStates[menu]) {
      updateBreadcrumbs({ path: '', label });
    }
  };

  const linkClass = (path) => `flex items-center p-2 rounded-lg ${isActive(path)
    ? "bg-gray-100 text-blue-950"
    : "text-white hover:bg-gray-100 hover:text-blue-950"
    } transition-colors duration-200`;

  return (
    <div className="hidden lg:block">
      <aside
        className="fixed top-0 left-0 z-40 w-64 h-screen"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-[#06052C]">
          <SidebarContent
            isActive={isActive}
            linkClass={linkClass}
            dropdownStates={dropdownStates}
            toggleDropdown={toggleDropdown}
            updateBreadcrumbs={updateBreadcrumbs}
            isMobile={false}
          />
        </div>
      </aside>
    </div>
  );
};

export default ResponsiveSidebar;