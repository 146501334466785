import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui/button';
import { ScrollArea } from '../../ui/scroll-area';
import { FileText, Plus, Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../ui/table';
import { toast } from 'react-toastify';
import { 
  getUserDocuments, 
  getDocumentTemplates, 
  createDocument 
} from '../../../apis/Documents';
import { getConstructionSites } from '../../../apis/ConstructionSite';

export default function UserDocuments() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [selectedSite, setSelectedSite] = useState('');
  const [templates, setTemplates] = useState([]);
  const [sites, setSites] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.companyId) {
        toast.error('Informazioni utente non trovate');
        navigate('/login');
        return;
      }
  
      const [templatesData, sitesResponse, documentsData] = await Promise.all([
        getDocumentTemplates(),
        getConstructionSites(user.companyId),
        getUserDocuments()
      ]);
  
      setTemplates(templatesData);
      setSites(sitesResponse.data.sites || []);
      setUserDocuments(documentsData || []);
    } catch (error) {
      toast.error(error.message || 'Errore nel caricamento dei dati');
      console.error('Error loading initial data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async () => {
    if (!selectedTemplate || !documentName || !selectedSite) {
      toast.error('Compila tutti i campi richiesti');
      return;
    }
  
    try {
      setIsCreating(true);
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.companyId) {
        toast.error('Informazioni utente non trovate');
        navigate('/login');
        return;
      }
  
      const data = await createDocument({
        templateId: selectedTemplate,
        name: documentName,
        constructionSiteId: selectedSite,
        status: 'draft'
      });
      
      toast.success('Documento creato con successo');
      setIsCreateModalOpen(false);
      resetForm();
      navigate(`/documenti/${data._id}/edit`);
    } catch (error) {
      toast.error(error.message || 'Errore nella creazione del documento');
      console.error('Error creating document:', error);
    } finally {
      setIsCreating(false);
    }
  };  

  const resetForm = () => {
    setDocumentName('');
    setSelectedTemplate('');
    setSelectedSite('');
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className=" mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">I miei documenti</h1>
        <Button onClick={() => setIsCreateModalOpen(true)}>
          Crea documento
        </Button>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nome documento</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead>Cantiere</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead>Data creazione</TableHead>
              <TableHead>Azioni</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {userDocuments.map((doc) => (
              <TableRow key={doc._id}>
                <TableCell>{doc.name}</TableCell>
                <TableCell>{doc.type}</TableCell>
                <TableCell>{doc.constructionSite?.name}</TableCell>
                <TableCell>
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    doc.status === 'draft' 
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-green-100 text-green-800'
                  }`}>
                    {doc.status === 'draft' ? 'Bozza' : 'Completato'}
                  </span>
                </TableCell>
                <TableCell>{new Date(doc.createdAt).toLocaleDateString('it-IT')}</TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => navigate(`/documenti/${doc._id}/edit`)}
                  >
                    <FileText className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {userDocuments.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-4">
                  Nessun documento trovato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isCreateModalOpen} onOpenChange={(open) => {
        if (!open) resetForm();
        setIsCreateModalOpen(open);
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Crea nuovo documento</DialogTitle>
            <DialogDescription>
              Seleziona il tipo di documento e il cantiere per iniziare
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="documentName">Nome documento</Label>
              <Input
                id="documentName"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Inserisci il nome del documento"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="template">Tipo documento</Label>
              <Select onValueChange={setSelectedTemplate} value={selectedTemplate}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona tipo documento" />
                </SelectTrigger>
                <SelectContent>
                  {templates.map((template) => (
                    <SelectItem key={template._id} value={template._id}>
                      {template.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="site">Cantiere</Label>
              <Select onValueChange={setSelectedSite} value={selectedSite}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona cantiere" />
                </SelectTrigger>
                <SelectContent>
                  {sites.map((site) => (
                    <SelectItem key={site._id} value={site._id}>
                      {site.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setIsCreateModalOpen(false)}>
              Annulla
            </Button>
            <Button 
              onClick={handleCreateDocument}
              disabled={isCreating}
            >
              {isCreating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Creazione...
                </>
              ) : (
                'Genera documento'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}