import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resendVerification } from '../../apis/Auth';
import { toast } from 'react-toastify';

const UnverifiedEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(location.state?.email || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleResend = async () => {
    setIsLoading(true);
    try {
      await resendVerification(email);
      toast.success('Verification email resent successfully!');
    } catch (error) {
      toast.error('Error resending verification email');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-950 to-blue-800 p-4">
      <div className="max-w-md w-full bg-white rounded-lg p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-6">
          Email Not Verified
        </h2>
        <div className="text-center space-y-4">
          <p className="text-gray-600">
            Please verify your email address to continue. We've sent a verification link to
            <span className="font-semibold"> {email}</span>.
          </p>
          
          <div className="space-y-4">
            <button
              onClick={handleResend}
              disabled={isLoading}
              className="h-12 p-2 w-full rounded-full text-normal bg-blue-600 hover:bg-blue-700 text-white disabled:opacity-50"
            >
              {isLoading ? 'Sending...' : 'Resend Verification Email'}
            </button>
            
            <button
              onClick={() => navigate('/login')}
              className="text-blue-600 hover:underline"
            >
              Return to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnverifiedEmail;