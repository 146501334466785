import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createPrimaNota = async (data) => {
  try {
    const response = await axios.post('/prima-nota', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPrimaNotes = async (params) => {
  try {
    const response = await axios.get('/prima-nota', { params });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updatePrimaNota = async (id, data) => {
  try {
    const response = await axios.put(`/prima-nota/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deletePrimaNota = async (id) => {
  try {
    const response = await axios.delete(`/prima-nota/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateStatus = async (ids, status) => {
  try {
    const response = await axios.patch('/prima-nota/status', { ids, status });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getCategories = async () => {
  try {
    const response = await axios.get('/prima-nota/categories');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};