// apis/Documents.js
import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const getUserDocuments = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.companyId) {
      throw new Error('User information not found');
    }
    
    const response = await axios.get('/documents/user');
    return response.data;
  } catch (error) {
    console.error('Error in getUserDocuments:', error);
    throw error.response?.data || error;
  }
};

export const getDocumentTemplates = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.companyId) {
      throw new Error('User information not found');
    }

    const response = await axios.get('/documents/templates');
    return response.data;
  } catch (error) {
    console.error('Error in getDocumentTemplates:', error);
    throw error.response?.data || error;
  }
};

export const createDocument = async (data) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.companyId) {
      throw new Error('User information not found');
    }

    const response = await axios.post('/documents/generate', {
      ...data,
      companyId: user.companyId
    });
    return response.data;
  } catch (error) {
    console.error('Error in createDocument:', error);
    throw error.response?.data || error;
  }
};

export const deleteDocument = async (id) => {
  try {
    const response = await axios.delete(`/documents/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getDocument = async (id) => {
  try {
    const response = await axios.get(`/documents/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in getDocument:', error);
    throw error.response?.data || error;
  }
};

export const updateDocument = async (id, data) => {
  try {
    const response = await axios.put(`/documents/${id}`, {
      content: data.content,
      status: data.status,
      formData: data.formData
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const exportDocumentToPdf = async (id, formData) => {
  try {
    const response = await axios.post(
      `/documents/${id}/export`,
      { formData },
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Error in exportDocumentToPdf:', error);
    throw error.response?.data || error;
  }
};