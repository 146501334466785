import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { X } from "lucide-react";
import { format } from "date-fns";

import { Button } from "../../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";
import { AddLeave, UpdateLeave } from "../../../../../apis/leave";
import { markAttendance, updateAttendance } from "../../../../../apis/Attendence";

const AddAbsence = ({ 
  isOpen, 
  onClose, 
  setIsModalOpen, 
  employeeData, 
  selectedId, 
  SingleLeaveData, 
  dataUpdate, 
  setDataUpdate 
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user.companyId;

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: SingleLeaveData,
  });

  // Function to create attendance records for each day in the absence period
  const createAttendanceRecords = async (data, employeeId) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const attendancePayload = {
        employeeId: employeeId,
        date: format(currentDate, "dd-MM-yyyy"),
        attendanceState: "absent",
        absenceReason: data.absenceType,
        companyId: companyId,
      };

      try {
        await markAttendance(attendancePayload);
      } catch (error) {
        console.error("Error creating attendance record:", error);
        // Continue with other days even if one fails
      }

      // Move to next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  // Function to update attendance records
  const updateAttendanceRecords = async (data, employeeId) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const attendancePayload = {
        employeeId: employeeId,
        date: format(currentDate, "dd-MM-yyyy"),
        attendanceState: "absent",
        absenceReason: data.absenceType,
        companyId: companyId,
      };

      try {
        // Here you might want to first check if an attendance record exists
        // and update it, or create a new one if it doesn't exist
        await markAttendance(attendancePayload);
      } catch (error) {
        console.error("Error updating attendance record:", error);
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      // Find the selected employee to get their matricola
      const selectedEmployee = employeeData.find(emp => emp.employeeId === data.employeeId);
      const payload = {
        ...data,
        companyId: companyId,
        matricola: selectedEmployee?.freshman || '',
      };
      
      if (selectedId) {
        const res = await UpdateLeave(selectedId, payload);
        if (res.status === 200) {
          toast.success(res.data?.message);
          // Update attendance records for the absence period
          await updateAttendanceRecords(data, data.employeeId);
        } else {
          toast.error("Failed to update absence");
        }
      } else {
        const res = await AddLeave(payload);
        if (res.status === 201) {
          toast.success(res.data?.message);
          // Create new attendance records for the absence period
          await createAttendanceRecords(data, data.employeeId);
        } else {
          toast.error("Failed to mark absence");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    } finally {
      setIsModalOpen(false);
      setDataUpdate(!dataUpdate);
    }
  };

  useEffect(() => {
    reset(SingleLeaveData);
  }, [SingleLeaveData, reset]);

  const calculateAbsenceHours = () => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate) && startDate <= endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      const absenceHours = diffDays * 8;
      setValue("absenceHours", absenceHours);
    } else {
      setValue("absenceHours", 0);
    }
  };

  useEffect(() => {
    calculateAbsenceHours();
  }, [watch("startDate"), watch("endDate")]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>
              {selectedId ? "Aggiornamento assenza" : "Aggiungi assenza"}
            </DialogTitle>
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md"
              onClick={onClose}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </DialogHeader>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="employeeId">Dipendente</Label>
            <Select onValueChange={(value) => setValue("employeeId", value)}>
              <SelectTrigger>
                <SelectValue placeholder="Seleziona dipendente" />
              </SelectTrigger>
              <SelectContent>
                {employeeData?.map((employee) => (
                  <SelectItem 
                    key={employee.employeeId} 
                    value={employee.employeeId}
                  >
                    {`${employee.name} (${employee.freshman || 'No matricola'})`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="absenceType">Tipologia assenza</Label>
            <Select onValueChange={(value) => setValue("absenceType", value)}>
              <SelectTrigger>
                <SelectValue placeholder="Seleziona tipologia" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Ferie">Ferie</SelectItem>
                <SelectItem value="Malattia">Malattia</SelectItem>
                <SelectItem value="Permesso">Permesso</SelectItem>
                <SelectItem value="Ingiustificata">Ingiustificata</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="startDate">Data inizio assenza</Label>
            <Input
              id="startDate"
              type="date"
              {...register("startDate")}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="endDate">Data fine assenza</Label>
            <Input
              id="endDate"
              type="date"
              {...register("endDate")}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="absenceHours">Ore di assenza</Label>
            <Input
              id="absenceHours"
              type="number"
              min={0}
              {...register("absenceHours")}
              readOnly
            />
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <Button
              type="button"
              variant="destructive"
              onClick={onClose}
            >
              Annulla
            </Button>
            <Button
              type="submit"
              className="bg-[#06052C] hover:opacity-90"
            >
              {selectedId ? "Aggiornamento" : "Aggiungi"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddAbsence;