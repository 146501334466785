import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { ProjectorIcon, Zap, Laptop, ArrowRightIcon, Check, X, Minus, CircleCheck, HelpCircle } from 'lucide-react';
import logo from "../assest/logo2.png";
import Screenshot from "../assest/screenshot.png";
import Titolo from "../assest/titolo.png";
import NewsLetter from '../components/admin/sharedComponent/NewsLetter';
import LogoSection from '../components/admin/sharedComponent/logo-section';
import RestructFeaturesSection from '../components/admin/sharedComponent/RestructFeatureSection';
import FAQs from '../components/admin/sharedComponent/Faqs';
import { useSubscription } from '../screens/auth/SubscriptionContext';
import { fetchPlans, initiateStripeCheckout, changePlan } from '../apis/SubscriptionApi';
import UserDropdown from '../components/admin/sharedComponent/UserDropdown';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { PolicyLink } from '../components/admin/sharedComponent/PolicyLink';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import Footer from '../components/landing/Footer';
import Header from '../components/landing/Header';

const stats = [
  { id: 1, name: 'Aziende che innovano con i nostri prodotti', value: '+100' },
  { id: 2, name: 'Tempo risparmiato per le operazioni aziendali', value: '-46%' },
  { id: 3, name: 'Media fatturato dei nostri clienti dopo il primo anno', value: '+37%' },
  { id: 4, name: 'Paesi in cui operiamo, e lo facciamo al meglio', value: '1' },
];

const FeatureNameCell = ({ name, tooltip }) => {
  if (tooltip) {
    return (
      <div className="flex items-center gap-2">
        <div className="font-medium text-gray-800">{name}</div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="h-4 w-4 text-gray-700" />
            </TooltipTrigger>
            <TooltipContent className="max-w-xs">
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }

  return <div className="font-medium text-gray-800">{name}</div>;
};

const featureGroups = [
  {
    name: "Risorse",
    features: [
      { name: "Spazio di archiviazione in cloud", tiers: ["1 gb", "3 gb", "10 gb", "Unlimited"] },
      { name: "Utenti", tiers: [1, 5, 50, 100] },
      { name: "Dipendenti", tiers: [5, 20, 50, "Unlimited"] },
    ]
  },
  {
    name: "Funzionalità base",
    features: [
      { name: "Chat", tiers: [false, true, true, true] },
      { name: "Patente a punti", tiers: [false, true, true, true] },
      { name: "Calendario & Agenda", tiers: [true, true, true, true] },
      { name: "Movimenti bancari", tiers: ["100/mese", "300/mese", "Unlimited", "Unlimited"] },
    ]
  },
  {
    name: "Gestione aziendale",
    features: [
      { name: "Gestione clienti", tiers: [50, 200, 500, "Unlimited"] },
      { name: "Gestione fornitori", tiers: [25, 100, 300, "Unlimited"] },
      { name: "Gestione flotta", tiers: [false, "10 Veicoli", "50 Veicoli", "Unlimited"] },
      { name: "Magazzino", tiers: ["1 magazzino\n100 prodotti", "3 magazzini\n500 prodotti", "5 magazzini\n1000 prodotti", "Unlimited"] },
      { name: "DDT", tiers: ["20/mese", "50/mese", "200/mese", "Unlimited"] },
    ]
  },
  {
    name: "Gestione cantieri",
    features: [
      {
        name: "Cantieri",
        tiers: [3, 10, 70, "Unlimited"],
        tooltip: "Limite di cantieri aperti e gestiti contemporaneamente nell'app. Non sono conteggiati i cantieri terminati e archiviati."
      },
      { name: "Stato avanzamento lavori", tiers: [true, true, true, true] },
      { name: "Contabilità di cantiere", tiers: [false, false, true, true] },
      { name: "Documentazione cantieri", tiers: [false, true, true, true] },
      { name: "Rapportini di cantiere", tiers: [false, true, true, true] },
      { name: "Giornale dei lavori dei cantieri", tiers: [false, false, true, true] },
      { name: "Media dei cantieri", tiers: [false, "Foto", "Foto", "Foto & Video"] },
      { name: "Computi metrici", tiers: [false, false, true, true] },
    ]
  },
  {
    name: "Supporto e funzionalità avanzate",
    features: [
      { name: "Supporto", tiers: ["Standard", "Standard", "Dedicato", "Dedicato 24/7"] },
      { name: "Accesso prioritario a nuove funzionalità", tiers: [false, false, false, true] },
    ]
  }
];

const planDescriptions = {
  "Piano Restruct Basic": "Perfetto per piccole imprese edili",
  "Piano Restruct Standard": "Ideale per imprese in crescita",
  "Piano Restruct Professional": "Ottimo per aziende di medie dimensioni",
  "Piano Restruct Enterprise": "Completo per grandi imprese edili"
};

const PricingTier = ({ plan, billingCycle, onSubscribe, isCurrentPlan, userLoggedIn, userPlanType }) => {
  const price = plan.prices[billingCycle];
  const priceAmount = price ? (price.unit_amount / 100).toFixed(2) : 'N/A';
  const isPopular = plan.name === "Piano Restruct Professional";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="h-full"
    >
      <div className={`relative bg-white rounded-2xl p-4 h-full flex flex-col ${isPopular
        ? 'border-2 border-primary shadow-lg shadow-blue-100 ring-1 ring-blue-500/20'
        : 'border'
        } ${isCurrentPlan ? 'bg-blue-50' : ''}`}
      >
        {isPopular && (
          <div className="absolute -top-4 left-0 right-0 mx-auto w-max bg-primary text-white px-4 py-1 text-sm rounded-full shadow-sm">
            Più popolare
          </div>
        )}
        <div className={`${isPopular ? 'pt-2' : ''}`}>
          <h3 className="font-semibold text-xl mb-2">{plan.name}</h3>
          <p className="text-gray-600 text-sm">{planDescriptions[plan.name]}</p>

          <div className="my-6 flex items-baseline flex-wrap">
            <span className="text-4xl lg:text-5xl font-bold">€{priceAmount}</span>
            <span className="text-gray-600 ml-2">
              /{billingCycle === 'quarter' ? 'trimestre' : billingCycle === 'year' ? 'anno' : 'mese'}
            </span>
          </div>
        </div>

        <div className="mt-auto pt-1">
          {isCurrentPlan ? (
            <p className="text-green-600 font-semibold w-full text-center">Piano Attuale</p>
          ) : (
            <div className="flex flex-col gap-2 w-full">
              {userLoggedIn ? (
                <Button
                  onClick={() => onSubscribe(price?.id)}
                  className={`w-full ${isPopular ? 'bg-primary' : ''}`}
                  disabled={!price}
                  variant={isPopular ? "default" : "outline"}
                >
                  {userPlanType === 'free' ? 'Sottoscrivi' : 'Upgrade'}
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => onSubscribe(price?.id)}
                    className={`w-full ${isPopular ? 'bg-primary' : ''}`}
                    disabled={!price}
                    variant={isPopular ? "default" : "outline"}
                  >
                    Acquista ora
                  </Button>
                  <button
                    onClick={() => onSubscribe(price?.id)}
                    className="mt-2 w-full text-slate-900"
                    disabled={!price}
                  >
                    Inizia prova gratuita
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Card className="group relative bg-white dark:bg-gray-800 hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 border-none">
      <CardContent className="relative space-y-8 py-12 p-8">
        <Icon className="w-12 h-12 text-blue-500" />
        <div className="space-y-2">
          <h5 className="text-xl font-medium text-gray-700 dark:text-white transition group-hover:text-primary">
            {title}
          </h5>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {description}
          </p>
        </div>
      </CardContent>
    </Card>
  </motion.div>
);

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [billingCycle, setBillingCycle] = useState('month');
  const { subscription, setSubscription } = useSubscription();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      if (storedUser.subscription) {
        setSubscription(storedUser.subscription);
      }
    }
  }, [setSubscription]);

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const fetchedPlans = await fetchPlans();

        // Define the desired order of plans
        const planOrder = ['basic', 'standard', 'professional', 'enterprise'];

        // Sort plans based on the defined order
        const sortedPlans = fetchedPlans.sort((a, b) => {
          const indexA = planOrder.findIndex(p => a.name.toLowerCase().includes(p));
          const indexB = planOrder.findIndex(p => b.name.toLowerCase().includes(p));
          return indexA - indexB;
        });

        setPlans(sortedPlans);
      } catch (err) {
        console.error('Error fetching plans:', err);
        setError('Errore nel caricamento dei piani. Riprova più tardi.');
      }
    };

    loadPlans();
  }, []);

  const handleSubscribe = async (priceId) => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      if (subscription && subscription.planType !== 'free') {
        const response = await changePlan(priceId);
        setSuccessMessage('Piano aggiornato con successo. Le modifiche saranno effettive dal prossimo ciclo di fatturazione.');
        setSubscription(response.subscription);
        navigate('/subscription', { state: { success: true } });
      } else {
        const { checkoutUrl } = await initiateStripeCheckout(priceId);
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          throw new Error('URL di checkout non ricevuto');
        }
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      setError('Errore nell\'elaborazione della richiesta. Riprova.');
    }
  };

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("breadcrumb");
    window.location.href = "/login";
  };

  const AppFeatures = [
    {
      icon: ProjectorIcon,
      title: "Gestione dei progetti",
      description: "Organizza e coordina ogni fase del progetto con strumenti integrati, intuitivi e facili da usare, per una gestione senza sforzo."
    },
    {
      icon: Zap,
      title: "Velocità e precisione",
      description: "Statistiche in tempo reale e aggiornamenti immediatamente disponibili, lavora riducendo gli errori e aumentando l'efficienza."
    },
    {
      icon: Laptop,
      title: "Gestisci ovunque tu sia",
      description: "Accedi alla tua webapp da qualsiasi dispositivo e in qualsiasi momento, per una gestione flessibile e senza interruzioni."
    }
  ];

  const pricingTiers = [
    { title: "Piano Basic", price: { mensile: 30, trimestrale: 85, annuale: 324 }, index: 0, id: "price_base" },
    { title: "Piano Standard", price: { mensile: 60, trimestrale: 170, annuale: 648 }, index: 1, id: "price_standard" },
    { title: "Piano Professional", price: { mensile: 120, trimestrale: 340, annuale: 1296 }, index: 2, id: "price_professional" },
    { title: "Piano Enterprise", price: { mensile: 250, trimestrale: 710, annuale: 2700 }, index: 3, id: "price_enterprise" }
  ];

  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <Header />

        <div className="container px-6 py-16 mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-6xl mx-auto"
          >
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white lg:text-5xl">
              Organizza, pianifica e costruisci:
              la tua impresa edile a portata di mano
            </h1>
            <p className="mt-6 text-gray-500 dark:text-gray-300">
              Gestisci i tuoi cantieri, pianifica i progetti e tieni sotto controllo ogni dettaglio del tuo business edile. Con la nostra piattaforma intuitiva, avrai tutto ciò di cui hai bisogno per organizzare, monitorare e ottimizzare il lavoro.
            </p>
            <motion.div
              className="flex gap-x-6 my-6 justify-center items-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {user ? (
                <Link to="/dashboard">
                  <Button className='p-6 rounded-xl'>
                    Vai alla Dashboard
                  </Button>
                </Link>
              ) : (
                <Link to="/register">
                  <Button className='p-6 rounded-xl'>
                    Avvia ora la prova gratuita
                  </Button>
                </Link>
              )}
              <Button variant='link'>
                <Link to="https://glds.it" target='_blank'>
                  Contattaci
                </Link>
              </Button>
            </motion.div>
          </motion.div>

          <motion.div
            className="flex justify-center pt-10"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img
              className="object-cover w-full rounded-xl border"
              src={Screenshot}
              alt="Landing"
            />
          </motion.div>
        </div>

        <div className="pb-10">
          <div className="xl:container m-auto px-6 text-gray-500 md:px-12">
            <div className=" grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {AppFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <FeatureCard {...feature} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        <section className="bg-slate-900 text-white py-8">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <motion.div
                className="w-full md:w-1/2 mb-8 md:mb-0"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-3xl md:text-4xl font-bold mb-4">
                  Tutto organizzato e a portata di mano
                  <br />
                  <span className="text-blue-400">per una gestione più efficiente e senza stress.</span>
                </h2>
                <p className="text-gray-300 mb-8">
                  Dalla pianificazione iniziale fino alla fase di produzione, la nostra piattaforma ti
                  consente di gestire ogni singolo dettaglio del tuo cantiere in modo efficiente e
                  accurato, semplificando il lavoro quotidiano e migliorando la produttività
                  complessiva della tua impresa.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button className="bg-blue-600 hover:bg-blue-700 text-white">
                    Avvia ora la prova gratuita
                    <ArrowRightIcon className="ml-2 h-4 w-4" />
                  </Button>
                  <Button variant="link" className="text-white border-white hover:bg-white hover:text-[#020134]">
                    Contattaci
                  </Button>
                </div>
              </motion.div>
              <motion.div
                className="w-full md:w-1/2 md:pl-8"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="aspect-video rounded-xl flex items-center justify-center text-gray-600">
                  <img src={Titolo} alt="web app image" className='object-cover rounded-xl' />
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <LogoSection />

        <motion.div
          className="bg-white py-24 sm:py-32"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.id}
                  className="mx-auto flex max-w-xs flex-col gap-y-4"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                    {stat.value}
                  </dd>
                </motion.div>
              ))}
            </dl>
            <div className="flex items-center justify-center p-8">
              <Link to="/register">
                <Button>
                  Avvia ora la prova gratuita
                </Button>
              </Link>
            </div>
          </div>
        </motion.div>

        <RestructFeaturesSection />

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Errore</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {successMessage && (
          <Alert className="mb-4">
            <AlertTitle>Successo</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}

        <section className="py-20 max-sm:px-4">
          <div className="max-w-7xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold text-center mb-8">
                Qualunque sia il tuo obiettivo, abbiamo il piano giusto per te.
              </h2>

              <div className="flex justify-center mb-8">
                {['month', 'quarter', 'year'].map((cycle) => (
                  <button
                    key={cycle}
                    className={`px-4 py-2 ${billingCycle === cycle ? 'bg-slate-900 text-white' : 'bg-gray-200'}`}
                    onClick={() => setBillingCycle(cycle)}
                  >
                    {cycle === 'month' ? 'Mensile' : cycle === 'quarter' ? 'Trimestrale' : 'Annuale'}
                  </button>
                ))}
              </div>
            </motion.div>

            {error && <div className="text-center text-red-500 mb-4">{error}</div>}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mb-12">
              {plans.map((plan, index) => (
                <motion.div
                  key={plan.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <PricingTier
                    plan={plan}
                    billingCycle={billingCycle}
                    onSubscribe={handleSubscribe}
                    isCurrentPlan={user && subscription?.planType === plan.name.toLowerCase()}
                    isPopular={plan.name === "Piano Professionale"}
                    userLoggedIn={!!user}
                    userPlanType={subscription?.planType}
                  />
                </motion.div>
              ))}
            </div>

            <motion.div
              className="overflow-x-auto"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div className="text-center text-gray-600 mb-4">
                Tutti i prezzi sono IVA inclusa
              </div>
              <div className="rounded-xl  border-gray-200 shadow-lg bg-white">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left py-6 px-6 border-b bg-white rounded-tl-xl w-1/3">
                        <span className="text-xl font-semibold text-gray-900">Caratteristiche</span>
                      </th>
                      {['Basic', 'Standard', 'Professional', 'Enterprise'].map((tier, i) => (
                        <th key={i} className={`py-6 px-6 border-b bg-white ${i === 3 ? 'rounded-tr-xl' : ''}`}>
                          <div className="space-y-1">
                            <span className="text-xl font-semibold text-gray-900 block">{tier}</span>
                            {tier === 'Professional' && (
                              <span className="inline-block px-3 py-1 text-xs font-medium text-blue-700 bg-blue-50 rounded-full">
                                Più popolare
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {featureGroups.map((group, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <tr className="bg-gradient-to-r from-gray-50 to-white">
                          <td colSpan={5} className="py-4 px-6 font-bold text-gray-900 border-t border-b text-lg ">
                            {group.name}
                          </td>
                        </tr>
                        {group.features.map((feature, index) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-50 transition-colors"
                          >
                            <td className="py-4 px-6 border-b">
                              <FeatureNameCell
                                name={feature.name}
                                tooltip={feature.tooltip}
                              />
                            </td>
                            {feature.tiers.map((value, i) => (
                              <td key={i} className="px-6 py-4 text-center border-b">
                                {typeof value === 'boolean' ? (
                                  value ? (
                                    <div className="flex justify-center">
                                      <div className="p-1 rounded-full bg-green-50">
                                        <CircleCheck color="#000000" strokeWidth={2} />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex justify-center">
                                      <div className="p-1 rounded-full bg-gray-50">
                                        <Minus className="h-5 w-5 text-gray-400" />
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  <span className={`text-gray-900 font-medium ${value === 'Unlimited' ? 'text-blue-600' : ''}`}>
                                    {value}
                                  </span>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </motion.div>
          </div>
        </section>

        <FAQs />

        <NewsLetter />

        <Footer />
      </section>
    </>
  );
};

export default LandingPage;