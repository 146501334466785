import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Eye, Edit, Trash, Filter, Plus, Loader2, FileDown, Upload } from "lucide-react";
import { fetchPreventivi, updatePreventivi, deletePreventivo } from '../../../apis/Preventivi';
import { toast } from "react-toastify";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";

import EditModal from './EditModal';
import SearchBar from '../sharedComponent/SearchBar';
import Pagination from "../../admin/sharedComponent/Pgination";
import HelpSheet from '../sharedComponent/HelpSheet';
import Spinner from '../../Spinner';
import PreventiviTablePDFExport from './PreventiviTablePDFExport';
import { fetchCompany } from '../../../apis/PreventiviEdit';
import { exportToPDF } from '../../../config/helper';
import ImportDialog from './ImportDialog';

const FilterDialog = ({ filters, onFilterChange, onFilterApply, open, onOpenChange }) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Filtri</DialogTitle>
      </DialogHeader>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="startDate">Data di inizio</Label>
            <Input
              id="startDate"
              type="date"
              value={filters.startDate}
              onChange={(e) => onFilterChange('startDate', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="endDate">Data di fine</Label>
            <Input
              id="endDate"
              type="date"
              value={filters.endDate}
              onChange={(e) => onFilterChange('endDate', e.target.value)}
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label htmlFor="cliente">Cliente</Label>
          <Input
            id="cliente"
            placeholder="Nome del cliente"
            value={filters.cliente}
            onChange={(e) => onFilterChange('cliente', e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="numeroMin">Numero Minimo</Label>
            <Input
              id="numeroMin"
              type="number"
              placeholder="Numero min"
              value={filters.numeroMin}
              onChange={(e) => onFilterChange('numeroMin', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="numeroMax">Numero Massimo</Label>
            <Input
              id="numeroMax"
              type="number"
              placeholder="Numero max"
              value={filters.numeroMax}
              onChange={(e) => onFilterChange('numeroMax', e.target.value)}
            />
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button variant="outline" onClick={() => onOpenChange(false)}>Annulla</Button>
        <Button onClick={() => { onFilterApply(); onOpenChange(false); }}>Applica Filtri</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

const TableComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    cliente: '',
    numeroMin: '',
    numeroMax: '',
  });
  const [company, setCompany] = useState(null);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);



  useEffect(() => {
    getItems();
    fetchCompany(companyId).then(setCompany);
  }, [currentPage, limit]);

  const handleExport = async () => {
    try {
      setExporting(true);
  
      const formattedData = filteredData.map((item, index) => ({
        nu: (index + 1).toString(),
        numero: item.numero || '',
        data: item.dataa ? new Date(item.dataa).toLocaleDateString('it-IT', { 
          day: '2-digit', 
          month: '2-digit', 
          year: 'numeric' 
        }).replace(/\//g, '-') : '',
        cliente: item.clienti?.fullName || item.clienti?.companyName || '',
        oggetto: item.ogguto || '',
        totale: item.totaleval ? `€ ${parseFloat(item.totaleval).toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}` : '€ 0,00'
      }));
  
      const columns = [
        { header: "N°", key: "nu", width: 0.5 },
        { header: "Numero", key: "numero", width: 1 },
        { header: "Data", key: "data", width: 1.2 },
        { header: "Cliente", key: "cliente", width: 2 },
        { header: "Oggetto", key: "oggetto", width: 2.5 },
        { header: "Totale", key: "totale", width: 1.2 }
      ];
  
      const currentDate = new Date().toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');
  
      let dateRange = currentDate;
      if (filters.startDate && filters.endDate) {
        dateRange = `Dal ${new Date(filters.startDate).toLocaleDateString('it-IT').replace(/\//g, '-')} al ${new Date(filters.endDate).toLocaleDateString('it-IT').replace(/\//g, '-')}`;
      }
  
      const stats = [
        { label: "Totale Preventivi", value: filteredData.length },
        { label: "Periodo", value: dateRange }
      ];
  
      // Calculate totals
      const totals = filteredData.reduce((acc, item) => {
        const imponibile = parseFloat(item.totaleval) || 0;
        return {
          imponibile: acc.imponibile + imponibile
        };
      }, { imponibile: 0 });
  
      const summaries = [
        {
          label: "Totale Complessivo",
          value: `€ ${totals.imponibile.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      ];
  
      await exportToPDF(
        formattedData, 
        columns, 
        "Lista Preventivi", 
        stats,
        undefined, 
        summaries
      );
  
      toast.success("Esportazione PDF completata con successo");
    } catch (error) {
      console.error('Errore durante l\'esportazione PDF:', error);
      toast.error("Errore durante l'esportazione PDF");
    } finally {
      setExporting(false);
    }
  };
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await fetchPreventivi(companyId, currentPage, limit);
      const limitedItems = response.data.preventivis.slice(0, limit);
      setData(limitedItems);
      setTotalPage(response.data?.totalPages);
      setCurrentPage(response.data?.currentPage);
      applyFilters(limitedItems);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      toast.error("Errore nel recupero dei dati. Riprova più tardi.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getItems();
  }, [currentPage, limit]);

  const handleSearch = (searchTerm) => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter(item =>
        item.ogguto?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.clienti?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.numero?.toString().includes(searchTerm) ||
        item.dataa?.includes(searchTerm)
      );
      applyFilters(filtered);
    } else {
      applyFilters(data);
    }
  };

  const applyFilters = (dataToFilter) => {
    let filtered = dataToFilter.filter(item => {
      const itemDate = new Date(item.dataa);
      return (
        (!filters.startDate || itemDate >= new Date(filters.startDate)) &&
        (!filters.endDate || itemDate <= new Date(filters.endDate)) &&
        (!filters.cliente || item.clienti?.fullName?.toLowerCase().includes(filters.cliente.toLowerCase())) &&
        (!filters.numeroMin || parseInt(item.numero) >= parseInt(filters.numeroMin)) &&
        (!filters.numeroMax || parseInt(item.numero) <= parseInt(filters.numeroMax))
      );
    });
    setFilteredData(filtered);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleFilterApply = () => {
    applyFilters(data);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (updatedData) => {
    try {
      await updatePreventivi(updatedData._id, updatedData);
      setIsEditModalOpen(false);
      getItems();
      toast.success("Preventivo aggiornato con successo");
    } catch (error) {
      console.error('Errore nell\'aggiornamento del preventivo:', error);
      toast.error("Errore nell'aggiornamento del preventivo. Riprova.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questo elemento?')) {
      try {
        await deletePreventivo(id);
        getItems();
        toast.success("Preventivo eliminato con successo");
      } catch (error) {
        console.error('Errore nella cancellazione del preventivo:', error);
        toast.error("Errore nella cancellazione del preventivo. Riprova.");
      }
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:items-center">
          <Link to="/preventivi_add" className="w-full sm:w-auto">
            <Button className="w-full sm:w-auto">
              Aggiungi
            </Button>
          </Link>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={handleExport}
                  disabled={exporting}
                  variant="outline"
                  className="w-full sm:w-auto"
                >
                  {exporting ? 'Esportazione...' : 'Esporta PDF'}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Scarica</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button
            onClick={() => setIsImportDialogOpen(true)}
            variant="outline"
            className="w-full sm:w-auto"
          >
            Importa
          </Button>
        </div>
        <div className="flex sm:flex-row gap-2 w-full sm:w-auto">
          <div className="w-full sm:w-auto">
            <SearchBar onSearch={handleSearch} />
          </div>
          <Button
            variant="outline"
            onClick={() => setIsFilterDialogOpen(true)}
            className="w-full sm:w-auto"
          >
            <Filter className="mr-2 h-4 w-4" /> Filtri
          </Button>
          <HelpSheet route="/preventivi" />
        </div>
      </div>

      <FilterDialog
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterApply={handleFilterApply}
        open={isFilterDialogOpen}
        onOpenChange={setIsFilterDialogOpen}
      />

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Numero</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Cliente</TableHead>
              <TableHead>Stato</TableHead>
              <TableHead>Descrizione</TableHead>
              <TableHead>Azione</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-4">
                  <Spinner loading={loading} />
                </TableCell>
              </TableRow>
            ) : filteredData.length > 0 ? (
              filteredData.map(item => (
                <TableRow key={item._id}>
                  <TableCell>{item.numero}</TableCell>
                  <TableCell>{new Date(item.dataa).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')}</TableCell>
                  <TableCell>{item.clienti?.fullName}</TableCell>
                  <TableCell>{item?.stato}</TableCell>
                  <TableCell>{item.ogguto?.length > 50 ? `${item.ogguto.slice(0, 150)}...` : item.ogguto}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link to={`/preventivi_edit/${item._id}`}>
                              <Button variant="ghost" size="sm">
                                <Eye className="h-4 w-4" />
                              </Button>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Apri</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button variant="ghost" size="sm" onClick={() => handleDelete(item._id)}>
                              <Trash className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Elimina</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-4">
                  Nessun dato
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {filteredData.length > 0 && (
        <Pagination
          totalItems={filteredData.length}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <EditModal
        open={isEditModalOpen}
        onOpenChange={setIsEditModalOpen}
        item={selectedItem}
        onUpdate={handleUpdate}
      />
      <ImportDialog
        open={isImportDialogOpen}
        onOpenChange={setIsImportDialogOpen}
        onImportComplete={getItems}
      />
    </div>
  );
};

export default TableComponent;