import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyEmail, resendVerification } from '../../apis/Auth';
import { toast } from 'react-toastify';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const token = searchParams.get('token');

  useEffect(() => {
    const verify = async () => {
      try {
        if (!token) {
          setVerificationStatus('invalid');
          return;
        }

        await verifyEmail(token);
        setVerificationStatus('success');
        
        // Reindirizza alla pagina di login dopo 5 secondi
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } catch (error) {
        setVerificationStatus('error');
        console.error('Errore di verifica:', error);
      }
    };

    verify();
  }, [token, navigate]);

  const getStatusContent = () => {
    switch (verificationStatus) {
      case 'verifying':
        return {
          title: 'Verifica della tua email...',
          description: 'Attendi mentre verifichiamo il tuo indirizzo email.',
          bgColor: 'bg-blue-100',
          textColor: 'text-blue-800',
          borderColor: 'border-blue-500'
        };
      case 'success':
        return {
          title: 'Email Verificata!',
          description: 'La tua email è stata verificata con successo. Sarai reindirizzato alla pagina di login a breve.',
          bgColor: 'bg-green-100',
          textColor: 'text-green-800',
          borderColor: 'border-green-500'
        };
      case 'error':
        return {
          title: 'Verifica Fallita',
          description: 'Il link di verifica non è valido o è scaduto. Per favore, richiedi un nuovo link di verifica.',
          bgColor: 'bg-red-100',
          textColor: 'text-red-800',
          borderColor: 'border-red-500'
        };
      case 'invalid':
        return {
          title: 'Richiesta Non Valida',
          description: 'Nessun token di verifica trovato. Per favore, usa il link dalla tua email di verifica.',
          bgColor: 'bg-red-100',
          textColor: 'text-red-800',
          borderColor: 'border-red-500'
        };
      default:
        return null;
    }
  };

  const content = getStatusContent();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-950 to-blue-800 p-4">
      <div className="max-w-md w-full">
        <div className={`p-4 rounded-lg border ${content.bgColor} ${content.textColor} ${content.borderColor}`}>
          <h2 className="text-lg font-semibold mb-2">{content.title}</h2>
          <p>{content.description}</p>
        </div>

        {(verificationStatus === 'error' || verificationStatus === 'invalid') && (
          <div className="mt-4 text-center">
            <button
              onClick={() => navigate('/resend-verification')}
              className="text-white hover:underline"
            >
              Richiedi Nuovo Link di Verifica
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ResendVerification = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('submitting');

    try {
      await resendVerification(email);
      setStatus('success');
      toast.success('Email di verifica inviata con successo!');
    } catch (error) {
      setStatus('error');
      toast.error('Impossibile inviare l\'email di verifica');
      console.error('Errore di reinvio:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-950 to-blue-800 p-4">
      <div className="max-w-md w-full">
        <h2 className="text-2xl font-bold text-white mb-6 text-center">
          Reinvia Email di Verifica  
        </h2>

        {status === 'success' ? (
          <div className="bg-green-100 border border-green-500 text-green-800 rounded-lg p-4">
            <h3 className="font-semibold">Email di Verifica Inviata!</h3>
            <p>Per favore, controlla la tua email per il link di verifica.</p>
            <div className="mt-4">
              <button
                onClick={() => navigate('/login')}
                className="text-blue-600 hover:underline"
              >
                Torna al Login
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Inserisci la tua email"
                className="h-12 p-4 ring-2 rounded-full text-normal text-black w-full ring-blue-700"
                required
              />
            </div>

            {status === 'error' && (
              <div className="bg-red-100 border border-red-500 text-red-800 rounded-lg p-4">
                Impossibile inviare l'email di verifica. Per favore, riprova o contatta il supporto.
              </div>
            )}

            <button
              type="submit" 
              disabled={status === 'submitting'}
              className="h-12 p-2 w-full rounded-full text-normal bg-green-500 hover:opacity-70 text-white disabled:opacity-50"
            >
              {status === 'submitting' ? 'Invio...' : 'Invia Email di Verifica'}
            </button>

            <div className="text-center mt-4">
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="text-white hover:underline"
              >
                Torna al Login
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export { EmailVerification, ResendVerification };