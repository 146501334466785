import React, { useState, useEffect } from 'react';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from '../../ui/dropdown-menu';
import { BellDot, Check, Trash2, AlertCircle, Clock } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../ui/alert';
import { Button } from '../../ui/button';
import { Badge } from '../../ui/badge';
import { ScrollArea } from '../../ui/scroll-area';
import { useNavigate } from 'react-router-dom';
import notificationApi from '../../../apis/NotificationApi';

const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await notificationApi.getNotifications({ limit: 10 });
      setNotifications(response.notifications);
      const unreadCount = await notificationApi.getUnreadCount();
      setUnreadCount(unreadCount);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await notificationApi.markAsRead(id);
      setNotifications(notifications.map(notif => 
        notif._id === id ? { ...notif, status: 'read' } : notif
      ));
      setUnreadCount(Math.max(0, unreadCount - 1));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await notificationApi.markAllAsRead();
      setNotifications(notifications.map(notif => ({ ...notif, status: 'read' })));
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await notificationApi.deleteNotification(id);
      setNotifications(notifications.filter(notif => notif._id !== id));
      if (notifications.find(n => n._id === id)?.status === 'unread') {
        setUnreadCount(Math.max(0, unreadCount - 1));
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.status === 'unread') {
      handleMarkAsRead(notification._id);
    }
    
    // Navigate based on the notification type and related resource
    if (notification.relatedResource.type === 'ConstructionSite') {
      navigate(`/cantieri/${notification.relatedResource.id}`);
    }
    // Add more navigation logic for other notification types
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'high':
        return 'destructive';
      case 'medium':
        return 'yellow';
      default:
        return 'secondary';
    }
  };

  const getTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    
    let interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + ' anni fa';
    
    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + ' mesi fa';
    
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + ' giorni fa';
    
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + ' ore fa';
    
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + ' minuti fa';
    
    return Math.floor(seconds) + ' secondi fa';
  };

  

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative">
          <BellDot className="cursor-pointer h-6 w-6 text-gray-600 hover:text-gray-800" />
          {unreadCount > 0 && (
            <Badge 
              variant="destructive" 
              className="absolute -top-1 -right-1 min-w-[10px] h-4 flex items-center justify-center"
            >
              {unreadCount}
            </Badge>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[380px]">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="font-semibold">Notifiche</h3>
          {notifications.some(n => n.status === 'unread') && (
            <Button 
              variant="ghost" 
              size="sm"
              onClick={handleMarkAllAsRead}
              className="text-sm"
            >
              Segna tutti come letti
            </Button>
          )}
        </div>
        
        <ScrollArea className="h-[400px]">
          {loading ? (
            <div className="p-4 text-center text-gray-500">
              Caricamento notifiche...
            </div>
          ) : notifications.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              Nessuna notifica
            </div>
          ) : (
            <div className="divide-y">
              {notifications.map((notification) => (
                <div
                  key={notification._id}
                  className={`p-4 hover:bg-gray-50 transition-colors ${
                    notification.status === 'unread' ? 'bg-blue-50/50' : ''
                  }`}
                >
                  <div className="flex items-start gap-4 mb-2">
                    <div className="flex-1">
                      <div 
                        className="cursor-pointer"
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <div className="flex items-center gap-2 mb-1">
                          <Badge variant={getPriorityColor(notification.priority)}>
                            {notification.priority === 'high' ? 'Alta priorità' : 
                             notification.priority === 'medium' ? 'Media priorità' : 
                             'Bassa priorità'}
                          </Badge>
                          <span className="text-xs text-gray-500">
                            {getTimeAgo(notification.createdAt)}
                          </span>
                        </div>
                        <h4 className="font-medium text-sm mb-1">{notification.title}</h4>
                        <p className="text-sm text-gray-600">{notification.message}</p>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      {notification.status === 'unread' && (
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleMarkAsRead(notification._id)}
                        >
                          <Check className="h-4 w-4" />
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleDelete(notification._id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>
        {/* <div className="p-4 border-t">
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => navigate('/notifications')}
          >
            Vedi tutte le notifiche
          </Button>
        </div> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NotificationDropdown;