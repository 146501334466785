import React, { useState, useEffect } from 'react';
import { Button } from "../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { toast } from "react-toastify";
import { Building } from "lucide-react";
import { getConstructionSites } from '../../../../apis/ConstructionSite';
import { fetchGeneralData, updateGeneralData } from '../../../../apis/ComputimetricAddrecSumm';

const GeneralDataDialog = ({ id, companyId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    comune: '',
    provincia: '',
    oggetto: '',
    committente: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      loadInitialData();
    }
  }, [isOpen]);

  const loadInitialData = async () => {
    try {
      // Load clients and general data in parallel
      const [sitesResponse, generalDataResponse] = await Promise.all([
        getConstructionSites(companyId, 1, 10), // Using the same API as Cantieri page
        fetchGeneralData(id)
      ]);

      setClients(sitesResponse.data?.clients || []);
      
      if (generalDataResponse.data?.data) {
        setFormData(generalDataResponse.data.data);
      }
    } catch (error) {
      console.error('Error loading initial data:', error);
      toast.error('Errore nel caricamento dei dati. Riprova.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await updateGeneralData(id, formData);
      toast.success('Dati generali aggiornati con successo');
      setIsOpen(false);
    } catch (error) {
      console.error('Error updating general data:', error);
      toast.error('Errore nell\'aggiornamento dei dati. Riprova.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button 
        variant="outline" 
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2"
      >
        <Building className="h-4 w-4" />
        Dati generali
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Dati generali</DialogTitle>
          </DialogHeader>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="comune">Comune di</Label>
              <Input
                id="comune"
                value={formData.comune || ''}
                onChange={(e) => setFormData({ ...formData, comune: e.target.value })}
                placeholder="Inserisci il comune"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="provincia">Provincia di</Label>
              <Input
                id="provincia"
                value={formData.provincia || ''}
                onChange={(e) => setFormData({ ...formData, provincia: e.target.value })}
                placeholder="Inserisci la provincia"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="oggetto">Oggetto</Label>
              <Input
                id="oggetto"
                value={formData.oggetto || ''}
                onChange={(e) => setFormData({ ...formData, oggetto: e.target.value })}
                placeholder="Inserisci l'oggetto"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="committente">Committente</Label>
              <Select
                value={formData.committente || ''}
                onValueChange={(value) => setFormData({ ...formData, committente: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona il committente" />
                </SelectTrigger>
                <SelectContent>
                  {clients.map((client) => (
                    <SelectItem key={client._id} value={client._id}>
                      {client.typology === 'Private' ? client.fullName : client.companyName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => setIsOpen(false)}>
                Annulla
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Salvataggio...' : 'Salva'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GeneralDataDialog;